import React, { useState } from 'react';
import LcaDriagramManagement from 'src/Lca/LCADiagram/Management';
import LCADiagram from 'src/Lca/LCADiagram';
import MonteCarloDiagram from 'src/Lca/LCADiagram/MonteCarloDiagram';

import { LcaManagementTab } from 'src/Lca/LCADiagram/Management/index.types';
import ImpactGrid from 'src/Lca/ImpactGrid';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEcoinventProLicenceValid } from 'src/Lca/hooks';
import { useIsLcaPartialResults, useIsLcaPartialResultsGlobal } from '../utils';

export default function Diagram({ productId }: { productId: number }) {
  const [tab, setTab] = useState<LcaManagementTab>(LcaManagementTab.FLOWCHART);

  const { isEcoinventLicenceValid } = useEcoinventProLicenceValid();

  const { t } = useTranslation();
  const isLcaPartialResults = useIsLcaPartialResults(productId);
  const isUseIsLcaPartialResultsGlobal =
    useIsLcaPartialResultsGlobal(productId);

  return (
    <div style={{ padding: 10, minWidth: '1350px', maxWidth: '1550px' }}>
      <LcaDriagramManagement productId={productId} tab={tab} setTab={setTab} />

      {!isEcoinventLicenceValid && (
        <>
          <br />
          <Alert severity="info">{t('lca.provideCustomFactors')}</Alert>
        </>
      )}

      {(isLcaPartialResults ||
        (isUseIsLcaPartialResultsGlobal &&
          tab === LcaManagementTab.IMPACT)) && (
        <>
          <br />
          <Alert severity="warning">{t('lca.partialLcaResults')}</Alert>
        </>
      )}

      {tab === LcaManagementTab.FLOWCHART && (
        <LCADiagram productId={productId} />
      )}
      {tab === LcaManagementTab.IMPACT && <ImpactGrid />}
      {tab === LcaManagementTab.ANALYTICS && (
        <MonteCarloDiagram productId={productId} />
      )}
    </div>
  );
}
