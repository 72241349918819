import React from 'react';
import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import themeColors from './colors';
import dialogTheme from './dialog';
import customTypography from './typographies';

const baseTheme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    ...customTypography,
  },
  palette: {
    primary: {
      main: themeColors.greenBrand,
      contrastText: themeColors.pureWhite,
    },
    secondary: {
      main: themeColors.pureWhite,
      contrastText: themeColors.greenBrand,
    },
    error: {
      main: themeColors.error,
    },
    hover: themeColors.hover,
    pressed: themeColors.pressed,
    disabledLabel: themeColors.disabledLabel,
    disabledGraphics: themeColors.disabledGraphics,
    placeholder: themeColors.placeholder,
    strokeInputs: themeColors.strokeInputs,
    backgroundInput: themeColors.backgroundInput,
    dark: themeColors.dark,
    darkGrey: themeColors.darkGrey,
    footerBackground: themeColors.footer,
    footerText: themeColors.ghgDarkGrey,
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'primary',
        size: 'medium',
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          boxShadow: 'none',
          borderRadius: 28,
          fontSize: 18,
          height: 56,
          padding: '0 24px',
          lineHeight: 1.25,
          minWidth: '144px',

          ':hover': {
            boxShadow: 'none',
          },
          '&:disabled': {
            backgroundColor: themeColors.pressed,
            color: themeColors.disabledLabel,
          },
        },
        // @ts-ignore
        neutral: {
          minWidth: '120px',
          marginRight: '5px',
          backgroundColor: '#fff',
          color: '#606462',
          fontSize: '18px',
          borderRadius: '16px',
          textTransform: 'none',
          '& svg': {
            fontSize: '24px',
          },
          '&:hover': {
            backgroundColor: '#f2f2f2',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            fontFamily: 'Poppins',
            '& a': {
              // color links with primary color
              color: theme.palette.primary.main,
            },
          }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiCheckbox-root': {
            // if Checkbox is used within FormControlLabel dissallow shrinking its outline
            flexShrink: 0,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
          '&:hover': {
            backgroundColor: themeColors.ghgMainLight,
          },
          '&.active': {
            backgroundColor: themeColors.pureWhite,
            color: themeColors.ghgDark,
          },
        },
      },
    },
    MuiBox: {
      variants: [
        {
          props: { variant: 'footer' },
          style: {
            backgroundColor: '#1976d2',
            color: '#fff',
            padding: '20px',
            textAlign: 'center',
          },
        },
      ],
    },
  },
});

const theme = createTheme(deepmerge(baseTheme, dialogTheme));

export default theme;
