import { Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { CbamImportedGoodOutSchema } from '../types';
import { Delete, Edit, KeyboardArrowDown } from '@mui/icons-material';
import {
  useActors,
  useImportedGoods,
  useInstallations,
  useProductionProcesses,
} from '../hooks';
import { useNavigate } from 'react-router-dom';
import { quantityUnit } from '../utils';

interface Props {
  data: CbamImportedGoodOutSchema;
}

export function formatNumber(value: number | null | undefined) {
  if (value === undefined || value === null) {
    return '--';
  }
  return Number.isNaN(value) ? '--' : value.toFixed(5);
}

export default function Row(props: Props) {
  const navigate = useNavigate();
  const importedGoods = useImportedGoods();
  const actors = useActors();
  const productionProcesses = useProductionProcesses();
  const productionProcess = productionProcesses.data.find(
    (p) => p.id === props.data.production_process_id
  );

  const unit = quantityUnit(productionProcess);

  const installations = useInstallations();
  const installation = installations.data.find(
    (i) => i.id === productionProcess?.installation_id
  );

  const importer = actors.data.find((a) => a.id === props.data.importer_id);

  const [isCollapseOpen, setIsCollapseOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={() => setIsCollapseOpen((x) => !x)}>
            <KeyboardArrowDown />
          </IconButton>
        </TableCell>
        <TableCell>{props.data.name}</TableCell>
        <TableCell>
          <p>{props.data.amount}</p>
          <p>[{unit}]</p>
        </TableCell>
        <TableCell>
          {importer?.name} ({importer?.identifier})
        </TableCell>
        <TableCell>
          <p>{productionProcess?.name}</p>
          <p>[{productionProcess?.aggregated_goods_category}]</p>
        </TableCell>
        <TableCell>{formatNumber(props.data.specific_emissions)}</TableCell>
        <TableCell>
          {formatNumber(props.data.benchmark_specific_emissions)}
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() =>
              navigate(`/cbam/imported-goods/${props.data.id}`, {
                state: { reportId: props.data.report_id },
              })
            }
          >
            <Edit />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => importedGoods.delete.mutate(props.data.id)}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ p: 0 }} />
        <TableCell colSpan={9} sx={{ p: 0 }}>
          <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
            <Box sx={{ m: 1 }}>
              Details
              <ul>
                <li>
                  HS/CN Code: {productionProcess?.hs_code}{' '}
                  {productionProcess?.cn_code}
                </li>
                <li>
                  Aggregated goods category:{' '}
                  {productionProcess?.aggregated_goods_category}
                </li>
                <li>Country of origin: {installation?.country} </li>
                <li>
                  Specific electricity consumption:{' '}
                  {formatNumber(props.data.specific_electricity_consumption)}{' '}
                  [MWh/unit]
                </li>
                <li>
                  Specific embedded direct emissions:{' '}
                  {formatNumber(
                    props.data.specific_attributed_direct_emissions
                  )}{' '}
                  [tCO2e/unit]
                </li>
                <li>
                  Specific embedded indirect emissions:{' '}
                  {formatNumber(
                    props.data?.specific_attributed_indirect_emissions
                  )}{' '}
                  [tCO2e/unit]
                </li>
                <li>
                  Total emissions: {formatNumber(props.data.total_emissions)}{' '}
                  [tCO2e]
                </li>
              </ul>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
