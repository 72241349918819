import React from 'react';
import Dialog from '@mui/material/Dialog';
import { styled as styledMUI } from '@mui/material/styles';
import CloseIcon from '../../../../../Pages/ESG/create/closeIcon';
import {
  Close,
  ModalSubTitle,
  ModalTitle,
  ButtonsWrapper,
} from '../../../../../Pages/ESG/create/index.styles';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

interface Props {
  open: boolean;
  onClose: Function;
  mutation: Function;
}

// delete styles after rewriting getFieldTheme
const StyledDialog = styledMUI(Dialog)`
  & .MuiPaper-root {
    background: #FFFFFF;
    border: 1px solid #D9DFDD;
    border-radius: 16px;
    padding: 24px;  
  }`;
const button = css`
  height: 48px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-decoration: none;
  border-radius: 24px;
  width: 244px;
`;

const AddButton = styled.button`
  ${button};
  color: var(--main-dark-main, #38414f);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--light-grey, #ccc);
  background: var(--white, #fff);

  &:hover {
    background: var(--icon-button-hover-dark, rgba(0, 0, 0, 0.04));
  }

  &:active {
    border: 1px solid var(--icon-button-pressed-dark, rgba(0, 0, 0, 0.08));
    background: var(--icon-button-pressed-dark, rgba(0, 0, 0, 0.08));
  }
`;
const CloseButton = styled.button`
  ${button};
  color: var(--white, #fff);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--main-dark-main, #38414f);
  background: var(--main-dark-main, #38414f);

  &:hover {
    border: 1px solid var(--main-dark-hover, #49525f);
    background: var(--main-dark-hover, #49525f);
  }

  &:active {
    border: 1px solid var(--main-dark-pressed, #5a636f);
    background: var(--main-dark-pressed, #5a636f);
  }

  &:disabled {
    cursor: auto;
    color: var(--inactive-letters, #bbb);
    border: 1px solid var(--icon-button-hover-dark, rgba(0, 0, 0, 0.04));
    background: var(--icon-button-hover-dark, rgba(0, 0, 0, 0.04));
  }
`;

export default function RemoveModal({ open, onClose, mutation }: Props) {
  const { t } = useTranslation();
  return (
    <StyledDialog open={open} onClose={() => onClose()}>
      <Close type="button" onClick={() => onClose()}>
        <CloseIcon />
      </Close>
      <ModalTitle>{t('esg.remove_modal.title')}</ModalTitle>
      <ModalSubTitle>{t('esg.remove_modal.subtitle')}</ModalSubTitle>
      <ButtonsWrapper>
        <AddButton type="button" onClick={() => onClose()}>
          {t('esg.remove_modal.buttons.cancel')}
        </AddButton>
        <CloseButton
          type="button"
          onClick={() => {
            mutation();
            onClose();
          }}
        >
          {t('esg.remove_modal.buttons.remove')}
        </CloseButton>
      </ButtonsWrapper>
    </StyledDialog>
  );
}
