import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { StyledPanel } from 'src/components/StyledPanel';
import { Link } from 'react-router-dom';

const data = [
  {
    title:
      'Regulation (EU) 2023/956 of the European Parliament and of the Council of 10 May 2023 establishing a carbon border adjustment mechanism ',
    link: 'https://eur-lex.europa.eu/eli/reg/2023/956',
  },
  {
    title:
      'Commission Implementing Regulation (EU) 2023/1773 of 17 August 2023 laying down the rules for the application of Regulation (EU) 2023/956 of the European Parliament and of the Council as regards reporting obligations for the purposes of the carbon border adjustment mechanism during the transitional period',
    link: 'https://eur-lex.europa.eu/eli/reg_impl/2023/1773',
  },
  {
    title:
      'Guidance document on CBAM implementation for importers of goods into the EU',
    link: 'https://taxation-customs.ec.europa.eu/document/download/bc15e68d-566d-4419-88ec-b8f5c6823eb2_en?filename=TAXUD-2023-01189-01-00-EN-ORI-00.pdf',
  },
  {
    title:
      'Guidance document on CBAM implementation for installation operators outside the EU',
    link: 'https://taxation-customs.ec.europa.eu/document/download/2980287c-dca2-4a4b-aff3-db6374806cf7_en?filename=Guidance%20document%20on%20CBAM%20implementation%20for%20installation%20operators%20outside%20the%20EU.pdf',
  },
];

export default function Regulations() {
  return (
    <>
      <Typography variant="h1">Regulations & Guidelines</Typography>
      <br />
      <br />
      {data.map((item, index) => (
        <>
          <StyledPanel key={index}>
            <Typography variant="h4">{item.title}</Typography>
            <br />
            <Link to={item.link}>
              <Button>Read more</Button>
            </Link>
          </StyledPanel>
          <br />
          <br />
        </>
      ))}
    </>
  );
}
