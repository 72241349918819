import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useTranslation } from 'react-i18next';
import { getTargets } from '../../../redux-file/thunk';
import {
  StyledAlert,
  StyledTh,
  StyledHeader,
  StyledTd,
  StyledTdInvisible,
  TitleHeader,
  Title,
} from './index.styles';
import {
  SectionGroupContainer,
  Section,
  PreviewBox,
  PageContainer,
  Description,
} from '../Questionnaire/index.styles';
import PageLoading from '../../../components/PageLoading';
import plusIcon from '../../../images/plus-white.png';
import IconSvg from '../../components/IconSvg';
import { useGhgOrganization } from '../../../common/hooks';
import { Button } from '@mui/material';

export default function TargetsOverview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { targets, targetsError } = useAppSelector((state) => state.app);
  const organization = useGhgOrganization();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getTargets({ organizationId: organization.id })).then(() =>
      setLoading(false)
    );
  }, []);

  const goToAddTarget = () => navigate('/ghg/target/add');
  const goToTargetView = (id: number) => navigate(`/ghg/target/${id}`);

  if (loading) return <PageLoading />;

  return (
    <PageContainer>
      <SectionGroupContainer>
        {targetsError && (
          <Section>
            <StyledAlert severity="error">{t('targets.error')}</StyledAlert>
          </Section>
        )}

        <div>
          <TitleHeader>
            <Title>{t('targets.targets-overview')}</Title>
          </TitleHeader>
          <Description>{t('targets.targets-overview-desc')}</Description>
        </div>

        <Section>
          <PreviewBox>
            <StyledHeader>
              <StyledTh width={31}>{t('targets.domain-header')}</StyledTh>
              <StyledTh width={24}>{t('targets.period-header')}</StyledTh>
              <StyledTh width={21}>{t('targets.method-header')}</StyledTh>
              <StyledTh width={21}>{t('targets.target-value-header')}</StyledTh>
              <StyledTdInvisible width={3} />
            </StyledHeader>
            {targets &&
              targets.map((target, index) => {
                return (
                  <StyledHeader key={index}>
                    <StyledTd width={31} colorIdx={index}>
                      {t(
                        `targets.domains.${target.domain.replace(/\./gi, '-')}`
                      )}
                    </StyledTd>
                    <StyledTd width={24} colorIdx={index}>
                      {target.start_period} - {target.end_period}
                    </StyledTd>
                    <StyledTd width={21} colorIdx={index}>
                      {t(`targets.method.${target.method}`)}
                    </StyledTd>

                    <StyledTd width={21} colorIdx={index}>
                      {target.target_value}
                    </StyledTd>
                    <StyledTdInvisible width={3}>
                      <div>
                        <button
                          type="button"
                          onClick={() => goToTargetView(target.id)}
                        >
                          <IconSvg name="view" />
                        </button>
                      </div>
                    </StyledTdInvisible>
                  </StyledHeader>
                );
              })}
          </PreviewBox>
        </Section>
        <Button
          style={{ marginTop: '20px' }}
          onClick={goToAddTarget}
          startIcon={<img src={plusIcon} alt="plus icon" />}
        >
          <span>{t('targets.add-target-button')}</span>
        </Button>
      </SectionGroupContainer>
    </PageContainer>
  );
}
