import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GhgSupplierCodeOfConductSurveyOutSchema } from 'src/Ghg/CodeOfConductSurvey/types';
import SurveyModalEdit from './Edit';
import SurveyModalCreate from './Create';
import { StyledSubtitle } from './styles';

type CodeOfConductSurveyModalProps = {
  open: boolean;
  onClose: () => void;
  survey: GhgSupplierCodeOfConductSurveyOutSchema | null;
};

export default function CodeOfConductSurveyModal(
  props: CodeOfConductSurveyModalProps
) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ghg.scope3GoodsAndServices.codeOfConduct',
  });
  const [editedSurvey, setEditedSurvey] =
    React.useState<GhgSupplierCodeOfConductSurveyOutSchema | null>(null);

  const survey = editedSurvey || props.survey;

  useEffect(() => {
    setEditedSurvey(null);
  }, [props.open, props.survey]);

  const _onClose = () => {
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={_onClose} maxWidth="lg">
      <DialogTitle>
        <Typography>{t('surveyModal.title')}</Typography>
      </DialogTitle>
      <StyledSubtitle sx={{ maxWidth: '900px', padding: '0 24px' }}>
        {t('surveyModal.subtitle')}{' '}
        <a href="mailto:support@envirly.com">support@envirly.com</a>
      </StyledSubtitle>

      <IconButton
        sx={{ position: 'absolute', right: '10px', top: '10px' }}
        onClick={_onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: '900px' }}>
        <br />
        {!survey && (
          <SurveyModalCreate onCreated={setEditedSurvey} onClose={_onClose} />
        )}
        {survey && <SurveyModalEdit survey={survey} onClose={_onClose} />}
      </DialogContent>
    </Dialog>
  );
}
