import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { styled as styledMUI } from '@mui/material/styles';
import styled from 'styled-components';
import { FieldValueProps } from './index.interfaces';
import { ValueFieldCSS } from '../../../../../components/TextFieldNumber/index.styles';

export const StyledAutocomplete = styledMUI(Autocomplete)<
  AutocompleteProps<any, false, true, true, any>
>(
  (props) => `
  width: 100%;
  border-color: #B6BBB9;
  border-radius: 2px;
  background-color: ${props.value ? '#F2F2F2' : 'white'};
  &:hover {
    border-color: #2F2F2F;
  };
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #165D5C;
    };
  };
  & label.Mui-focused {
    color: #165D5C;
  };
  & .MuiAutocomplete-input {
    color: #132C1D;
    font-size: 18px;
    font-weight: bold;
  };
  & label {
    background-color: ${props.value ? 'white' : 'transparent'};
    padding-right: 7px;
    padding-left: 7px;
    margin-left: -4px;
  };
`
);

export const ValueField = styled.div<FieldValueProps>(
  (props) => `  
    width: ${props.max === 1 ? '100%' : 'calc(50% - 10px)'};
    ${ValueFieldCSS}`
);
