import themeColors from 'src/theme/colors';

// eslint-disable-next-line import/prefer-default-export
export const valueColors: {
  [key: string]: { main: string; contrastText: string };
} = {
  '0': {
    main: '#e0e0e0',
    contrastText: themeColors.esgMain,
  },
  '1': {
    main: '#ECF6FE',
    contrastText: themeColors.esgMain,
  },
  '2': {
    main: '#CDEFFF',
    contrastText: themeColors.esgMain,
  },
  '3': {
    main: '#ACE8FF',
    contrastText: themeColors.esgMain,
  },
  '4': {
    main: '#A6D8F0',
    contrastText: themeColors.esgMain,
  },
  '5': {
    main: '#8CC6EA',
    contrastText: themeColors.esgMain,
  },
  true: {
    main: '#8CC6EA',
    contrastText: themeColors.esgMain,
  },
  false: {
    main: '#CDEFFF',
    contrastText: themeColors.esgMain,
  },
  affected_stakeholder: {
    main: '#CDEFFF',
    contrastText: themeColors.esgMain,
  },
  user: {
    main: '#CDEFFF',
    contrastText: themeColors.esgMain,
  },
  affected_stakeholder_and_user: {
    main: '#A6D8F0',
    contrastText: themeColors.esgMain,
  },
  not_applicable: {
    main: '#ECF6FE',
    contrastText: themeColors.esgMain,
  },
};
