import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';

import theme from 'src/theme';
import colors from 'src/theme/colors';

/* eslint-disable import/prefer-default-export */
const cbam = createTheme(
  deepmerge(theme, {
    palette: {
      primary: {
        main: colors.pureWhite,
        contrastText: colors.cbamDark,
        light: colors.cbamMainLight,
      },
      secondary: {
        main: colors.cbamMain,
        contrastText: colors.cbamDark,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'primary',
        },
        styleOverrides: {
          root: {
            borderRadius: '28px',
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
          primary: {
            background: colors.cbamMain,
            color: colors.cbamDark,
            alignItems: 'center',
            textDecoration: 'none',
            fontWeight: 700,
            lineHeight: '15px',
            border: `1px solid ${colors.cbamMain}`,
            '&:hover': {
              backgroundColor: colors.cbamMainLight,
            },
            '&.pressed': {
              backgroundColor: colors.cbamMainLighter,
            },
            '&.active': {
              backgroundColor: colors.cbamMain,
              color: colors.cbamDark,
            },
            '&.disabled:': {
              backgroundColor: colors.disabledGraphics,
            },
          },
          secondary: {
            border: `2px solid var(--brand-color-main, ${colors.disabledGraphics})`,
            background: `var(--White, ${colors.pureWhite})`,
            boxShadow: 'none',
            color: colors.cbamDark,
            textAlign: 'center',
            lineHeight: '15px',
            '&:hover': {
              backgroundColor: colors.hover,
            },
            '&:active': {
              backgroundColor: colors.pressed,
              boxShadow: 'none',
            },
            '&:disabled': {
              color: colors.grayInactive,
              backgroundColor: colors.pureWhite,
              border: `2px solid var(--brand-color-main, ${colors.grayInactive})`,
              boxShadow: `0 3px 0px ${colors.strokePressed}`,
            },
          },
          sizeSmall: {
            height: '32px',
            fontSize: '13px',
          },
          sizeMedium: {
            height: '40px',
            fontSize: '15px',
          },
          sizeLarge: {
            height: '40px',
            fontSize: '16px',
          },
        },
      },
      MuiRadio: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiSelect: {
        defaultProps: {
          color: 'secondary',
        },
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
      MuiInputLabel: {
        defaultProps: { color: 'secondary' },
      },
      MuiCheckbox: {
        defaultProps: { color: 'secondary' },
      },
      MuiTextField: {
        defaultProps: {
          color: 'secondary',
        },
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              borderRadius: '8px',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            alignItems: 'flex-start',
            '& .MuiCheckbox-root': {
              paddingTop: '0px',
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: '24px',
            '&:hover': {
              backgroundColor: colors.cbamMainLight,
            },
            '&.pressed': {
              backgroundColor: colors.cbamMainLighter,
            },
            '&.active': {
              backgroundColor: colors.cbamMain,
              color: colors.cbamDark,
            },
            '&.disabled:': {
              backgroundColor: colors.disabledGraphics,
            },
          },
        },
      },
    },
  })
);

export default cbam;
