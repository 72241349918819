import React from 'react';
import { useTranslation } from 'react-i18next';
import linkedInIcon from './images/LinkedIn_icon.png';
import linkedInIconWhite from './images/LinkedIn_icon_white.png';
import phoneIcon from './images/smartphone-24px-1.png';
import phoneIconWhite from './images/smartphone_white.png';
import mailIcon from './images/Mail_icon.png';
import mailIconWhite from './images/Mail_icon_white.png';
import { useLocation } from 'react-router-dom';
import { appConfig } from 'src/config';
import { useAppSelector } from 'src/redux-file/hooks';
import { OrganizationTag } from 'src/utils/tags';
import { useHelpCenterUrl } from 'src/common/sidebar';
import { Box, Typography, Link, Grid } from '@mui/material';
import {
  FooterIcon,
  FooterLinkWrapper,
  StyledFooter,
  SubTitleTypography,
  SubtitleLink,
  TextWithIconBox,
  TitleTypography,
} from './index.styles';

export default function Footer() {
  const { pathname } = useLocation();
  const tags = useAppSelector((state) => state.platform.tags);
  const isBnp = tags.includes(OrganizationTag.mBNP);
  const isLca = pathname.startsWith('/lca');
  const { t } = useTranslation();
  const apiUrl = `${appConfig.backendApiUrl}/docs`;
  const helpCenterUrl = useHelpCenterUrl();

  return (
    <StyledFooter>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <Typography variant="h1">{t('footer.envirly')}</Typography>
          <Typography variant="subtitle2" sx={{ mb: '40px' }}>
            {t('footer.by-q')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'end' }}>
            <Typography variant="captionText">
              {t('footer.dont-miss-out')}
            </Typography>
            <Link
              href="https://www.linkedin.com/company/envirly"
              target="_blank"
              rel="noreferrer"
              sx={{ display: 'flex', alignItems: 'center', ml: 1 }}
            >
              <img
                src={isLca ? linkedInIconWhite : linkedInIcon}
                alt="LinkedIn Icon"
                width="22"
                style={{ margin: '0 12px' }}
              />
            </Link>
          </Box>
        </Grid>
        <Grid item md={3}>
          <Typography variant="h3" sx={{ mb: '13px' }}>
            {t('footer.address-title')}
          </Typography>
          <SubTitleTypography variant="h6">
            {t('footer.company-name')}
          </SubTitleTypography>
          <SubTitleTypography variant="body2">
            {t('footer.company-street')}
          </SubTitleTypography>
          <SubTitleTypography variant="body2">
            {t('footer.company-city')}
          </SubTitleTypography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="h3" sx={{ mb: '13px' }}>
            {t('footer.see-more')}
          </Typography>
          {isBnp && (
            <Box>
              <SubtitleLink
                variant="body2"
                href="https://assets.envirly.pl/regulamin-mikro-bnp.pdf"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.termsOfService')}
              </SubtitleLink>
            </Box>
          )}
          <FooterLinkWrapper>
            <SubtitleLink
              variant="body2"
              href="https://www.envirly.pl/polityka-prywatnosci.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {t('footer.privacyPolicy')}
            </SubtitleLink>
          </FooterLinkWrapper>
          <FooterLinkWrapper>
            <SubtitleLink
              sx={{ marginBottom: '10px' }}
              href={helpCenterUrl}
              target="_blank"
              rel="noreferrer"
              variant="body2"
            >
              {t('footer.helpCenter')}
            </SubtitleLink>
          </FooterLinkWrapper>
          <FooterLinkWrapper>
            <SubtitleLink
              href={apiUrl}
              target="_blank"
              rel="noreferrer"
              variant="body2"
            >
              {t('footer.api')}
            </SubtitleLink>
          </FooterLinkWrapper>
        </Grid>
        <Grid item md={3}>
          <TitleTypography variant="h3">{t('footer.contact')}</TitleTypography>
          <TextWithIconBox>
            <FooterIcon
              src={isLca ? phoneIconWhite : phoneIcon}
              alt="Phone Icon"
            />
            <Typography variant="body2"> {t('footer.phone-us')} </Typography>
          </TextWithIconBox>
          <TextWithIconBox>
            <FooterIcon
              src={isLca ? phoneIconWhite : phoneIcon}
              alt="Phone Icon"
            />
            <Typography variant="body2">{t('footer.phone-pl')}</Typography>
          </TextWithIconBox>
          <TextWithIconBox>
            <img
              style={{ width: '15px', height: '12px', margin: '0 10px 0 2px' }}
              src={isLca ? mailIconWhite : mailIcon}
              alt="Mail Icon"
            />
            <Typography variant="body2">{t('footer.email')}</Typography>
          </TextWithIconBox>
        </Grid>
      </Grid>
    </StyledFooter>
  );
}
