import React, { useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQueryClient } from 'react-query';
import { client } from '../../../../utils/api-client';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Dialog, Typography } from '@mui/material';
import { useEsgOrganization } from '../../../../common/hooks';
import { toast } from 'react-toastify';

interface Props {
  open: boolean;
  setClose: () => void;
  id: string;
}

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
`;

export default function DeleteReportModal({ setClose, open, id }: Props) {
  const organization = useEsgOrganization();
  const organizationId = organization.id;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [doubleCheckPhase, setDoubleCheckPhase] = useState(false);

  const _setClose = () => {
    setClose();
    setTimeout(() => {
      setDoubleCheckPhase(false);
    }, 500);
  };

  const { mutate } = useMutation({
    mutationFn: () => {
      return client.delete(`web/esg_v2/${organizationId}/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`esg-reports`] });
      _setClose();
      toast.success(t('esg.removeReportModal.toast.success') as string);
    },
  });

  return (
    <Dialog open={open} onClose={_setClose}>
      {!doubleCheckPhase ? (
        <>
          <Typography variant="h2" sx={{ textAlign: 'center' }}>
            {t('esg.removeReportModal.title')}
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', margin: '20px 0' }}
          >
            <Trans i18nKey="esg.removeReportModal.subtitle" />
          </Typography>

          <ButtonsWrapper>
            <Button
              variant="secondary"
              size="large"
              onClick={() => {
                setDoubleCheckPhase(true);
              }}
            >
              {t('esg.removeReportModal.buttons.remove')}
            </Button>
            <Button size="large" onClick={setClose}>
              {t('esg.removeReportModal.buttons.cancel')}
            </Button>
          </ButtonsWrapper>
        </>
      ) : (
        <>
          <Typography variant="h2" sx={{ textAlign: 'center' }}>
            {t('esg.removeReportModalDoubleCheck.title')}
          </Typography>
          <Typography
            sx={{
              fontSize: '18px',
              textAlign: 'center',
              fontStyle: 'regular',
              color: '#38414f',
              margin: '20px 0',
            }}
          >
            <Trans i18nKey="esg.removeReportModalDoubleCheck.subtitle" />
          </Typography>
          <ButtonsWrapper>
            <Button
              style={{ border: '1px solid #A03137', color: '#A03137' }}
              size="large"
              variant="secondary"
              onClick={() => {
                mutate();
              }}
            >
              {t('esg.removeReportModalDoubleCheck.buttons.remove')}
            </Button>
            <Button
              size="large"
              onClick={() => {
                setDoubleCheckPhase(false);
                setClose();
              }}
            >
              {t('esg.removeReportModalDoubleCheck.buttons.cancel')}
            </Button>
          </ButtonsWrapper>
        </>
      )}
    </Dialog>
  );
}
