import React, { useState } from 'react';
import { Container } from '../ReportsIndex/index.styles';
import { useTranslation } from 'react-i18next';
import { Box } from './index.styles';

import { IntuitiveCalendarPeriod } from '../../../components/IntuitiveCalendar';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from '../../../../utils/api-client';
import { useEsgOrganization } from 'src/common/hooks';
import { useNavigate } from 'react-router-dom';
import ReportModal from './modal';
import { StyledTextField } from '../../../../components/esg/Inputs/index.styles';
import { Button, Dialog, Typography } from '@mui/material';

export default function CreateESGReport() {
  const { t } = useTranslation();
  const organization = useEsgOrganization();
  const [name, setName] = useState('');
  const [date, setDate] = useState<Date | null>(null);
  const nav = useNavigate();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const queryClient = useQueryClient();
  const [existedReport, setExistedReport] = useState([
    {
      last_update: '',
      name: '',
      period: '',
      id: '',
    },
  ]);

  const { data } = useQuery(['report_index', organization.id], () =>
    client.get(`/web/esg_v2/${organization.id}`)
  );

  const mutation = useMutation({
    mutationFn: () => {
      return client.post(`web/esg_v2/${organization.id}`, {
        name,
        period: date?.getFullYear(),
      });
    },
    onSuccess: ({ data }) => {
      nav(`/esg/reports/${data.id}/indicators`);
      setOpenErrorModal(false);
      queryClient.invalidateQueries({ queryKey: ['esg-reports'] });
    },
  });

  const reports = data?.data.filter(
    (el: { period: string }) => el.period === `${date?.getFullYear()}`
  );

  return (
    <Container>
      <Typography variant="h1" sx={{ marginBottom: '20px' }}>
        {t('esg.new-report')}
      </Typography>
      <Box>
        <StyledTextField
          mainColor="rgb(56, 65, 79)"
          label={t('esg.name-report')}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </Box>
      <IntuitiveCalendarPeriod
        reportingPeriod="Y"
        selectedDate={date}
        setSelectedDate={(selDate: Date) => setDate(selDate)}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        <Button
          size="large"
          disabled={name === '' || date === null}
          onClick={() => {
            if (reports.length) {
              setOpenErrorModal(true);
              setExistedReport(reports);
            } else {
              mutation.mutate();
            }
          }}
        >
          {t('esg.create')}
        </Button>
      </div>
      <Dialog
        open={openErrorModal}
        onClose={() => setOpenErrorModal(!openErrorModal)}
      >
        <ReportModal
          newName={name}
          orgId={organization.id}
          report={existedReport[0]}
          setClose={setOpenErrorModal}
        />
      </Dialog>
    </Container>
  );
}
