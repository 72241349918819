import React, { useState } from 'react';
import styled from 'styled-components';
import themeColors from '../../../../theme/colors';

const Modal = styled.div`
  position: absolute;
  display: flex;
  width: 456px;
  height: 100%;
  bottom: 24px;
  left: 24px;
  z-index: 102;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;

  color: ${themeColors.grayIcons};
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;

  align-self: flex-end;
  background: ${themeColors.pureWhite};
  border: 1px solid ${themeColors.strokePressed};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  border-bottom-left-radius: 0px;
  padding: 24px;
  width: 456px;
`;

const StyledButton = styled.button`
  background: none;
  display: flex;
  width: 21px;
  height: 21px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  :hover {
    background-color: #fff;
  }

  svg {
    height: 32px;
    flex-shrink: 0;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function CommentBtn({
  state,
  id,
}: {
  state: any[];
  id: number;
}) {
  const [showComment, setShowComment] = useState(false);

  return (
    <Container className="comment">
      {state[id].comment && (
        <StyledButton
          type="button"
          onMouseOver={() => setShowComment(true)}
          onMouseLeave={() => setShowComment(false)}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.00693 11.3C9.00693 10.585 9.624 10 10.3867 10H21.48C22.2427 10 22.8667 10.585 22.8667 11.3V19.1C22.8667 19.815 22.2427 20.4 21.48 20.4H11.7733L9 23L9.00693 11.3ZM11.6 13.4667C11.6 12.988 11.988 12.6 12.4667 12.6H19.4C19.8786 12.6 20.2667 12.988 20.2667 13.4667C20.2667 13.9453 19.8786 14.3333 19.4 14.3333H12.4667C11.988 14.3333 11.6 13.9453 11.6 13.4667ZM12.4667 16.0667C11.988 16.0667 11.6 16.4547 11.6 16.9333C11.6 17.412 11.988 17.8 12.4667 17.8H19.4C19.8786 17.8 20.2667 17.412 20.2667 16.9333C20.2667 16.4547 19.8786 16.0667 19.4 16.0667H12.4667Z"
              fill={showComment ? '#165D5C' : '#165D5C'}
            />
          </svg>
        </StyledButton>
      )}
      {state[id].comment && showComment && (
        <Modal>
          <Box>{state[id].comment}</Box>
        </Modal>
      )}
    </Container>
  );
}
