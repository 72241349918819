import React, { ButtonHTMLAttributes, HTMLAttributes } from 'react';

/* eslint-disable no-unused-vars */

import {
  Checkbox,
  CheckboxProps,
  styled as styledMUI,
  TextField,
  TextFieldProps,
} from '@mui/material';
import styled from 'styled-components';

export const StyledCheckbox = styledMUI(Checkbox)<CheckboxProps>`
  color: #165D5C;
  margin-right: 8px;
  &.Mui-checked {
    color: #165D5C;
  }
`;

export const Task = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Icon = styled.img`
  margin: 4px 1px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: #eee;
  }
`;

export const StyledTextField = styledMUI(TextField)<TextFieldProps>(
  (props) => `
  width: 100%;
  border-color: #B6BBB9;
  border-radius: 2px;
  margin-top: 20px;
  background-color: ${props.value ? '#F2F2F2' : 'white'};
  & :hover {
    border-color: #2F2F2F;
  },
  & .MuiInputBase-root.MuiFilledInput-root:after {
      border-color: #165D5C;
  },
  & label {
    background-color: ${props.value ? 'white' : 'transparent'};
    padding-right: 7px;
    padding-left: 7px;
    margin-left: -4px;
  },
  input {
    color: #132C1D;
    font-size: 18px;
    font-weight: bold;
  },
`
);

export const Button = styled(
  ({
    clicked,
    ...props
  }: { clicked: boolean } & ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button type="button" {...props} />
  )
)`
  display: flex;
  border: 1px solid ${(props) => (props.clicked ? '#165D5C' : '#d9dfdd')};
  border-radius: 1.5rem;
  padding: 8px 30px;
  align-items: center;
  cursor: pointer;
  margin: 20px ${(props) => (props.clicked ? 0 : 'auto')} 0
    ${(props) => (props.clicked ? 'auto' : 0)};
  background: ${(props) => (props.clicked ? '#165D5C' : 'transparent')};

  &:hover {
    background-color: ${(props) => (props.clicked ? '#165D5Cdd' : '#d9dfdd40')};
  }

  & > span {
    font-weight: 700;
    font-size: 0.875rem;
    color: ${(props) => (props.clicked ? '#ffffff' : '#165D5C')};
    margin-right: 0.7rem;
  }

  & > img {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.7rem;
  }
`;

export const Label = styled(
  ({
    checked,
    ...props
  }: { checked: boolean } & HTMLAttributes<HTMLDivElement>) => (
    <div {...props} />
  )
)`
  color: ${(props) => (props.checked ? 'grey' : 'black')};
  text-decoration: ${(props) => (props.checked ? 'line-through' : 'none')};
  font-weight: 600;
`;
