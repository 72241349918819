import styled from 'styled-components';

export const MainMetricsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const MainMetricsPanel = styled.div`
  border-radius: 16px;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 17px 24px;
  width: 100%;
`;

export const StyledTextLight = styled.div`
  color: #49525f;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.14px;
`;

export const BigNumbers = styled.div`
  color: #38414f;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px; /* 95% */
  letter-spacing: 0.8px;
  margin-top: 10px;
`;

export const StyledText = styled.div`
  color: #38414f;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 170% */
  letter-spacing: 0.4px;
`;

export const MatrixAxesText = styled.div`
  color: #38414f;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.26px;
`;
