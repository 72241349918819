import styled, { css } from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import { Button } from '@mui/material';
import themeColors from '../../../theme/colors';

export const Wrapper = styled.div`
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: ${themeColors.pureWhite};
  border-radius: 16px;
  z-index: 7;
  padding: 36px;
  border: 1px solid #ddd;

  .MuiFormGroup-root .MuiTypography-root.MuiFormControlLabel-label {
    font-weight: 400;
  }

  .MuiButtonBase-root.MuiCheckbox-root {
    margin-top: -9px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 6;
  background-color: black;
  opacity: 0.4;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  margin-bottom: 24px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 18px;
  align-items: flex-start;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.03em;
    color: #2f2f2f;
    cursor: pointer;

    a {
      color: ${themeColors.greenBrand};
      font-weight: 600;

      &:hover {
        color: ${themeColors.greenHover};
      }
    }
  }
`;

export const Required = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #ad5356;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SaveButton = styledMUI(Button)`
  height: 56px;
  border-radius: 28px;
  padding-left: 36px;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.02em;
  background: #165D5C;
  color: #fff;
  border: 2px solid #165D5C;
  &:disabled {
    color: #b6bbb9;
    background: #f2f2f2;
    border: 2px solid #f2f2f2;
  }
  :hover {
    background: #276E6D;
    border: 2px solid #276E6D;
  }
  :pressed {
  background: #387F7E;
  border: 2px solid #387F7E;
  }
`;
