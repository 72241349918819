/* eslint-disable no-unused-vars */
import { AggregatedGoodsCategory } from './types';

export enum ProductionMethods {
  P01 = 'P01',
  P02 = 'P02',
  P03 = 'P03',
  P04 = 'P04',
  P05 = 'P05',
  P08 = 'P08',
  P09 = 'P09',
  P10 = 'P10',
  P11 = 'P11',
  P12 = 'P12',
  P13 = 'P13',
  P14 = 'P14',
  P15 = 'P15',
  P16 = 'P16',
  P17 = 'P17',
  P19 = 'P19',
  P20 = 'P20',
  P21 = 'P21',
  P22 = 'P22',
  P23 = 'P23',
  P24 = 'P24',
  P25 = 'P25',
  P26 = 'P26',
  P27 = 'P27',
  P28 = 'P28',
  P29 = 'P29',
  P30 = 'P30',
  P31 = 'P31',
  P32 = 'P32',
  P33 = 'P33',
  P34 = 'P34',
  P35 = 'P35',
  P36 = 'P36',
  P37 = 'P37',
  P38 = 'P38',
  P39 = 'P39',
  P40 = 'P40',
  P41 = 'P41',
  P42 = 'P42',
  P43 = 'P43',
  P44 = 'P44',
  P45 = 'P45',
  P46 = 'P46',
  P47 = 'P47',
  P48 = 'P48',
}

export const ProductionMethodNamesMap: { [_key in ProductionMethods]: string } =
  {
    [ProductionMethods.P01]: 'Calcined clay',
    [ProductionMethods.P02]: 'Cement clinker',
    [ProductionMethods.P03]: 'Cement',
    [ProductionMethods.P04]: 'Aluminuous cement',
    [ProductionMethods.P05]: 'Sintered ore',
    [ProductionMethods.P08]: 'Electricity',
    [ProductionMethods.P09]: 'Steam reforming',
    [ProductionMethods.P10]: 'Partial oxidation',
    [ProductionMethods.P11]: 'Other fuel-based hydrogen production',
    [ProductionMethods.P12]: 'Electrolysis of water',
    [ProductionMethods.P13]: 'Electrolysis of water (other energy sources)',
    [ProductionMethods.P14]: 'Chlor-Alkali electrolysis',
    [ProductionMethods.P15]: 'Production of chlorates',
    [ProductionMethods.P16]: 'Other production routes',
    [ProductionMethods.P17]: 'Nitric acid',
    [ProductionMethods.P19]:
      'Haber-Bosch process with steam reforming of natural gas or biogas',
    [ProductionMethods.P20]:
      'Haber-Bosch process with gasification of coal or other fuels',
    [ProductionMethods.P21]: 'Other production routes',
    [ProductionMethods.P22]: 'Mixed fertilisers',
    [ProductionMethods.P23]: 'Urea',
    [ProductionMethods.P24]: 'Blast furnace route',
    [ProductionMethods.P25]: 'Smelting reduction',
    [ProductionMethods.P26]: 'Other production routes',
    [ProductionMethods.P27]: 'Nickel Pig Iron production',
    [ProductionMethods.P28]: 'Nickel Pig Iron production',
    [ProductionMethods.P29]: 'Ferro-nickel (FeNi)',
    [ProductionMethods.P30]: 'Ferro-chromium (FeCr)',
    [ProductionMethods.P31]: 'Ferro-manganese (FeMn)',
    [ProductionMethods.P32]:
      'Production of Direct reduced Iron (using hydrogen)',
    [ProductionMethods.P33]: 'DRI (Direct reduced iron)',
    [ProductionMethods.P34]: 'Iron or steel products',
    [ProductionMethods.P35]: 'Basic oxigen steel making',
    [ProductionMethods.P36]: 'Basic oxigen steel making (incl. Blast furnace)',
    [ProductionMethods.P37]:
      'Basic oxigen steel making (incl. Melting reduction)',
    [ProductionMethods.P38]: 'Electric arc furnace',
    [ProductionMethods.P39]: 'Electric arc furnace (alloy steels))',
    [ProductionMethods.P40]:
      'Electric arc furnace (carbon steel, from direct reduced iron)',
    [ProductionMethods.P41]: 'Electric arc furnace (general)',
    [ProductionMethods.P42]: 'Primary (electrolytic) smelting',
    [ProductionMethods.P43]: 'Secondary melting (recycling)',
    [ProductionMethods.P44]: 'Mix of primary and secondary production',
    [ProductionMethods.P45]: 'Aluminium products',
    [ProductionMethods.P46]: 'Integrated production with primary smelting',
    [ProductionMethods.P47]: 'Integrated production with secondary melting',
    [ProductionMethods.P48]:
      'Integrated with mixed primary and secondary production',
  };

export const ProductionMethodsForAggregatedGoodsCategory: {
  [_key in AggregatedGoodsCategory]: ProductionMethods[];
} = {
  [AggregatedGoodsCategory.Hydrogen]: [
    ProductionMethods.P09,
    ProductionMethods.P10,
    ProductionMethods.P11,
    ProductionMethods.P12,
    ProductionMethods.P13,
    ProductionMethods.P14,
    ProductionMethods.P15,
    ProductionMethods.P16,
  ],
  [AggregatedGoodsCategory.Ammonia]: [
    ProductionMethods.P19,
    ProductionMethods.P20,
    ProductionMethods.P21,
  ],
  [AggregatedGoodsCategory.PigIron]: [
    ProductionMethods.P24,
    ProductionMethods.P25,
    ProductionMethods.P27,
  ],
  [AggregatedGoodsCategory.FeMn]: [
    ProductionMethods.P28,
    ProductionMethods.P29,
    ProductionMethods.P30,
    ProductionMethods.P31,
  ],
  [AggregatedGoodsCategory.FeNi]: [
    ProductionMethods.P28,
    ProductionMethods.P29,
    ProductionMethods.P30,
    ProductionMethods.P31,
  ],
  [AggregatedGoodsCategory.FeCr]: [
    ProductionMethods.P28,
    ProductionMethods.P29,
    ProductionMethods.P30,
    ProductionMethods.P31,
  ],
  [AggregatedGoodsCategory.DRI]: [ProductionMethods.P32, ProductionMethods.P33],
  [AggregatedGoodsCategory.CrudeSteel]: [
    ProductionMethods.P35,
    ProductionMethods.P36,
    ProductionMethods.P37,
    ProductionMethods.P38,
    ProductionMethods.P39,
    ProductionMethods.P40,
    ProductionMethods.P41,
  ],
  [AggregatedGoodsCategory.UnwroughtAluminium]: [
    ProductionMethods.P42,
    ProductionMethods.P43,
    ProductionMethods.P44,
  ],
  [AggregatedGoodsCategory.AluminiumProducts]: [
    ProductionMethods.P45,
    ProductionMethods.P46,
    ProductionMethods.P47,
    ProductionMethods.P48,
  ],
  [AggregatedGoodsCategory.CementClinker]: [],
  [AggregatedGoodsCategory.AluminousCement]: [],
  [AggregatedGoodsCategory.Electricity]: [],
  [AggregatedGoodsCategory.SinteredOre]: [],
  [AggregatedGoodsCategory.CalcinedClay]: [],
  [AggregatedGoodsCategory.Cement]: [],
  [AggregatedGoodsCategory.NitricAcid]: [],
  [AggregatedGoodsCategory.Urea]: [],
  [AggregatedGoodsCategory.MixedFertilisers]: [],
  [AggregatedGoodsCategory.IronOrSteelProducts]: [],
};
