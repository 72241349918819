import Radio, { RadioProps } from '@mui/material/Radio';
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { styled as styledMUI } from '@mui/material/styles';

export const StyledRadio = styledMUI(Radio)<RadioProps>`
  &.Mui-checked {
    color: #165D5C;
  }
`;

export const StyledFormControlLabel = styledMUI(
  FormControlLabel
)<FormControlLabelProps>`
  color: black;
  & .MuiRadio-root {
    padding: 7px 4px;
  }
`;

export const StyledFormLabel = styledMUI(FormLabel)<FormLabelProps>`
  font-weight: bold;
  margin-bottom: 10px;
  &.Mui-focused {
    color: #165D5C;
  }
`;

export const StyledFormControl = styledMUI(FormControl)<FormControlProps>`
  label {
    margin-left: 0;
  }
  `;
