import styled, { css } from 'styled-components';
import themeColors from '../../../theme/colors';

const GreenButton = css`
  background-color: ${themeColors.greenBrand};
  color: #ffffff;
  display: flex;
  align-items: center;
  font-weight: 600;
`;

export const FromButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const ButtonWhite = styled.button`
  background-color: ${themeColors.pureWhite};
  border: 1px solid ${themeColors.strokePressed};
  display: flex;
  align-items: center;
  color: ${themeColors.greenBrand};
  height: 48px;
  border-radius: 28px;
  font-size: 18px;
  padding: 0 20px;
  font-weight: 600;
`;

export const ButtonGreen = styled.button`
  background-color: ${themeColors.greenBrand};
  color: #ffffff;
  border: 1px solid ${themeColors.greenBrand};
  display: flex;
  align-items: center;

  height: 48px;
  border-radius: 28px;
  font-size: 18px;
  padding: 0 20px;
  font-weight: 600;
`;

export const ToButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;
export const CloseBtn = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;
export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
  z-index: 10;

  & > div {
    width: 700px;
    text-align: center;
  }
`;

export const WarningModal = styled.div`
  position: absolute;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #dddddd;
  padding: 24px 24px 4px;
  margin-bottom: 24px;
  z-index: 20;
  width: 556px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
  text-align: center;
`;

export const WarningModalButtonsBox = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0;

  & > button {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MainTitle = css`
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
`;

export const Title = styled.p`
  ${MainTitle};
  margin-bottom: 40px;
`;

export const ToTitle = styled.p`
  ${MainTitle};
  margin-bottom: 12px;
`;

export const WarningText = styled.p`
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 32px;
`;

export const WarningTitle = styled.p`
  ${MainTitle};
  margin-bottom: 12px;
`;
