import React, { useEffect, useRef, useState } from 'react';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import {
  SaveProductButtonWrapper,
  Description,
  PageContainer,
  Section,
  SectionGroupContainer,
  SaveButton,
  PreviewBox,
  CommentTextArea,
  DeleteButton,
  AnotherButtonWrapper,
} from './index.styles';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../Targets/index.styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import QuestionnaireSectionTitle from '../../components/QuestionnaireSectionTitle';
import {
  comment_cloud,
  fuel_and_raw_materials_cloud,
  fuel_and_raw_materials_waste_cloud,
} from './clouds_data';
import TotalAmountForm from '../../components/Questionnaire/forms/fuels-and-raw-materials/total-amount';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { timeConverter } from '../../../utils/timeConverter';
import {
  deleteFuelProduct,
  editSoldProductsFuelOrFeedstockData,
  getFuelProduct,
  getSoldProductsData,
} from '../../../redux-file/questionnaires/thunks';
import { index_url } from './index-sold-products';
import SoldProductsWasteForm from '../../components/Questionnaire/forms/fuels-and-raw-materials/waste-form';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import { fuel_or_feedstock_init, waste_init } from './fuels-and-raw-materials';
import AddProductButton from '../../../components/AddProductButton';
import styles from './styles.module.css';
import UploadExcelButton from '../../../components/UploadExcelButton';

export default function FuelsAndRawMaterialsEdit() {
  const { id } = useParams<{ id: string }>();
  const { selectedDate, soldProductFuel, soldProductsFuelError } =
    useAppSelector((state) => state.questionnaire);

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const waste_ref = useRef(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (id && !!reportingPeriod && !!selectedDate) {
      dispatch(
        getFuelProduct({
          period: timeConverter(selectedDate, reportingPeriod),
          participant_id: id,
        })
      );
    }
  }, [id, selectedDate, reportingPeriod]);

  const [fuel_or_feedstock, set_fuel_or_feedstock] = useState(
    fuel_or_feedstock_init
  );
  const [waste_state, set_waste_state] = useState([waste_init]);

  const [activeTotal, setActiveTotal] = useState(false);
  const [active1, setActive1] = useState<undefined | number>(undefined);

  useEffect(() => {
    if (!!soldProductFuel) {
      set_fuel_or_feedstock(soldProductFuel.fuel_or_feedstock);
      set_waste_state(
        soldProductFuel.waste === undefined ||
          soldProductFuel.waste.length === 0
          ? [waste_init]
          : soldProductFuel.waste
      );
    }
  }, [soldProductFuel]);

  const combinedData = {
    fuel_or_feedstock,
    waste:
      waste_state.length === 1 && waste_state[0].waste_type === ''
        ? []
        : waste_state,
  };

  const combineWasteData = (fullData: any, newData: any) => {
    set_waste_state([...newData, ...fullData.waste]);
  };

  if (soldProductsFuelError) return <SomethingWentWrong />;
  return (
    <>
      <PageContainer>
        <SectionGroupContainer>
          <Breadcrumbs>
            <Link to={index_url}>{t('menu.sold-products')}</Link>
            <span>{'>'}</span>
            <div>{t(`sold-products.fuels-and-raw-materials.title`)}</div>
          </Breadcrumbs>
          <QuestionnaireTitle
            info
            text={t('sold-products.fuels-and-raw-materials.title')}
          />
          <Description>
            {t('sold-products.fuels-and-raw-materials.description')}
          </Description>

          <Section>
            <QuestionnaireSectionTitle
              active={false}
              level="primary"
              text={t('sold-products.fuels-and-raw-materials.basic')}
            />
          </Section>
          <Section>
            <QuestionnaireSectionTitle
              active={activeTotal}
              level="secondary"
              boxContent={fuel_and_raw_materials_cloud}
              text={t('sold-products.fuels-and-raw-materials.total.title')}
            />

            <PreviewBox>
              <TotalAmountForm
                setActive={setActiveTotal}
                active={activeTotal}
                state={fuel_or_feedstock}
                setState={set_fuel_or_feedstock}
              />
            </PreviewBox>
          </Section>

          <Section>
            <QuestionnaireSectionTitle
              active={false}
              level="secondary"
              boxContent={comment_cloud}
              text={t('sold-products.fuels-and-raw-materials.comment.title')}
            />
            <PreviewBox>
              <CommentTextArea
                placeholder={t(
                  'sold-products.fuels-and-raw-materials.comment.placeholder'
                )}
                value={fuel_or_feedstock.comment}
                onChange={(e) => {
                  const tempState = {
                    name: fuel_or_feedstock.name,
                    type_of_fuel: fuel_or_feedstock.type_of_fuel,
                    unit: fuel_or_feedstock.unit,
                    amount: fuel_or_feedstock.amount,
                    manual_factor: fuel_or_feedstock.manual_factor,
                    comment: e.target.value,
                  };

                  set_fuel_or_feedstock(tempState);
                }}
              />
            </PreviewBox>
          </Section>

          <Section>
            <QuestionnaireSectionTitle
              active={false}
              level="primary"
              text={t('sold-products.final-products.direct.waste.main')}
            />
          </Section>
          <Section>
            <div ref={waste_ref} />
            <QuestionnaireSectionTitle
              active={active1}
              level="secondary"
              boxContent={fuel_and_raw_materials_waste_cloud}
              text={t('sold-products.fuels-and-raw-materials.waste.title')}
            />

            {waste_state.map((_, idx) => (
              <PreviewBox key={idx}>
                <SoldProductsWasteForm
                  setNewForm={() => {}}
                  id={idx}
                  setState={set_waste_state}
                  state={waste_state}
                  active={active1}
                  setActive={setActive1}
                />
              </PreviewBox>
            ))}
            <AnotherButtonWrapper>
              {waste_state[waste_state.length - 1].waste_type !== '' &&
                waste_state[waste_state.length - 1].amount_kg !== '' &&
                (waste_state[waste_state.length - 1].waste_type !==
                  'other.manual_factor' ||
                  !!waste_state[waste_state.length - 1].manual_factor) && (
                  <>
                    <AddProductButton
                      onClick={() => {
                        set_waste_state([waste_init, ...waste_state]);
                        setActive1(0);
                        if (waste_ref && waste_ref.current) {
                          // @ts-ignore
                          waste_ref.current.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }
                      }}
                    />
                    <span>{t('popup.excel.or')}</span>
                  </>
                )}
              <UploadExcelButton
                name={t('sold-products.fuels-and-raw-materials.waste.title')}
                slug="WasteData"
                fullData={combinedData}
                combineData={combineWasteData}
                sendData={() => {}}
                refresh={() => {}}
              />
            </AnotherButtonWrapper>
          </Section>

          <Section>
            <SaveProductButtonWrapper twoButtons>
              <DeleteButton type="button" onClick={() => setShowModal(true)}>
                {t('sold-products.buttons.delete')}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2809_77388)">
                    <path
                      d="M7 11V13H17V11H7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                      fill="#165D5C"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2809_77388">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </DeleteButton>
              <SaveButton
                disabled={fuel_or_feedstock.type_of_fuel === ''}
                type="button"
                onClick={() => {
                  if (id) {
                    dispatch(
                      editSoldProductsFuelOrFeedstockData({
                        period: timeConverter(selectedDate, reportingPeriod),
                        data: combinedData,
                        participant_id: id,
                      })
                    )
                      .then(() =>
                        dispatch(
                          getSoldProductsData({
                            period: timeConverter(
                              selectedDate,
                              reportingPeriod
                            ),
                          })
                        )
                      )
                      .finally(() => navigate(index_url));
                  }
                }}
              >
                {t('sold-products.buttons.edit')}
              </SaveButton>
            </SaveProductButtonWrapper>
          </Section>
        </SectionGroupContainer>
      </PageContainer>
      {showModal && (
        <div className={styles.deleteModal}>
          <div className={styles.deleteModalOverlay} />
          <div className={styles.deleteModalBox}>
            <button
              type="button"
              className={styles.deleteModalClose}
              onClick={() => setShowModal(false)}
            >
              x
            </button>
            <h2 className={styles.deleteModalText}>
              {t('sold-products.delete-modal')}
            </h2>
            <div className={styles.deleteModalButtonsBox}>
              <button
                className={styles.deleteModalButtonPrimary}
                type="button"
                onClick={() => {
                  if (id) {
                    dispatch(
                      deleteFuelProduct({
                        period: timeConverter(selectedDate, reportingPeriod),
                        participant_id: id,
                      })
                    )
                      .then(() =>
                        dispatch(
                          getSoldProductsData({
                            period: timeConverter(
                              selectedDate,
                              reportingPeriod
                            ),
                          })
                        )
                      )
                      .finally(() => navigate(index_url));
                  }
                }}
              >
                {t('targets.yes')}
              </button>
              <button
                className={styles.deleteModalButtonSecondary}
                type="button"
                onClick={() => setShowModal(false)}
              >
                {t('targets.no')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
