import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { StyledPanel } from 'src/components/StyledPanel';
import NavigationBlockerModal from 'src/components/NavigationBlockerModal';
import { useParams } from 'react-router-dom';
import { useActors, useImportedGoods, useReports } from '../hooks';
import { CbamReportInSchema, CbamReportOutSchema } from '../types';
import { toast } from 'react-toastify';
import { IntuitiveCalendarPeriod } from 'src/views/components/IntuitiveCalendar';
import { ReportingFreq } from 'src/utils/api.interfaces';
import { periodToDate, timeConverter } from 'src/utils/timeConverter';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { StyledFlex } from 'src/components/StyledFlex';
import { dateFormat } from 'src/common/constants';
import InfoPanel from '../components/InfoPanel';
import { FormErrors, requiredFields, validateForm } from './FormValidation';
import { useTranslation } from 'react-i18next';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import { StyledTextField } from '../styles';
import ReportInfoPanel from '../Reports/ReportInfoPanel';

const initialForm = {
  name: '',
  period: '',
  remarks: '',
  signature_date: null,
  signature_place: '',
  signature_name: '',
  signature_position: '',
  signature_global_data_confirmation: false,
  signature_use_of_data_confirmation: false,
  signature_other_applicable_methodology_confirmation: false,
};

export default function ReportAbout() {
  const { reportId } = useParams();
  const { data, create: _create, update, delete: _delete } = useReports();
  const importedGoods = useImportedGoods(reportId!);
  const { data: actors } = useActors();
  const report = data.find((report) => report.id === Number(reportId));
  const possibleDeclarantIds = new Set(
    importedGoods.data?.flatMap((good) => [
      good.importer_id,
      good.representative_id,
    ])
  );
  const possibleDeclarants = actors.filter(
    (actor) =>
      possibleDeclarantIds.has(actor.id) || actor.id === report?.declarant_id
  );

  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.report',
  });

  const [form, setForm] = React.useState<
    CbamReportOutSchema | CbamReportInSchema
  >(initialForm);

  const [errors, setErrors] = React.useState<FormErrors>({});

  const [isModified, setIsModified] = React.useState(false);

  const handleFormChange = (key: string, value: any) => {
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
    setIsModified(true);
  };

  useEffect(() => {
    if (report) setForm(report);
  }, [report]);

  const isError = (fieldName: keyof CbamReportInSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof CbamReportInSchema) =>
    isError(fieldName) && (mapError(errors, fieldName, form[fieldName]) || '');

  const handleFormDateChange = (key: string, value: Date | null) => {
    if (!!value) {
      setForm((prev) => ({ ...prev, [key]: format(value, dateFormat) }));
    } else {
      setForm((prev) => ({ ...prev, [key]: null }));
    }
    setIsModified(true);
  };

  const handleSaveClick = () => {
    setIsModified(false);
    update.mutateAsync(form).catch((err: any) => {
      setIsModified(true);
      toast.error('Failed to save');
      const extractedErrors = extractSchemaErrors(err, form);
      setErrors(extractedErrors);
    });
  };

  return (
    <Box>
      <NavigationBlockerModal shouldBlock={isModified} />
      <Box sx={{ mb: '12px' }}>
        <ReportInfoPanel />
      </Box>
      <Typography variant="h3">{t('generalInformation')}</Typography>
      <br />
      <StyledPanel>
        <StyledTextField
          key="name"
          id="name"
          value={form.name}
          label={t('reportName')}
          onChange={(e) => handleFormChange('name', e.target.value)}
          required={requiredFields.includes('name')}
          error={isError('name')}
          helperText={errorText('name')}
        />

        <Box sx={{ mb: '6px' }}>
          <IntuitiveCalendarPeriod
            reportingPeriod={ReportingFreq.Q}
            selectedDate={periodToDate(form.period, ReportingFreq.Q)}
            setSelectedDate={(selDate: Date) =>
              handleFormChange(
                'period',
                timeConverter(selDate, ReportingFreq.Q)
              )
            }
          />
        </Box>

        <StyledTextField
          multiline
          minRows={3}
          value={form.remarks}
          onChange={(e) => handleFormChange('remarks', e.target.value)}
          label={t('remarks')}
        />
        <Box sx={{ mb: '12px' }}>
          <InfoPanel>
            <Typography>{t('infoPanel')}</Typography>
          </InfoPanel>
        </Box>
        {possibleDeclarants.length === 0 && (
          <Box sx={{ mb: '12px' }}>
            <InfoPanel>
              <Typography>{t('infoPanel2')}</Typography>
            </InfoPanel>
          </Box>
        )}
        <FormControl sx={{ width: '100%' }}>
          <InputLabel>{t('declarantLabel')}</InputLabel>
          <Select
            fullWidth
            label={t('declarantLabel')}
            value={form.declarant_id || ''}
            required={requiredFields.includes('declarant_id')}
            disabled={possibleDeclarants.length === 0}
          >
            {possibleDeclarants.map((actor) => (
              <MenuItem
                key={actor.id}
                value={actor.id}
                onClick={() => handleFormChange('declarant_id', actor.id)}
              >
                {actor.name} ({actor.identifier})
              </MenuItem>
            ))}
          </Select>
          {isError('declarant_id') && (
            <FormHelperText>{errorText('declarant_id')}</FormHelperText>
          )}
        </FormControl>
      </StyledPanel>
      <br />
      <Typography variant="h3" sx={{ m: '6px' }}>
        {t('signature')}
      </Typography>
      <br />
      <Box sx={{ mb: '12px' }}>
        <InfoPanel>
          <Typography>{t('signatureInfoPanel')}</Typography>
        </InfoPanel>
      </Box>
      <StyledPanel>
        <DatePicker
          sx={{ width: '100%', mb: '12px' }}
          label={t('signature_date')}
          value={form.signature_date ? new Date(form.signature_date) : null}
          onChange={(date) => handleFormDateChange('signature_date', date)}
          slotProps={{
            textField: {
              onKeyDown: (e) => e.preventDefault(),
              required: requiredFields.includes('signature_date'),
              error: isError('signature_date'),
              helperText: errorText('signature_date'),
            },
          }}
        />
        {['signature_place', 'signature_name', 'signature_position'].map(
          (field) => (
            <StyledTextField
              key={field}
              label={t(`${field}`)}
              value={form[field as keyof CbamReportInSchema]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleFormChange(field, e.currentTarget.value);
              }}
              required={requiredFields.includes(
                field as keyof CbamReportInSchema
              )}
              error={isError(field as keyof CbamReportInSchema)}
              helperText={errorText(field as keyof CbamReportInSchema)}
            />
          )
        )}
      </StyledPanel>
      <br />
      <Typography variant="h3" sx={{ m: '6px' }}>
        {t('consents')}
      </Typography>
      <br />
      <StyledPanel>
        <StyledFlex
          sx={{ flexDirection: 'column', alignItems: 'start', m: '10px' }}
        >
          <FormControl error={isError('signature_global_data_confirmation')}>
            <FormControlLabel
              label={t('signature_global_data_confirmation')}
              control={
                <Checkbox
                  checked={form.signature_global_data_confirmation}
                  onChange={(e) =>
                    handleFormChange(
                      'signature_global_data_confirmation',
                      e.target.checked
                    )
                  }
                  color={
                    isError('signature_global_data_confirmation')
                      ? 'error'
                      : 'default'
                  }
                />
              }
            />
            {isError('signature_global_data_confirmation') && (
              <FormHelperText>
                {errorText('signature_global_data_confirmation')}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl error={isError('signature_use_of_data_confirmation')}>
            <FormControlLabel
              label={t('signature_use_of_data_confirmation')}
              control={
                <Checkbox
                  checked={form.signature_use_of_data_confirmation}
                  onChange={(e) =>
                    handleFormChange(
                      'signature_use_of_data_confirmation',
                      e.target.checked
                    )
                  }
                  color={
                    isError('signature_global_data_confirmation')
                      ? 'error'
                      : 'default'
                  }
                />
              }
            />
            {isError('signature_use_of_data_confirmation') && (
              <FormHelperText>
                {errorText('signature_use_of_data_confirmation')}
              </FormHelperText>
            )}
          </FormControl>

          {/* If you have a third consent checkbox, handle it similarly */}
          {/* <FormControl error={isError('signature_other_applicable_methodology_confirmation')}>
          <FormControlLabel
            label={t('otherMethodologyConfirmation')}
            control={
              <Checkbox
                checked={form.signature_other_applicable_methodology_confirmation}
                onChange={(e) =>
                  handleFormChange(
                    'signature_other_applicable_methodology_confirmation',
                    e.target.checked
                  )
                }
              />
            }
          />
          {isError('signature_other_applicable_methodology_confirmation') && (
            <FormHelperText>{errorText('signature_other_applicable_methodology_confirmation')}</FormHelperText>
          )}
          </FormControl> */}
        </StyledFlex>
      </StyledPanel>
      <br />
      <br />
      <Button
        sx={{ float: 'right' }}
        onClick={handleSaveClick}
        disabled={!isModified || Object.keys(validateForm(form, t)).length > 0}
      >
        {t('saveChanges')}
      </Button>
    </Box>
  );
}
