import { styled as styledMUI } from '@mui/material/styles';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Alert } from '@mui/material';

export const Container = styled.div`
  padding-bottom: 150px;
  min-height: calc(100vh - 208px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 2.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LoginButton = styled.button`
  background-color: #165d5c;
  border: 2px solid #165d5c;
  border-radius: 28px;
  display: flex;
  flex-direction: row;
  transition:
    background-color,
    border 0.3s ease-in-out;
  padding-right: 20px;
  height: 56px;
  width: 137px;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    transition:
      background-color,
      border 0.3s ease-in-out;
    background-color: #276e6d;
    border: 2px solid #276e6d;
  }

  span {
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.03em;
    padding-right: 20px;
  }
`;

export const StyledAlert = styledMUI(Alert)`
margin-top: 1rem;
margin-bottom: 2.75rem;
`;

export const BackButton = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d9dfdd;
  border-radius: 28px;
  padding: 8px 30px 8px 10px;
  margin-left: 0;
  height: 56px;
  margin-right: auto;
  cursor: pointer;
  > span {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #165d5c;
  }
  > img {
    height: 12px;
    width: 7.41px;
    margin-right: 20px;
  }
  &:hover {
    transition: background-color 0.3s ease-in-out;
    background-color: #f9f9f9;
  }
`;

export const Description = styled.p`
  color: #132c1d;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;
`;
