import {
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import {
  StyledFormControl,
  StyledNumberField,
  StyledTextField,
} from '../../../styles';
import {
  useCnGoodCodes,
  useInstallations,
  useProductionProcesses,
  useSupportingDocumentsForProductionProcesses,
} from 'src/Cbam/hooks';

import {
  AggregatedGoodsCategory,
  CbamCnGoodCode,
  CbamProductionProcessOutSchema,
  ProductionProcessGeneralInformation,
} from 'src/Cbam/types';
import { useNavigate } from 'react-router-dom';
import SectorSpecific from './SectorSpecific';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import { useChangeTracker } from '../components/CounterProvider';
import { quantityUnit } from 'src/Cbam/utils';
import { FormErrors, requiredFields, validateForm } from './FormValidation';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import { toast } from 'react-toastify';
import EmissionsSummary from './EmissionsSummary';
import { groupBy, uniqBy } from 'lodash';

interface Props {
  id?: number;
  survey?: boolean;
}

export default function GeneralInformation(props: Props) {
  const { id, survey } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.productionProcess.generalInformation',
  });
  const { globalCounter, isModified, setIsModified } = useChangeTracker();
  const installations = useInstallations();
  const { data: goodCodes } = useCnGoodCodes();
  const navigate = useNavigate();
  const productionProcesses = useProductionProcesses();
  const productionProcess = productionProcesses.data.find(
    (p) => p.id === Number(id)
  );

  const exists = !!productionProcess;

  const { data: documents } = survey
    ? { data: [] }
    : useSupportingDocumentsForProductionProcesses();

  const { t: supportingDocumentTranslation } = useTranslation(undefined, {
    keyPrefix: 'cbam.supportingDocuments',
  });

  const initialForm = {
    name: '',
    installation_id: 0,
    quantity: 0,
    supporting_documents: [],
    remarks: '',

    aggregated_goods_category: null,
    hs_code: null,
    cn_code: null,
  };

  const [form, setForm] =
    React.useState<ProductionProcessGeneralInformation>(initialForm);

  const [errors, setErrors] = React.useState<FormErrors>({});

  const [newId, setNewId] = React.useState<number | undefined>(undefined);

  const hsCodesForCategory = React.useMemo(
    () =>
      groupBy(
        uniqBy(
          goodCodes.map((good) => {
            return {
              category: good.aggregated_goods_category,
              hs_code: good.hs_code,
              hs_code_description: good.hs_code_description,
            };
          }),
          'hs_code'
        ),
        'category'
      ),
    [goodCodes]
  );

  const cnCodesForHsCode = React.useMemo(
    () => groupBy(goodCodes, 'hs_code'),
    [goodCodes]
  );

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  const handleFormChangeDocument = (index: number, value: number) => {
    const newDocuments = form.supporting_documents.slice();
    newDocuments[index] = value;
    handleFormChange('supporting_documents', newDocuments);
  };

  const handleFormAddDocument = () => {
    const newDocuments = form.supporting_documents.slice();
    newDocuments.push(null);
    handleFormChange('supporting_documents', newDocuments);
  };

  const handleFormRemoveDocument = (index: number) => {
    const newDocuments = form.supporting_documents.slice();
    newDocuments.splice(index, 1);
    handleFormChange('supporting_documents', newDocuments);
  };

  const getSingleHsCode = (category: AggregatedGoodsCategory) =>
    hsCodesForCategory[category]?.length === 1
      ? hsCodesForCategory[category][0]
      : null;

  const getSingleCnCode = (hsCode?: string) =>
    hsCode && cnCodesForHsCode[hsCode]?.length === 1
      ? cnCodesForHsCode[hsCode][0]
      : null;

  const handleAggregatedGoodsCategoryChange = (
    category: AggregatedGoodsCategory
  ) => {
    setIsModified(true);
    const hsCode = getSingleHsCode(category);
    const cnCode = getSingleCnCode(hsCode?.hs_code);
    const newForm = {
      ...form,
      aggregated_goods_category: category,
      hs_code: hsCode?.hs_code || null,
      cn_code: cnCode?.cn_code || null,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  const handleHsCodeChange = (hsCode: string) => {
    setIsModified(true);
    const cnCode = getSingleCnCode(hsCode);
    const newForm = {
      ...form,
      hs_code: hsCode,
      cn_code: cnCode?.cn_code || null,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  const handleCnCodeChange = (cnCode: CbamCnGoodCode) => {
    setIsModified(true);
    const newForm = {
      ...form,
      cn_code: cnCode.cn_code,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  const usedDocuments = form.supporting_documents.map(
    (doc) => documents.find((d) => d.id === doc) || null
  );

  React.useEffect(() => {
    if (productionProcess) setForm(productionProcess);
  }, [productionProcess]);

  const save = !!id
    ? productionProcesses.update.mutateAsync
    : productionProcesses.create.mutateAsync;

  const handleSaveClick = () =>
    save(form)
      .then((res: { data: CbamProductionProcessOutSchema }) => {
        setIsModified(false);
        if (!id) setNewId(res.data.id);
      })
      .catch((err: any) => {
        toast.error('Failed to save');
        const extractedErrors = extractSchemaErrors(err, form);
        setErrors(extractedErrors);
      });

  // Redirect to the new production process after creation must be done
  // after the counter is reset and navigation blocker modal is disabled
  React.useEffect(() => {
    if (newId !== undefined && globalCounter === 0)
      navigate(`/cbam/production-process/${newId}`);
  }, [newId, globalCounter]);

  const isError = (fieldName: keyof ProductionProcessGeneralInformation) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof ProductionProcessGeneralInformation) =>
    isError(fieldName) && mapError(errors, fieldName, form[fieldName] || '');

  const unit = quantityUnit(form);

  return (
    <>
      <Typography variant="h3">General Information</Typography>
      <br />
      <StyledPanel>
        <StyledTextField
          label={t('form.name')}
          value={form.name}
          onChange={(e) => handleFormChange('name', e.target.value)}
          required={requiredFields.includes('name')}
          error={isError('name')}
          helperText={errorText('name')}
        />
        {!survey && (
          <StyledFormControl
            required={requiredFields.includes('installation_id')}
            error={isError('installation_id')}
          >
            <InputLabel id="installation-id-label">
              {t('form.installation_id')}
            </InputLabel>
            <Select
              labelId="installation-id-label"
              label={t('form.installation_id')}
              value={String(form.installation_id)}
            >
              {installations.data.map((i) => (
                <MenuItem
                  key={i.id}
                  value={i.id}
                  onClick={() => handleFormChange('installation_id', i.id)}
                >
                  {i.name}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        )}

        <StyledFormControl
          disabled={exists}
          required={requiredFields.includes('aggregated_goods_category')}
        >
          <InputLabel id="aggregated-goods-category">
            {t('form.aggregated_goods_category')}
          </InputLabel>
          <Select
            labelId="aggregated-goods-category"
            value={form.aggregated_goods_category || ''}
            label={t('form.aggregated_goods_category')}
          >
            {Object.values(AggregatedGoodsCategory).map((category) => (
              <MenuItem
                key={category}
                value={category}
                onClick={() => handleAggregatedGoodsCategoryChange(category)}
              >
                {category}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <StyledFormControl
          disabled={exists || !form.aggregated_goods_category}
          required={requiredFields.includes('hs_code')}
        >
          <InputLabel id="hs-code-label">{t('form.hs_code')}</InputLabel>
          <Select
            labelId="hs-code-label"
            value={form.hs_code || ''}
            label={t('form.hs_code')}
          >
            {!!form.aggregated_goods_category &&
              hsCodesForCategory[form.aggregated_goods_category]?.map(
                (good) => (
                  <MenuItem
                    key={good.hs_code}
                    value={good.hs_code}
                    onClick={() => handleHsCodeChange(good.hs_code)}
                  >
                    {good.hs_code} ({good.hs_code_description})
                  </MenuItem>
                )
              )}
          </Select>
        </StyledFormControl>

        <StyledFormControl
          disabled={exists || !form.hs_code}
          required={requiredFields.includes('cn_code')}
        >
          <InputLabel id="cn-code-label">{t('form.cn_code')}</InputLabel>
          <Select
            labelId="cn-code-label"
            value={form.cn_code || ''}
            label={t('form.cn_code')}
          >
            {!!form.hs_code &&
              cnCodesForHsCode[form.hs_code]?.map((good) => (
                <MenuItem
                  key={good.cn_code}
                  value={good.cn_code}
                  onClick={() => handleCnCodeChange(good)}
                >
                  {good.cn_code} ({good.cn_code_description})
                </MenuItem>
              ))}
          </Select>
        </StyledFormControl>

        <StyledNumberField
          value={form.quantity}
          onChange={(value) => handleFormChange('quantity', value)}
          label={`${t('form.quantity')} [${unit}]`}
          required={requiredFields.includes('quantity')}
          error={isError('quantity')}
          helperText={errorText('quantity')}
        />
        <StyledTextField
          label="Reporting period (optional, if different than one year)"
          disabled
        />

        {!survey &&
          usedDocuments.map((doc, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
              <StyledFormControl error={isError('supporting_documents')}>
                <InputLabel id="supporting_documents">
                  Supporting document
                </InputLabel>
                <Select
                  labelId="supporting_documents"
                  label="Supporting Document"
                  value={doc?.id || ''}
                  onChange={(e) =>
                    handleFormChangeDocument(index, Number(e.target.value))
                  }
                >
                  {documents?.map((document) => (
                    <MenuItem key={document.id} value={document.id}>
                      {document.reference_number} (
                      {supportingDocumentTranslation(`types.${document.type}`)})
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
              <IconButton onClick={() => handleFormRemoveDocument(index)}>
                <Delete />
              </IconButton>
            </Box>
          ))}

        {!survey && isError('supporting_documents') && (
          <Typography color="error">
            {errorText('supporting_documents')}
          </Typography>
        )}

        {!survey && (
          <Button sx={{ mb: '12px' }} onClick={handleFormAddDocument}>
            Add Document
          </Button>
        )}

        <StyledTextField
          label="Remarks"
          value={form.remarks}
          onChange={(e) => handleFormChange('remarks', e.target.value)}
          multiline
          rows={3}
        />
        {(!exists || isModified) && (
          <Box sx={{ width: '100%', textAlign: 'right', mt: '12px' }}>
            <Button
              color="primary"
              onClick={handleSaveClick}
              sx={{ minWidth: '200px' }}
              disabled={Object.keys(validateForm(form, t)).length > 0}
            >
              {id ? 'Save' : 'Create'}
            </Button>
          </Box>
        )}
      </StyledPanel>
      {exists && (
        <>
          <br />
          <EmissionsSummary
            id={Number(id)}
            aggregatedGoodsCategory={form.aggregated_goods_category}
          />
          <SectorSpecific
            id={Number(id)}
            aggregatedGoodsCategory={form.aggregated_goods_category}
          />
        </>
      )}
    </>
  );
}
