import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';

import esgTheme from './theme';
import ESGForm from 'src/views/Pages/ESG/forms';
import CreateESGReport from 'src/views/Pages/ESG/create';

import ReportsIndex from 'src/views/Pages/ESG/ReportsIndex';
import { EsgReportRoute } from './routes';
import ReportPage from 'src/views/Pages/ESG/Report';
import Indicators from 'src/views/Pages/ESG/Report/indicators';
import SummaryPage from 'src/views/Pages/ESG/Report/summary';
import DownloadPage from 'src/views/Pages/ESG/Report/download';

import Dma from './Dma';
import DmaStakeholders from './Dma/Stakeholders';
import DmaStakeholdersAnalytics from './Dma/Stakeholders/StakeholdersAnalytics';
import DmaSelectingStakeholders from './Dma/Stakeholders/SelectingStakeholders';
import Step2TopicEvaluation from './Dma/Step2TopicEvaluation';
import Step3TopicEvaluation from './Dma/Step3TopicEvaluation';
import Step5TopicEvaluation from './Dma/Step5TopicEvaluation';
import Step6TopicEvaluation from './Dma/Step6TopicEvaluation';
import DmaMatrixView from './Dma/components/DmaMatrix';
import DmaStakeholdersAnalyticsDashboard from './Dma/Stakeholders/StakeholdersAnalytics/Dashboard';
import DmaStakeholdersAnalyticsTable from './Dma/Stakeholders/StakeholdersAnalytics/Table';
import DmaStakeholdersAnalyticsSubtopic from './Dma/Stakeholders/StakeholdersAnalytics/Subtopic';
import DmaStakeholdersAnalyticsIndividual from './Dma/Stakeholders/StakeholdersAnalytics/Individual';
import { RouteLayout } from 'src/views/components/Layout';
import EsgOrganizationSidebar from './components/OrganizationSidebar';
import TopBar from '../components/TopBar';
import {
  PermissionRoute,
  ProductHomeRoute,
  ProductOrganizationRoute,
} from '../common/routes';
import { ProductPlatform } from '../utils/api.interfaces';
import EsgHome from './Home';
import StakeholderSurveyIntro from 'src/views/Pages/ESG/StakeholderSurvey/Intro';
import StakeholderSurveyQuestionnaire from 'src/views/Pages/ESG/StakeholderSurvey/Questionnaire';
import StakeholderSurveySummary from 'src/views/Pages/ESG/StakeholderSurvey/Summary';
import StakeholderSurveyEnd from 'src/views/Pages/ESG/StakeholderSurvey/End';
import { useBasicSurveyMeta } from 'src/views/Pages/ESG/StakeholderSurvey/hooks';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import EsgReportHome from './ReportHome';
import EsgAdministratonPanel from './AdministrationPanel';

const EsgHomeRoute = () => (
  <ProductHomeRoute>
    <EsgHome />
  </ProductHomeRoute>
);

const EsgOrganizationRoute = () => (
  <ProductOrganizationRoute product={ProductPlatform.Esg} />
);

const EsgLayoutRoute = () => (
  <RouteLayout sidebar={<EsgOrganizationSidebar />} topbar={<TopBar />} />
);

const EsgDmaRoute = () => (
  <Routes>
    <Route
      index
      element={
        <ReportPage>
          <Dma />
        </ReportPage>
      }
    />
    <Route element={<DmaStakeholders />}>
      <Route element={<DmaStakeholdersAnalytics />}>
        <Route
          path="stakeholders/analytics/dashboard"
          element={<DmaStakeholdersAnalyticsDashboard />}
        />
        <Route
          path="stakeholders/analytics/table"
          element={<DmaStakeholdersAnalyticsTable />}
        />
        <Route
          path="stakeholders/analytics/subtopic"
          element={<DmaStakeholdersAnalyticsSubtopic />}
        />
        <Route
          path="stakeholders/analytics/individual"
          element={<DmaStakeholdersAnalyticsIndividual />}
        />
      </Route>

      <Route
        path="stakeholders/selecting-stakeholders"
        element={<DmaSelectingStakeholders />}
      />
    </Route>
    <Route path="step-2/topic-evaluation" element={<Step2TopicEvaluation />} />
    <Route path="step-3/topic-evaluation" element={<Step3TopicEvaluation />} />
    <Route path="step-5/topic-evaluation" element={<Step5TopicEvaluation />} />
    <Route path="step-6/topic-evaluation" element={<Step6TopicEvaluation />} />
    <Route path="matrix" element={<DmaMatrixView />} />
  </Routes>
);

export default function Esg() {
  return (
    <ThemeProvider theme={esgTheme}>
      <Routes>
        <Route index element={<EsgHomeRoute />} />
        <Route element={<EsgOrganizationRoute />}>
          <Route element={<EsgLayoutRoute />}>
            <Route
              path="administration-panel"
              element={<EsgAdministratonPanel />}
            />
            <Route
              path="dashboard"
              element={<Navigate to="/esg/reports" replace />}
            />
            <Route path="reports" element={<ReportsIndex />} />
            <Route element={<PermissionRoute writeData />}>
              <Route path="reports/new" element={<CreateESGReport />} />
            </Route>
            <Route element={<EsgReportRoute />}>
              <Route path="reports/:reportId" element={<EsgReportRoute />}>
                <Route index element={<EsgReportHome />} />
                <Route element={<ReportPage />}>
                  <Route path="indicators" element={<Indicators />} />
                  <Route path="summary" element={<SummaryPage />} />
                  <Route path="download" element={<DownloadPage />} />
                </Route>
                <Route element={<PermissionRoute writeData />}>
                  <Route path="questionnaires/:formKey" element={<ESGForm />} />
                  <Route path="dma/*" element={<EsgDmaRoute />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

// TODO: move to UnauthorizredProvider
// For unauthorized access
export const EsgStakeholderSurveyRoute = () => {
  const { token } = useParams();
  const { data: meta, isError } = useBasicSurveyMeta(token);

  if (isError) return <SomethingWentWrong />;
  if (!meta) return <PageLoading />;

  return (
    <Routes>
      <Route index element={<StakeholderSurveyIntro />} />
      <Route
        path="questionnaire/:idx?"
        element={<StakeholderSurveyQuestionnaire />}
      />
      <Route path="summary" element={<StakeholderSurveySummary />} />
      <Route path="end" element={<StakeholderSurveyEnd />} />
    </Routes>
  );
};
