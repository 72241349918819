import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  ScriptableContext,
} from 'chart.js';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { useAppSelector } from 'src/redux-file/hooks';
import { StyledDiagramContainer, StyledLabel } from './index.styles';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface MonteCarloDiagramProps {
  productId: number;
}

interface CustomScriptableContext extends ScriptableContext<'line'> {
  xStarted?: boolean;
  yStarted?: boolean;
}

const MonteCarloDiagram: React.FC<MonteCarloDiagramProps> = ({ productId }) => {
  const { selectedAreaOfImpact } = useAppSelector((state) => state.lcaDiagram);
  const { t } = useTranslation();

  const { isLoading, data, error } = useQuery(
    [
      'lca-diagram',
      'calculations',
      'monte-carlo-diagram',
      { productId, selectedAreaOfImpact },
    ],
    () =>
      client.get(`/web/lca/monte_carlo_chart/${productId}`, {
        params: { area_id: selectedAreaOfImpact?.id },
      }),
    {
      enabled: !!selectedAreaOfImpact,
    }
  );

  if (isLoading)
    return (
      <div
        style={{
          height: 215,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        loading
      </div>
    );
  if (!isLoading) {
    const highestAverages = data?.data.data_plus_std;

    const lowestAverages = data?.data.data_minus_std;

    const totalDuration = 10000;

    const delayBetweenPoints = data?.data.length
      ? totalDuration / data.data.length
      : 0;

    const previousY = (ctx: any) =>
      ctx.index === 0
        ? ctx.chart.scales.y.getPixelForValue(100)
        : ctx.chart
            .getDatasetMeta(ctx.datasetIndex)
            .data[ctx.index - 1]?.getProps(['y'], true).y;

    const renderData: ChartData<'line'> = {
      labels: Array.from({ length: data?.data.data.length }, (_, i) => i + 1),
      datasets: [
        {
          label: t('lca.monteCarloSimulation'),
          data: data?.data.data,
          borderColor: '#CC8720',
          tension: 0.1,
          pointRadius: 2,
        },
        {
          label: t('lca.standardDeviationTop'),
          data: highestAverages,
          borderColor: '#B8DBE5',
          tension: 0.1,
          fill: '+1',
          pointRadius: 0,
        },
        {
          label: t('lca.standardDeviationBottom'),
          data: lowestAverages,
          borderColor: '#B8DBE5',
          tension: 0.1,
          fill: '-1',
          backgroundColor: 'rgba(184, 219, 229, 0.4)',
          pointRadius: 0,
        },
      ],
    };

    const options: ChartOptions<'line'> = {
      animations: {
        x: {
          type: 'number',
          easing: 'linear',
          duration: delayBetweenPoints,
          from: NaN,
          delay(ctx: ScriptableContext<'line'>) {
            const customCtx = ctx as CustomScriptableContext;

            const { index } = ctx as any;
            if (ctx.type !== 'data' || customCtx.xStarted) {
              return 0;
            }
            customCtx.xStarted = true;
            return index * delayBetweenPoints;
          },
        },
        y: {
          type: 'number',
          easing: 'linear',
          duration: delayBetweenPoints,
          from: previousY,
          delay(ctx: ScriptableContext<'line'>) {
            const customCtx = ctx as CustomScriptableContext;
            const { index } = ctx as any;

            if (ctx.type !== 'data' || customCtx.yStarted) {
              return 0;
            }
            customCtx.yStarted = true;
            return index * delayBetweenPoints;
          },
        },
      },
    };
    return (
      <StyledDiagramContainer>
        <StyledLabel>
          {t('lca.monteCarloSimulation')} [{selectedAreaOfImpact?.unit_name}]
        </StyledLabel>
        <Line data={renderData} options={options} />
      </StyledDiagramContainer>
    );
  }
  return <div>Error: {error}</div>;
};

export default MonteCarloDiagram;
