/* eslint-disable no-unused-vars */

import { createTheme } from '@mui/material';
import '@mui/material/styles/createTypography';
import theme from '../theme';
import { deepmerge } from '@mui/utils';
import themeColors from 'src/theme/colors';

const superadminTheme = createTheme(
  deepmerge(theme, {
    palette: {
      primary: {
        main: themeColors.esgMain,
      },
      secondary: {
        main: themeColors.pureWhite,
        contrastText: themeColors.esgMain,
      },
      text: {
        primary: themeColors.esgMain,
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#38414F',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: '0.36px',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              borderRadius: '8px',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: '192px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '15px',
            minHeight: '48px',
            textTransform: 'none',
            padding: '10px 30px',
          },
          primary: {
            backgroundColor: themeColors.esgMain,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '15px',
            textTransform: 'none',
            padding: '10px 30px',
            color: themeColors.pureWhite,
            '&:hover': {
              backgroundColor: themeColors.esgMainLight,
            },
            '&:pressed': {
              backgroundColor: themeColors.esgMainLighter,
            },
          },
          secondary: {
            border: `1px solid ${themeColors.disabledGraphics}`,
            backgroundColor: themeColors.pureWhite,
            alignSelf: 'center',
            '&:hover': {
              backgroundColor: themeColors.hover,
            },
            '&:pressed': {
              backgroundColor: themeColors.pressed,
            },
            '& .MuiButton-endIcon': {
              color: themeColors.esgMain,
              fontSize: '18px',
              marginLeft: '3px',
            },
          },
          sizeSmall: {
            minHeight: '32px',
            minWidth: '100px',
            padding: '5px 20px',
            fontSize: '13px',
            lineHeight: '18px',
            height: '32px',
          },
          sizeMedium: {
            height: '40px',
            fontSize: '15px',
          },
          sizeLarge: {
            height: '48px',
            fontSize: '16px',
          },
          containedSecondary: {
            border: '1px solid #D9D9D9',
          },
          textSecondary: {
            color: themeColors.esgMain,
            border: `1px solid ${themeColors.strokeInputs}`,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: '16px',
            border: `1px solid ${themeColors.strokeInputs}`,
            minWidth: '192px',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
            textTransform: 'none',
            letterSpacing: '0.32px',
            color: themeColors.esgMain,
            '&.Mui-selected, &.Mui-selected:hover': {
              color: themeColors.pureWhite,
              backgroundColor: themeColors.esgMain,
              cursor: 'default',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '& .MuiSelect-outlined': {
              backgroundColor: themeColors.backgroundInput,
              borderRadius: '8px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px',
              borderColor: themeColors.backgroundInput,
            },
          },
        },
      },
    },
  })
);

export default superadminTheme;
