import { Navigate, Route, Routes } from 'react-router-dom';
import LCAPageIndexProducts from './Products';

import React from 'react';
import Steps from './Products/steps';
import LcaCreateProduct from './Products/Create';
import lcaTheme from './theme';
import {
  PermissionRoute,
  ProductHomeRoute,
  ProductOrganizationRoute,
} from '../common/routes';
import { ProductPlatform } from '../utils/api.interfaces';
import { RouteLayout } from '../views/components/Layout';
import TopBar from '../components/TopBar';
import LcaHome from './Home';
import { ThemeProvider } from '@mui/material';

const LcaHomeRoute = () => {
  return (
    <ProductHomeRoute>
      <LcaHome />
    </ProductHomeRoute>
  );
};

const LcaOrganizationRoute = () => {
  return <ProductOrganizationRoute product={ProductPlatform.Lca} />;
};

const LcaLayoutRoute = () => {
  return <RouteLayout topbar={<TopBar />} />;
};

export default function Lca() {
  return (
    <ThemeProvider theme={lcaTheme}>
      <Routes>
        <Route index element={<LcaHomeRoute />} />
        <Route element={<LcaOrganizationRoute />}>
          <Route element={<LcaLayoutRoute />}>
            <Route
              path="dashboard"
              element={<Navigate to="/lca/products" replace />}
            />
            <Route path="products">
              <Route index element={<LCAPageIndexProducts />} />
              <Route path=":id" element={<Steps />} />
              <Route element={<PermissionRoute writeData />}>
                <Route path="create" element={<LcaCreateProduct />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}
