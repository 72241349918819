import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFileUpload from 'react-use-file-upload';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import cloud from '../../images/cloud.png';
import {
  CloseIcon,
  DownloadButton,
  ErrorItem,
  ErrorBox,
  FileWrapper,
  Icon,
  StyledDialog,
  StyledDialogContent,
  UploadedTitle,
} from './index.styles';
import uploadExcel from '../../images/upload_excel.png';
import { getExcelFileAPI, postExcelFileAPI } from '../../utils';
import IconSvg from '../../views/components/IconSvg';
import { useFileUploadHook } from 'react-use-file-upload/dist/lib/types';
import { Box, Button } from '@mui/material';
import ConfirmationModal from '../ConfirmationModal';
import _ from 'lodash';

export interface ErrorProps {
  type: string;
  msg: string;
  loc: [string, number, string, string];
}

type ExcelFileSelectorProps = {
  name: string;
  slug: string;
  setUploadError: React.Dispatch<React.SetStateAction<boolean>>;
  fileUploadForm: useFileUploadHook;
};

function ExcelFileSelector(props: ExcelFileSelectorProps) {
  const { handleDragDropEvent, setFiles } = props.fileUploadForm;
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div
      onDragEnter={(e: any) => handleDragDropEvent(e)}
      onDragOver={(e: any) => handleDragDropEvent(e)}
      onDrop={(e: any) => {
        props.setUploadError(false);
        if (
          e?.dataTransfer.files[0].type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          handleDragDropEvent(e);
          setFiles(e);
        } else {
          props.setUploadError(true);
        }
      }}
    >
      <div className="content-wrapper">
        <img src={cloud} alt="cloud excel upload" />
        <Button onClick={() => inputRef.current?.click()}>
          <span>{t('popup.excel.search')}</span>
        </Button>
        <div>{t('popup.excel.drop')}</div>
      </div>

      <Box flexDirection="column">
        <DownloadButton
          type="button"
          onClick={() =>
            getExcelFileAPI(props.slug, 'GoogleSheets').then((blob) => {
              const file = new Blob([blob], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(file);
              link.download = `${props.name}.xlsx`;
              link.click();
            })
          }
        >
          {t('popup.excel.get-GoogleSheets')}
        </DownloadButton>
        <DownloadButton
          type="button"
          onClick={() =>
            getExcelFileAPI(props.slug, 'LibreOffice').then((blob) => {
              const file = new Blob([blob], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(file);
              link.download = `${props.name}.xlsx`;
              link.click();
            })
          }
        >
          {t('popup.excel.get-LibreOffice')}
        </DownloadButton>
        <DownloadButton
          type="button"
          onClick={() =>
            getExcelFileAPI(props.slug, 'WindowsExcel').then((blob) => {
              const file = new Blob([blob], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(file);
              link.download = `${props.name}.xlsx`;
              link.click();
            })
          }
        >
          {t('popup.excel.get-WindowsExcel')}
        </DownloadButton>
      </Box>
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={(e: any) => {
          setFiles(e);
        }}
        multiple={false}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
    </div>
  );
}

type ExcelErrorListProps = {
  error: ErrorProps[];
};

function ExcelErrorList({ error }: ExcelErrorListProps) {
  const { t } = useTranslation();

  const popupErrors = [
    'no_data',
    'required_column_missing',
    'not_excel_file_uploaded',
    'outdated_template',
  ];
  const isPopupError =
    error.length > 0 && popupErrors.indexOf(error[0].type) >= 0;

  return (
    <Box mt="16px">
      {error.length > 0 && isPopupError
        ? error.map((el, i) => (
            <ErrorItem key={i}>{t(`popup.excel.${el.type}`)}</ErrorItem>
          ))
        : null}

      {error.length > 0 && !isPopupError ? (
        <div>
          <ErrorItem>{t(`popup.excel.missing_data`)}</ErrorItem>
          <ErrorItem>
            <ul>
              {error.map((el, i) => (
                <li style={{ textAlign: 'left' }} key={i}>
                  {el.loc.length >= 2
                    ? `${t('popup.excel.row')} ${el.loc[1] + 1}: `
                    : ''}
                  {t(`popup.excel.${el.type}`)}
                </li>
              ))}
            </ul>
          </ErrorItem>
        </div>
      ) : null}
    </Box>
  );
}

const deleteMark = Symbol('deleteMark');
/**
 * Empties all collections with deleteMark in the object (including nested objects)
 */
const emptyCollectionsWithMarkInObj = (obj: any) => {
  let haveFound = false;
  const traverse = (obj: any) => {
    if (Array.isArray(obj) && obj.includes(deleteMark)) {
      haveFound = true;
      return [];
    }
    if (typeof obj === 'object') {
      for (const [key, value] of Object.entries(obj)) {
        obj[key] = traverse(value);
      }
    }
    return obj;
  };
  const result = traverse(obj);
  if (!haveFound)
    throw new Error('emptyCollectionsWithMarkInObj: deleteMark not found');
  return result;
};

type PopupState = 'select-file' | 'upload' | 'success';

export default function UploadExcelPopup({
  opened,
  setOpened,
  slug,
  setError,
  fullData,
  combineData,
  sendData,
  refresh,
  error,
  name,
}: {
  opened: boolean;
  error: ErrorProps[];
  setOpened: Function;
  slug: string;
  setError: Function;
  fullData: any;
  combineData: (existingData: any, newData: any) => any;
  sendData: Function;
  refresh: Function;
  name: string;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [popupState, setPopupState] = useState<PopupState>('select-file');
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [confirmReplace, setConfirmReplace] = useState<boolean>(false);

  const fileUploadForm = useFileUpload();
  const { fileNames, files, clearAllFiles } = fileUploadForm;

  const clearForm = () => {
    setPopupState('select-file');
    setUploadError(false);
    setConfirmReplace(false);
    setError([]);
    clearAllFiles();
  };

  useEffect(() => {
    if (!opened || files.length === 0) clearForm();
    else if (files.length > 0) setPopupState('upload');
  }, [opened, files.length]);

  const uploadSelectedFiles = async (replace = false) => {
    const formData = new FormData();
    formData.append('file', files[0]);

    try {
      const { data } = await postExcelFileAPI(slug, formData);
      let existingData = _.cloneDeep(fullData);
      if (replace) {
        // DIRTY hack to clear the existing data
        // this is kind of neccessary because there are >20 custom functions for this..
        // and there is no point in refactoring them for now
        const dataWithMark = combineData(existingData, [deleteMark]);
        existingData = emptyCollectionsWithMarkInObj(dataWithMark);
      }
      const newCombinedData = combineData(existingData, data);
      await sendData(newCombinedData);

      setPopupState('success');
      setTimeout(() => {
        refresh();
        setOpened(false);
      }, 1000);
    } catch (err: any) {
      setError(JSON.parse(err.response.data.detail));
    }
  };

  return (
    <StyledDialog
      fullScreen={fullScreen}
      open={opened}
      aria-labelledby="responsive-dialog-title"
    >
      <div className="wrapper">
        <span>{t('popup.excel.title')}</span>
        <CloseIcon
          onClick={() => {
            setOpened(false);
          }}
        >
          <span>x</span>
        </CloseIcon>
      </div>
      <StyledDialogContent>
        {popupState === 'select-file' && (
          <ExcelFileSelector
            name={name}
            slug={slug}
            fileUploadForm={fileUploadForm}
            setUploadError={setUploadError}
          />
        )}
        {popupState === 'success' && (
          <div>
            <IconSvg name="good-icon" />
            <UploadedTitle>{t('popup.excel.success')}</UploadedTitle>
          </div>
        )}
        {popupState === 'upload' && (
          <Box flexDirection="column">
            <FileWrapper className="file-wrapper">
              <Icon src={uploadExcel} />
              <span>{fileNames[0]}</span>
              <span onClick={clearForm}>{t('popup.excel.remove')}</span>
            </FileWrapper>

            <Box flexDirection="row" justifyContent="space-evenly" gap="40px">
              <Button
                sx={{ flex: 1 }}
                size="large"
                onClick={() => uploadSelectedFiles(false)}
              >
                <span>{t('popup.excel.uploadAndInsert')}</span>
              </Button>
              <Button
                sx={{ flex: 1 }}
                size="large"
                onClick={() => setConfirmReplace(true)}
              >
                <span>{t('popup.excel.uploadAndReplace')}</span>
              </Button>
            </Box>

            {uploadError && (
              <ErrorBox>
                <ErrorItem>{t('popup.excel.error-upload')}</ErrorItem>
              </ErrorBox>
            )}
            <ExcelErrorList error={error} />
          </Box>
        )}
      </StyledDialogContent>
      <ConfirmationModal
        open={confirmReplace}
        onTrue={() => {
          setConfirmReplace(false);
          uploadSelectedFiles(true);
        }}
        onFalse={() => setConfirmReplace(false)}
        onClose={() => setConfirmReplace(false)}
        textKey="popup.excel.uploadAndReplaceWarning"
      />
    </StyledDialog>
  );
}
