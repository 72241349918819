import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import theme from '../theme';
import themeColors from '../theme/colors';

const lcaTheme = createTheme(
  deepmerge(theme, {
    palette: {
      primary: {
        main: themeColors.lcaDark,
      },
      secondary: {
        main: themeColors.lcaDarkGrey,
        contrastText: themeColors.esgDark,
      },
      main: themeColors.lcaMain,
      mainDark: themeColors.lcaMainDark,
      mainLight: themeColors.lcaMainLight,
      mainLighter: themeColors.lcaMainLighter,
      text: {
        primary: themeColors.lcaDark,
        secondary: themeColors.lcaDarkGrey,
      },
      footerBackground: themeColors.lcaVeryDark,
      footerText: themeColors.pureWhite,
    },

    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: '0.36px',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              borderRadius: '8px',
            },
            '&.OrganizationUnitSearchTextField': {
              '& .MuiInputBase-root': {
                borderRadius: '32px',
              },
              '& input::placeholder': {
                color: themeColors.placeholder,
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontStyle: 'italic',
                fontWeight: 400,
                lineHeight: '18px',
                letterSpacing: '0.32px',
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 700,
            borderRadius: 24,
            padding: '0 24px',
            textTransform: 'none',
          },
          textSecondary: {
            color: themeColors.esgDark,
            border: `1px solid ${themeColors.strokeInputs}`,
          },

          primary: {
            background: `var(--brand-color-main, ${themeColors.lcaMain})`,
            color: themeColors.pureWhite,
            alignItems: 'center',
            textDecoration: 'none',
            fontWeight: 700,
            lineHeight: '15px',
            border: `2px solid var(--brand-color-main, ${themeColors.lcaMain})`,
            boxShadow: `0 3px 0px ${themeColors.lcaMainDark}`,
            '&:hover': {
              backgroundColor: themeColors.lcaMainLight,
            },
            '&:active': {
              backgroundColor: themeColors.lcaMainLight,
              boxShadow: 'none',
              transform: 'translateY(4px)',
            },
          },
          secondary: {
            border: `2px solid var(--brand-color-main, ${themeColors.lcaMain})`,
            background: `var(--White, ${themeColors.pureWhite})`,
            boxShadow: `0 3px 0px ${themeColors.lcaMainDark}`,
            color: `var(--brand-color-main, ${themeColors.lcaMain})`,
            textAlign: 'center',
            lineHeight: '18px',
            '&:hover': {
              backgroundColor: themeColors.hover,
            },
            '&:active': {
              backgroundColor: themeColors.pressed,
              boxShadow: 'none',
              transform: 'translateY(4px)',
            },
            '&:disabled': {
              color: themeColors.grayInactive,
              backgroundColor: themeColors.pureWhite,
              border: `2px solid var(--brand-color-main, ${themeColors.grayInactive})`,
              boxShadow: `0 3px 0px ${themeColors.strokePressed}`,
            },
          },
          sizeSmall: {
            height: '40px',
            fontSize: '15px',
          },
          sizeMedium: {
            height: '48px',
            fontSize: '16px',
          },
          sizeLarge: {
            height: '59px',
            fontSize: '18px',
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: '32px',
            border: `2px solid ${themeColors.lcaMainDark}`,
            minWidth: '165px',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
            textTransform: 'none',
            letterSpacing: '0.32px',
            color: themeColors.lcaMainDark,
            height: '56px',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            transform: 'translateY(-2px)',
            boxShadow: `0 2px 0px ${themeColors.error}`,

            '&.Mui-selected, &.Mui-selected:hover': {
              color: themeColors.pureWhite,
              backgroundColor: themeColors.lcaMainDark,
              cursor: 'default',
              transform: 'none',
              boxShadow: 'none',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px',
            },
          },
        },
      },
    },
  })
);

export default lcaTheme;
