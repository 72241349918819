import React from 'react';

import Modals from '../Management/Modals';
import { ModalProvider } from '../Management/Modals/Provider';
import { getCradlesAndGraves } from '../helpers';
import { StyledColumns } from './index.styles';
import {
  LCADiagram as Props,
  Stage as StageType,
  boundaries,
} from './index.types';

import Column from './Column';
import {
  StyledCalculation,
  StyledColumn,
  StyledColumnTitle,
} from './Column/index.styles';
import Gate from './Gate';
import RegisterArrows from './RegisterArrows';

import { useProductImpact } from 'src/Lca/hooks';
import { useAppSelector } from 'src/redux-file/hooks';
import EcoinventEmission from 'src/Lca/components/EcoinventEmission';

export default function LCADiagram(props: Props) {
  const { boundary, productId, data: _data } = props;

  const data = _data.filter((stage) =>
    boundaries[boundary].includes(stage.key)
  );
  const { selectedAreaOfImpact } = useAppSelector((state) => state.lcaDiagram);
  const { data: impact_data } = useProductImpact(
    productId,
    selectedAreaOfImpact?.id
  );

  const { cradles, graves } = getCradlesAndGraves(data);

  return (
    <>
      <ModalProvider>
        <div style={{ width: '100%', maxWidth: 1700, minWidth: 500 }}>
          <StyledColumns>
            {cradles.map((stage: StageType, idx: number) => (
              <Column
                key={stage.key}
                header={idx === 0 ? stage.group.toUpperCase() : ''}
                columns={data.length}
                stage={stage}
                productId={productId}
              />
            ))}

            {!!graves.length && <Gate />}

            {graves.map((stage: StageType, idx: number) => (
              <Column
                key={stage.key}
                header={
                  idx === graves.length - 1 ? stage.group.toUpperCase() : ''
                }
                columns={data.length}
                stage={stage}
                productId={productId}
              />
            ))}
          </StyledColumns>
          <RegisterArrows data={data} />
        </div>
        <Modals />
      </ModalProvider>
      <StyledColumn style={{ marginTop: '165px', width: '100%' }}>
        <StyledColumnTitle
          style={{ justifyContent: 'center', display: 'flex', margin: 0 }}
        >
          summary
        </StyledColumnTitle>
        <StyledCalculation
          style={{ justifyContent: 'center', margin: 0, paddingTop: 0 }}
        >
          <div style={{ fontSize: 32, padding: 15, justifyContent: 'center' }}>
            <EcoinventEmission value={impact_data?.total_emission} />
          </div>
          {impact_data?.area?.unit_name || ''}
        </StyledCalculation>
      </StyledColumn>
    </>
  );
}
