import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { ReportingFreq } from 'src/redux-file/interfaces';

/* eslint-disable no-unused-vars */

export const CalendarWrapper = styled(
  ({
    period,
    ...props
  }: {
    period: ReportingFreq | undefined;
  } & HTMLAttributes<HTMLDivElement>) => <div {...props} />
)`
  && .calendar {
    width: 100%;
    border-radius: 15px 15px 0 0;
  }
  && .react-datepicker__month-container,
  && .react-datepicker__year-wrapper,
  && react-datepicker__quarter-wrapper {
    float: none;
    width: 100%;
    max-width: 100%;
  }
  && .react-datepicker__header {
    background: transparent;
    padding: 15px 0;
    font-size: 20px;
  }
  && .react-datepicker__navigation {
    top: 10px;
  }
  && .react-datepicker__month,
  && .react-datepicker__year {
    margin: 0;
  }
  && .react-datepicker__month .react-datepicker__month-text,
  && .react-datepicker__month .react-datepicker__quarter-text,
  && .react-datepicker__year .react-datepicker__year-text {
    width: ${({ period }) => (period !== 'Q' ? 33.3 : 25)}%;
    margin: 0;
    padding: 16px;
    font-size: 18px;
    border-radius: 0;
  }
  && .react-datepicker__day--keyboard-selected,
  && .react-datepicker__month-text--keyboard-selected,
  && .react-datepicker__quarter-text--keyboard-selected,
  && .react-datepicker__year-text--keyboard-selected {
    background-color: #165d5c;
    color: white;
  }
`;

export default { CalendarWrapper };
