import React from 'react';
import LcaLayout from 'src/Lca/components/layout';
import Breadcrumbs from 'src/Lca/components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import {
  ProductsItemsCreated,
  ProductsItemsEmpty,
} from 'src/Lca/components/productsItems';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import PageLoading from 'src/components/PageLoading';
import { BigLink } from './steps/index.styles';
import { useLcaOrganization } from 'src/common/hooks';
import { LcaProductMaterial } from '../types';
import { Typography } from '@mui/material';

type Product = {
  data_collection_period: string;
  id: number;
  is_draft: boolean;
  last_updated: string;
  materials: LcaProductMaterial[];
  name: string;
  organization_id: number;
};

export default function LCAPageIndexProducts() {
  const data = [{ name: 'Products List', url: '/lca/products' }];
  const { t } = useTranslation();

  const organization = useLcaOrganization();
  const canWrite = organization.can_write_data;

  const { data: productsData, isLoading } = useQuery(
    ['lca', 'products', { organizationId: organization.id }],
    () => client.get(`/web/lca/products?organization_id=${organization.id}`)
  );

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <LcaLayout>
      <Breadcrumbs data={data} />
      <div
        style={{
          marginTop: '44px',
          marginBottom: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h1">{t('lca.products')}</Typography>
        {canWrite && (
          <BigLink to="/lca/products/create">Create a new product</BigLink>
        )}
      </div>
      {canWrite && (
        <Typography variant="subtitle1" color="text.secondary">
          Click on ’Create new product’ to create a life cycle assessment of
          your product and count your product environment footprint. We will
          guide you through the process step by step.
        </Typography>
      )}
      {!productsData?.data.length && (
        <ProductsItemsEmpty canCreate={canWrite} />
      )}
      {!!productsData?.data.length && (
        <div>
          <Typography
            variant="h5"
            color="text.secondary"
            style={{ marginBottom: '28px', marginTop: '12px' }}
          >
            ALL PRODUCTS
          </Typography>
          <div
            style={{
              display: 'flex',
              gap: '24px',
              flexWrap: 'wrap',
              wordBreak: 'break-word',
            }}
          >
            {productsData?.data.map((el: Product) => (
              <ProductsItemsCreated
                key={el.id}
                id={el.id}
                name={el.name}
                period={el.data_collection_period}
                materials={el.materials}
                last_edited={el.last_updated}
                url={`/lca/products/${el.id}`}
                canDelete={canWrite}
              />
            ))}
          </div>
        </div>
      )}
    </LcaLayout>
  );
}
