import React from 'react';
import { useWasteGasesExported } from 'src/Cbam/hooks';
import { CbamWasteGasInSchema, CbamWasteGasOutSchema } from 'src/Cbam/types';
import { StyledPanel } from 'src/components/StyledPanel';
import { StyledNumberField, StyledTextField } from '../../../styles';
import { Button } from '@mui/material';
import { StyledFlex } from 'src/components/StyledFlex';
import { useChangeTracker } from '../components/CounterProvider';

interface Props {
  productionProcessId: number;
  id?: number;
  onSaved?: () => void;
}

export default function Exported(props: Props) {
  const { id, productionProcessId, onSaved } = props;
  const { isModified, setIsModified } = useChangeTracker();
  const _exported = useWasteGasesExported(productionProcessId);
  const exported = _exported.data.find((e) => e.id === id);

  const initialForm = {
    production_process_id: productionProcessId,
    name: '',
    volume_of_waste_gas_m3: null,
    net_calorific_value_tco2tj: null,
  };

  const [form, setForm] = React.useState<
    CbamWasteGasInSchema | CbamWasteGasOutSchema
  >(initialForm);

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  const onSuccess = () => {
    setIsModified(false);
    onSaved?.();
  };

  const handleSaveClick = () =>
    id
      ? _exported.update.mutateAsync({ ...form, id }).then(onSuccess)
      : _exported.create.mutateAsync(form).then(onSuccess);

  React.useEffect(() => {
    if (exported) setForm(exported);
  }, [exported]);

  return (
    <StyledPanel sx={{ m: '20px 0px' }}>
      <StyledTextField
        label="Name"
        value={form.name}
        onChange={(e) => handleFormChange('name', e.target.value)}
      />
      <StyledNumberField
        label="Volume of waste gas [m3]"
        value={form.volume_of_waste_gas_m3}
        onChange={(value) => handleFormChange('volume_of_waste_gas_m3', value)}
      />
      <StyledNumberField
        label="Net calorific value [tCO2/TJ]"
        value={form.net_calorific_value_tco2tj}
        onChange={(value) =>
          handleFormChange('net_calorific_value_tco2tj', value)
        }
      />
      <br />

      <StyledFlex>
        <Button
          onClick={() => (id ? _exported.delete.mutate(id) : onSaved?.())}
          color="primary"
        >
          Remove Answer
        </Button>
        {isModified && (
          <Button
            color="primary"
            onClick={handleSaveClick}
            sx={{ minWidth: '200px' }}
          >
            {id ? 'Save' : 'Create'}
          </Button>
        )}
      </StyledFlex>
    </StyledPanel>
  );
}
