import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.p`
  color: var(--Dark, #281302);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.64px;
  margin-top: 16px;
`;

export const Desc = styled.p`
  color: var(--Dark-grey, #4d4d4d);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.54px;
`;

export const Heading = styled.p`
  color: var(--Dark, #281302);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-top: 48px;
  margin-bottom: 24px;
  letter-spacing: 0.48px;
`;

const Big = css`
  border-radius: 24px;
  background: var(--brand-color-main, #7e4308);
  height: 56px;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border: 2px solid var(--brand-color-main, #7e4308);
  box-shadow: 0 3px 0px #6d3206;
  padding: 0 24px;

  &:hover {
    background-color: #8f5409;
  }

  &:active {
    background-color: #8f5409;
    box-shadow: none;
    transform: translateY(4px);
  }
`;

const BigWhite = css`
  border-radius: 24px;
  height: 56px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  box-shadow: 0 3px 0px #6d3206;
  padding: 0 24px;
  border: 2px solid var(--brand-color-main, #7e4308);
  background: var(--White, #fff);
  color: var(--brand-color-main, #7e4308);
  position: relative;
  z-index: 2;

  &:hover {
    background-color: #fff7e8;
  }

  &:active {
    background-color: #fff7e8;
    box-shadow: none;
    transform: translateY(4px);
  }
`;

export const BigLink = styled(Link)`
  ${Big}
`;

export const BigButton = styled.button`
  ${Big}
`;

export const BigButtonWhite = styled.button`
  ${BigWhite}
`;
