import styled from 'styled-components';
import themeColors from '../../../theme/colors';

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(60% - 1.5rem);

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const SecondColumn = styled.div`
  width: 40%;
  margin-left: 1.5rem;

  &:first-child {
    max-height: 225px;
  }

  @media (max-width: 1400px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &:first-child {
    margin-left: 0.7rem;
  }

  @media (max-width: 1400px) {
    display: block;
  }
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  color: ${themeColors.grayIcons};
  line-height: 28px;
  margin-bottom: 24px;
`;
