import { Box, Typography, Link, styled, Theme } from '@mui/material';
import { color } from '@mui/system';

export const StyledFooter = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.footerBackground,
  color: theme.palette.footerText,
  padding: '20px 40px 20px 120px',
  textAlign: 'left',
  minHeight: '240px',
  alignItems: 'center',
  minWidth: '950px',
  br: {
    display: 'block',
    height: '10px !important',
  },
  '& a': {
    color: theme.palette.footerText,
  },
}));

export const FooterIcon = styled('img')({
  height: '18px',
  width: '18px',
  marginRight: '10px',
});

export const SubTitleTypography = styled(Typography)({
  marginBottom: '6px',
});

export const SubtitleLink = styled(Link)({
  textDecoration: 'none',
});

export const TitleTypography = styled(Typography)({
  marginBottom: '13px',
});

export const TextWithIconBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '6px',
});
export const FooterLinkWrapper = styled(Box)({
  marginBottom: '5px',
});
