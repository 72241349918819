import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useIndicators, useIndicatorsResponsibilites } from '../hooks';
import { EsgReportSchemaOut } from '../api.types';
import { getIndicatorProgress } from './utils';
import ProgressBar from 'src/components/ProgressBar';
import { StyledFlex } from 'src/components/StyledFlex';
import { DatePicker } from '@mui/x-date-pickers';
import ResponsilityUserSelect from './components/ResponsilityUserSelect';
import AddNewIndicatorResponsibility from './components/AddNewIndicatorResponsibility';
import ResponsibilityMenu from './components/ResponsibilityMenu';
import { format } from 'date-fns';
import { dateFormat } from 'src/common/constants';
import { useTranslation } from 'react-i18next';

/* eslint-disable */

interface Props {
  report: EsgReportSchemaOut;
}
export default function IndicatorsResponsibilityTable(props: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg.administrationPanel.table',
  });
  const { report } = props;
  const { indicators, answeredQuestions } = useIndicators();
  const responsibilities = useIndicatorsResponsibilites();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="small">{t('columns.indicator')}</TableCell>
          <TableCell size="small">{t('columns.progress')}</TableCell>
          <TableCell size="small" sx={{ width: '200px' }}>
            {t('columns.deadLine')}
          </TableCell>
          <TableCell size="small" sx={{ width: '330px' }}>
            {t('columns.responsibleUsers')}
          </TableCell>
          <TableCell size="small">&nbsp;</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {indicators.map((indicator) => {
          const progress = getIndicatorProgress(
            indicator,
            answeredQuestions[report.id]?.[indicator.key]
          );
          const indicatorResponsibilities = responsibilities.data.filter(
            (r) =>
              r.esg_questionnaire_id === indicator.id &&
              r.report_id === report.id
          );
          const rowSpan = indicatorResponsibilities.length
            ? indicatorResponsibilities.length + 2
            : 1;

          return (
            <React.Fragment key={indicator.id}>
              <TableRow>
                <TableCell
                  size="small"
                  rowSpan={rowSpan}
                  sx={{ verticalAlign: 'top' }}
                >
                  {indicator.title}
                </TableCell>
                <TableCell
                  size="small"
                  rowSpan={rowSpan}
                  sx={{ verticalAlign: 'top' }}
                >
                  <StyledFlex>
                    <Typography variant="h5">{progress}%</Typography>
                    &nbsp;
                    <ProgressBar pct={progress} color={indicator.color} />
                  </StyledFlex>
                </TableCell>
                {!indicatorResponsibilities.length && (
                  <AddNewIndicatorResponsibility
                    report={report}
                    indicator={indicator}
                  />
                )}
              </TableRow>
              {indicatorResponsibilities.map((responsibility) => {
                const handleUpdateAppUserId = (appUserId: number) =>
                  responsibilities.update.mutate({
                    ...responsibility,
                    app_user_id: appUserId,
                  });
                const handleUpdateDeadline = (deadline: Date | null) =>
                  responsibilities.update.mutate({
                    ...responsibility,
                    deadline: format(deadline || new Date(), dateFormat),
                  });
                return (
                  <TableRow key={responsibility.id}>
                    <TableCell size="small" sx={{ borderBottom: 'none' }}>
                      <DatePicker
                        sx={{ minWidth: '140px' }}
                        value={new Date(responsibility.deadline)}
                        slotProps={{
                          textField: {
                            size: 'small',
                            inputProps: { readOnly: true },
                          },
                        }}
                        onChange={handleUpdateDeadline}
                      />
                    </TableCell>
                    <TableCell size="small" sx={{ borderBottom: 'none' }}>
                      <ResponsilityUserSelect
                        value={responsibility.app_user_id}
                        onChange={handleUpdateAppUserId}
                        report={report}
                        indicator={indicator}
                      />
                    </TableCell>
                    <TableCell
                      size="small"
                      sx={{ borderBottom: 'none', pl: 0, pr: 0 }}
                    >
                      <ResponsibilityMenu responsibility={responsibility} />
                    </TableCell>
                  </TableRow>
                );
              })}

              {!!indicatorResponsibilities.length && (
                <TableRow>
                  <AddNewIndicatorResponsibility
                    report={report}
                    indicator={indicator}
                  />
                </TableRow>
              )}
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
}
