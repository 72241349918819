import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { StyledPanel } from 'src/components/StyledPanel';
import { useLanguage } from 'src/common/hooks';

const getVideoId = (lang: string) => {
  const videoId: any = { en: '846953577', pl: '810148436' };
  return videoId[lang] || videoId.en;
};

export default function HelpCenter() {
  const { i18n, language } = useLanguage();

  const tutorialVidId = getVideoId(i18n.language.toLowerCase());

  const vimeoUrl = `https://player.vimeo.com/video/${tutorialVidId}?h=633a53ba7d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;

  return (
    <Box>
      <Typography variant="h1">Help Center</Typography>
      <br />
      <br />
      <StyledPanel>
        <Typography variant="h3">Getting started with Envirly CBAM</Typography>
        <br />
        <Typography variant="body1">
          To begin with, find out the essential information about the EU Carbon
          Border Adjustment Mechanism. Watch the introductory video.
        </Typography>

        <br />
        <Box display="flex" justifyContent="center">
          <iframe
            src={vimeoUrl}
            width="826"
            height="465"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Envirly"
          />
        </Box>
      </StyledPanel>
      <br />
      <br />

      <StyledPanel>
        <Typography variant="h3">
          How to use the Envirly CBAM platform?
        </Typography>
        <br />
        <Typography variant="body1">
          Learn how to use Envirly CBAM. Our manual will guide you step-by-step
          through all the phases of reporting.
        </Typography>
        <br />
        <Button
          href={`https://assets.envirly.pl/intro/${language}/CBAM.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download manual
        </Button>
      </StyledPanel>
    </Box>
  );
}
