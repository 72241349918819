import React from 'react';
import {
  Dialog,
  Button,
  DialogContent,
  Typography,
  DialogTitle,
} from '@mui/material';
import { Trans } from 'react-i18next';

interface ModalBaseProps {
  titleKey?: string;
  textKey?: string;
  children?: React.ReactNode;
  open: boolean;
  onClose?: () => void;
}

export function ModalBase(props: ModalBaseProps) {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md">
      <DialogTitle>
        <Typography sx={{ textAlign: 'center' }}>
          <Trans i18nKey={props.titleKey} />
        </Typography>
      </DialogTitle>
      <DialogContent>
        {!!props.textKey && (
          <Typography sx={{ textAlign: 'center' }}>
            <Trans i18nKey={props.textKey} />
          </Typography>
        )}
        {props.children}
      </DialogContent>
    </Dialog>
  );
}

interface ConfirmationModalProps extends ModalBaseProps {
  trueButtonTextKey?: string;
  falseButtonTextKey?: string;
  // eslint-disable-next-line no-unused-vars
  onFalse?: () => void;
  onTrue?: () => void;
}

export default function ConfirmationModal({
  titleKey = 'common.areYouSure',
  textKey = undefined,
  trueButtonTextKey = 'common.yes',
  falseButtonTextKey = 'common.no',
  open = false,
  onClose,
  onFalse,
  onTrue,
  children,
}: ConfirmationModalProps) {
  return (
    <ModalBase
      titleKey={titleKey}
      textKey={textKey}
      open={open}
      onClose={onClose}
    >
      {children}
      <div
        style={{
          gap: '20px',
          justifyContent: 'center',
          display: 'flex',
          paddingTop: '30px',
        }}
      >
        <Button onClick={onTrue} variant="secondary" sx={{ width: '224px' }}>
          <Trans i18nKey={trueButtonTextKey} />
        </Button>
        <Button onClick={onFalse} sx={{ width: '224px' }}>
          <Trans i18nKey={falseButtonTextKey} />
        </Button>
      </div>
    </ModalBase>
  );
}
