import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from 'src/utils/api-client';
import {
  CbamInstallationInSchema,
  CbamInstallationOutSchema,
  CbamElectricitySchema,
  CbamProductionProcessOutSchema,
  CbamImportedGoodOutSchema,
  CbamImportedGoodInSchema,
  CbamProductionProcessSurveyInSchema,
  FuelTypesOutSchema,
  CbamActorOutSchema,
  CbamActorInSchema,
  ProductionProcessGeneralInformation,
  SectorSpecificSchema,
  CbamHeatSchema,
  CbamWasteGasOutSchema,
  CbamWasteGasInSchema,
  CbamDirectEmissionsSchema,
  CbamDirectCombustionEmissionOutSchema,
  CbamDirectCombustionEmissionInSchema,
  CbamDirectProcessEmissionOutSchema,
  CbamDirectProcessEmissionInSchema,
  CbamMassBalanceInSchema,
  CbamMassBalanceOutSchema,
  CbamMeasurementBasedOutSchema,
  CbamMeasurementBasedInSchema,
  CbamCo2TransferSchema,
  CbamPrecursorInSchema,
  CbamPrecursorOutSchema,
  CbamReportOutSchema,
  CbamReportInSchema,
  CbamInstallationOperatorOutSchema,
  CbamInstallationOperatorInSchema,
  CbamSupportingDocumentForImportedGoodOutSchema,
  CbamSupportingDocumentForImportedGoodInSchema,
  CbamSupportingDocumentForProductionProcessInSchema,
  CbamSupportingDocumentForProductionProcessOutSchema,
  CbamProductionProcessEmissionsSchema,
  WithId,
  CbamCnGoodCode,
  CbamIndirectEmissionsDefaultFactor,
} from './types';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

export const useInstallations = (productionProcessId?: string) => {
  const url = '/web/cbam/installations';
  const queryClient = useQueryClient();
  const queryKey = ['installations', { productionProcessId }];

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamInstallationOutSchema[]>(url, {
        params: productionProcessId
          ? { production_process_id: productionProcessId }
          : {},
      })
      .then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: Partial<CbamInstallationOutSchema>) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamInstallationInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return {
    data: (data || []).sort((a, b) => a.id - b.id),
    update,
    create,
    delete: _delete,
  };
};

export const useOperators = () => {
  const url = '/web/cbam/operators';
  const queryClient = useQueryClient();
  const queryKey = 'operators';

  const { data } = useQuery(queryKey, () =>
    client.get<CbamInstallationOperatorOutSchema[]>(url).then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: Partial<CbamInstallationOperatorOutSchema>) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamInstallationOperatorInSchema) =>
      client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return {
    data: (data || []).sort((a, b) => a.id - b.id),
    update,
    create,
    delete: _delete,
  };
};

const productionProcessEmissionsQueryKey = (id: number) => [
  'production-process-emissions',
  { id },
];

export const useProductionProcessEmissions = (id: number) => {
  const url = `/web/cbam/production-processes/${id}/emissions`;

  const { data } = useQuery(productionProcessEmissionsQueryKey(id), () =>
    client
      .get<CbamProductionProcessEmissionsSchema>(url)
      .then((res) => res.data)
  );

  const empty = {
    id,
    specific_attributed_indirect_emissions: 0,
    specific_attributed_direct_emissions: 0,
  };

  return { data: data || empty };
};

export const useProductionProcesses = () => {
  const url = '/web/cbam/production-processes';
  const queryClient = useQueryClient();
  const queryKey = 'production-processes';

  const { data } = useQuery(queryKey, () =>
    client.get<CbamProductionProcessOutSchema[]>(url).then((res) => res.data)
  );

  const onSuccess = (
    response: AxiosResponse<CbamProductionProcessOutSchema>
  ) => {
    queryClient.invalidateQueries({ queryKey });
    queryClient.invalidateQueries({
      queryKey: productionProcessEmissionsQueryKey(response.data.id),
    });
    toast.success('Success');
  };

  const update = useMutation({
    mutationFn: (data: Partial<CbamProductionProcessOutSchema>) =>
      client.put<CbamProductionProcessOutSchema>(`${url}/${data.id}`, data),
    onSuccess,
  });

  const create = useMutation({
    mutationFn: (data: ProductionProcessGeneralInformation) =>
      client.post<CbamProductionProcessOutSchema>(url, data),
    onSuccess,
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const updateSurvey = useMutation({
    mutationFn: (data: CbamProductionProcessSurveyInSchema & { id: number }) =>
      client.put(`${url}/${data.id}/survey`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], update, create, delete: _delete, updateSurvey };
};

export const useImportedGoods = (reportId?: string) => {
  const url = '/web/cbam/imported-goods';
  const queryClient = useQueryClient();
  const queryKey = 'imported-goods';

  const { data } = useQuery([queryKey, { reportId }], () =>
    client
      .get<CbamImportedGoodOutSchema[]>(url, {
        params: reportId ? { report_id: reportId } : {},
      })
      .then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: Partial<CbamImportedGoodOutSchema>) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamImportedGoodInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], update, create, delete: _delete };
};

export const useFuelTypes = () => {
  const queryKey = 'fuel-types';

  const query = useQuery(queryKey, () =>
    client
      .get<FuelTypesOutSchema>('/web/cbam/fuel-types')
      .then((res) => res.data)
  );

  return { ...query, data: query.data || {} };
};

export const useCnGoodCodes = () => {
  const queryKey = 'good-codes';

  const query = useQuery(queryKey, () =>
    client
      .get<CbamCnGoodCode[]>('/web/cbam/good-codes/cn')
      .then((res) => res.data)
  );

  return { ...query, data: query.data || [] };
};

export const useIndirectEmissionsDefaultFactors = () => {
  const queryKey = 'indirect-emissions-default-factors';

  const query = useQuery(queryKey, () =>
    client
      .get<
        CbamIndirectEmissionsDefaultFactor[]
      >('/web/cbam/indirect-emission-factors')
      .then((res) => res.data)
  );

  return { ...query, data: query.data || [] };
};

export const useActors = () => {
  const url = '/web/cbam/actors';
  const queryClient = useQueryClient();
  const queryKey = 'actors';

  const { data } = useQuery(queryKey, () =>
    client.get<CbamActorOutSchema[]>(url).then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: Partial<CbamActorOutSchema>) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamActorInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return {
    data: data || [],
    update,
    create,
    delete: _delete,
  };
};

function ReadUpdate<Schema>(id: number, url: string, queryKey: any) {
  const queryClient = useQueryClient();

  const { data } = useQuery(
    queryKey,
    () => client.get<Schema>(url).then((res) => res.data),
    { enabled: !!id }
  );

  const update = useMutation({
    mutationFn: (data: Schema) => client.put<Schema>(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: productionProcessEmissionsQueryKey(id),
      });
      toast.success('Success');
    },
    onError: () => {
      toast.error('Failed to save');
    },
  });

  return { data, update };
}

export const useElectricity = (id: number) => {
  const url = `/web/cbam/production-processes/${id}/electricity`;
  const queryKey = ['production-process', id, 'electricity'];
  return ReadUpdate<CbamElectricitySchema>(id, url, queryKey);
};

export const useHeat = (id: number) => {
  const url = `/web/cbam/production-processes/${id}/heat`;
  const queryKey = ['production-process', id, 'heat'];
  return ReadUpdate<CbamHeatSchema>(id, url, queryKey);
};

export const useSectorSpecific = (id: number) => {
  const url = `/web/cbam/production-processes/${id}/sector-specific`;
  const queryKey = ['production-process', id, 'sector-specific'];
  return ReadUpdate<SectorSpecificSchema>(id, url, queryKey);
};

export const useCo2Transfer = (id: number) => {
  const url = `/web/cbam/production-processes/${id}/co2-transfer`;
  const queryKey = ['production-process', id, 'co2-transfer'];
  return ReadUpdate<CbamCo2TransferSchema>(id, url, queryKey);
};

export const useDirectEmissions = (id: number) => {
  const url = `/web/cbam/production-processes/${id}/direct-emissions`;
  const queryKey = ['production-process', id, 'direct-emissions'];
  return ReadUpdate<CbamDirectEmissionsSchema>(id, url, queryKey);
};

function CRUD<InSchema, OutSchema extends InSchema & WithId>(
  productionProcessId: number,
  url: string,
  queryKey: any
) {
  const queryClient = useQueryClient();

  const { data } = useQuery(queryKey, () =>
    client
      .get<OutSchema[]>(url, {
        params: { production_process_id: productionProcessId },
      })
      .then((res) => res.data)
  );

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey });
    queryClient.invalidateQueries({
      queryKey: productionProcessEmissionsQueryKey(productionProcessId),
    });
    toast.success('Success');
  };
  const onError = () => {
    toast.error('Failed to save');
  };

  const create = useMutation({
    mutationFn: (data: InSchema) => client.post<OutSchema>(url, data),
    onSuccess,
    onError,
  });

  const update = useMutation({
    mutationFn: (data: OutSchema) =>
      client.put<OutSchema>(`${url}/${data.id}`, data),
    onSuccess,
    onError,
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess,
    onError,
  });

  return { data: data || [], create, update, delete: _delete };
}

export const useWasteGasesConsumed = (productionProcessId: number) => {
  const url = `/web/cbam/waste-gases-consumed`;
  const queryKey = ['waste-gases-consumed', { productionProcessId }];
  return CRUD<CbamWasteGasInSchema, CbamWasteGasOutSchema>(
    productionProcessId,
    url,
    queryKey
  );
};

export const useWasteGasesExported = (productionProcessId: number) => {
  const url = `/web/cbam/waste-gases-exported`;
  const queryKey = ['waste-gases-exported', { productionProcessId }];
  return CRUD<CbamWasteGasInSchema, CbamWasteGasOutSchema>(
    productionProcessId,
    url,
    queryKey
  );
};

export const useDirectCombustionEmissions = (productionProcessId: number) => {
  const url = `/web/cbam/direct-emissions-combustion`;
  const queryKey = ['direct-combustion-emissions', { productionProcessId }];
  return CRUD<
    CbamDirectCombustionEmissionInSchema,
    CbamDirectCombustionEmissionOutSchema
  >(productionProcessId, url, queryKey);
};

export const useDirectProcessEmissions = (productionProcessId: number) => {
  const url = `/web/cbam/direct-emissions-process`;
  const queryKey = ['direct-process-emissions', { productionProcessId }];
  return CRUD<
    CbamDirectProcessEmissionInSchema,
    CbamDirectProcessEmissionOutSchema
  >(productionProcessId, url, queryKey);
};

export const useMassBalance = (productionProcessId: number) => {
  const url = `/web/cbam/mass-balance`;
  const queryKey = ['mass-balance', { productionProcessId }];
  return CRUD<CbamMassBalanceInSchema, CbamMassBalanceOutSchema>(
    productionProcessId,
    url,
    queryKey
  );
};

export const useMeasurementBased = (productionProcessId: number) => {
  const url = `/web/cbam/measurement-based`;
  const queryKey = ['measurement-based', { productionProcessId }];
  return CRUD<CbamMeasurementBasedInSchema, CbamMeasurementBasedOutSchema>(
    productionProcessId,
    url,
    queryKey
  );
};

export const usePrecursors = (productionProcessId: number) => {
  const url = `/web/cbam/precursors`;
  const queryKey = ['precursors', { productionProcessId }];
  return CRUD<CbamPrecursorInSchema, CbamPrecursorOutSchema>(
    productionProcessId,
    url,
    queryKey
  );
};

export const useReports = () => {
  const url = '/web/cbam/reports';
  const queryClient = useQueryClient();
  const queryKey = 'reports';

  const { data } = useQuery('reports', () =>
    client.get<CbamReportOutSchema[]>(url).then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: Partial<CbamReportOutSchema>) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamReportInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], create, update, delete: _delete };
};

export const useSupportingDocumentsForImportedGoods = () => {
  const url = '/web/cbam/supporting-documents-for-imported-goods';
  const queryClient = useQueryClient();
  const queryKey = 'supporting-documents-for-imported-goods';

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamSupportingDocumentForImportedGoodOutSchema[]>(url)
      .then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (
      data: Partial<CbamSupportingDocumentForImportedGoodOutSchema>
    ) => client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamSupportingDocumentForImportedGoodInSchema) =>
      client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return {
    data: data || [],
    update,
    create,
    delete: _delete,
  };
};

export const useSupportingDocumentsForProductionProcesses = () => {
  const url = '/web/cbam/supporting-documents-for-production-processes';
  const queryClient = useQueryClient();
  const queryKey = 'supporting-documents-for-production-processes';

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamSupportingDocumentForProductionProcessOutSchema[]>(url)
      .then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (
      data: Partial<CbamSupportingDocumentForProductionProcessOutSchema>
    ) => client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamSupportingDocumentForProductionProcessInSchema) =>
      client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return {
    data: data || [],
    update,
    create,
    delete: _delete,
  };
};
