import Select, { SelectProps } from '@mui/material/Select';
import { styled as styledMUI } from '@mui/material/styles';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import { StyledFormControlProps, FieldValueProps } from './index.interfaces';
import { ValueFieldCSS } from '../../../../../components/TextFieldNumber/index.styles';

export const StyledSelect = styledMUI(Select)<SelectProps>`
  & .MuiSelect-select {
    position: relative;
    color: #132c1d;
    font-size: 18px;
    font-weight: bold;
  };
`;

export const StyledFormControl = styledMUI(FormControl)<StyledFormControlProps>(
  (props) => `
    position: relative;
    border-color: #B6BBB9;
    background-color: ${props.value ? '#F2F2F2' : 'white'};
    &:hover {
      border-color: #2F2F2F;
    };
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #165D5C;
      };
    };
    & label.Mui-focused {
      color: #165D5C;
    };
    & label {
      background-color: ${props.value ? 'white' : 'transparent'};
      padding-right: 7px;
      padding-left: 7px;
      margin-left: -4px;
    };
  `
);

export const ValueField = styled.div<FieldValueProps>(
  (props) => `  
    width: ${props.max === 1 ? '100%' : 'calc(50% - 10px)'};
    ${ValueFieldCSS}
    `
);
