import { Box, Typography } from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';

import {
  useProductionProcessEmissions,
  useProductionProcesses,
} from 'src/Cbam/hooks';

import { AggregatedGoodsCategory } from 'src/Cbam/types';
import { quantityUnit } from 'src/Cbam/utils';
import EmissionSummary from './EmissionSummary';

interface Props {
  id: number;
  aggregatedGoodsCategory: AggregatedGoodsCategory | null;
}

export default function EmissionsSummary(props: Props) {
  const { id, aggregatedGoodsCategory } = props;
  const productionProcesses = useProductionProcesses();
  const productionProcess = productionProcesses.data.find(
    (p) => p.id === Number(id)
  );
  const { data: emissions } = useProductionProcessEmissions(id);

  const unit = quantityUnit(productionProcess);

  if (!productionProcess) return null;

  return (
    <>
      <Typography variant="h3">Emissions Summary</Typography>
      <br />
      <StyledPanel>
        {aggregatedGoodsCategory !== AggregatedGoodsCategory.Electricity && (
          <Box sx={{ mb: '12px' }}>
            <EmissionSummary
              type="Indirect"
              emissions={emissions.specific_attributed_indirect_emissions}
              unit={unit}
            />
          </Box>
        )}
        <EmissionSummary
          type="Direct"
          emissions={emissions.specific_attributed_direct_emissions}
          unit={unit}
        />
      </StyledPanel>
    </>
  );
}
