import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled as styledMUI } from '@mui/material/styles';
import styled, { css } from 'styled-components';
import { FieldValueProps } from './index.interfaces';

export const StyledSpan = styled.span`
  color: grey;
  font-weight: normal;
  margin-left: 7px;
`;

export const StyledTextField = styledMUI(TextField)<TextFieldProps>(
  (props) => `
  width: 100%;
  border-color: #B6BBB9;
  border-radius: 2px;
  background-color: ${props.value ? '#F2F2F2' : 'white'};
  & :hover {
    border-color: #2F2F2F;
  },
  & .MuiOutlinedInput-root {
    font-family: 'Poppins', sans-serif;
    &.Mui-focused fieldset {
      border-color: #165D5C;
    },
  },
  & label.Mui-focused {
    color: #165D5C;
    
  },
  & label {
    background-color: ${props.value ? 'white' : 'transparent'};
    padding-right: 7px;
    padding-left: 7px;
    margin-left: -4px;
    font-family: 'Poppins', sans-serif;
  },
  input {
    color: #132C1D;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
  },
`
);

export const ValueFieldCSS = css`
  margin: 5px 0;
  color: #132c1d;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #b6bbb9;
  border-radius: 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 16.5px 14px;
`;

export const ValueField = styled.div<FieldValueProps>(
  (props) => `  
    width: ${props.max === 1 ? '100%' : 'calc(50% - 10px)'};
   ${ValueFieldCSS}
    `
);

export const ValueParagraph = styled.p`
  padding: 0;
  margin: 0;
  height: 1.4375em;
`;

export const ValueLabel = styled.span`
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  transform: translate(14px, -9px) scale(0.75);
  color: rgba(0, 0, 0, 0.6);
  padding-right: 7px;
  padding-left: 7px;
  margin-left: -4px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  transform-origin: top left;
  max-width: calc(133% - 24px);
  white-space: nowrap;
`;
