import React from 'react';
import { Document, PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import introductionSection from './sections/Introduction';
import carbonFootprintSection from './sections/CarbonFootprint';
import employeeEmissions from './sections/EmployeeEmissions';
import aboutEnvirly from './sections/AboutEnvirly';
import ReportDataType from './types';
import { Button } from '@mui/material';

export type PDFReportProps = {
  data: ReportDataType;
  requestError?: Object;
};

export const PDFReport = ({ data, requestError }: PDFReportProps) => {
  const { t } = useTranslation();

  const {
    company_name: companyName,
    report_title: reportTitle,
    report_range: reportRange,
  } = data.introduction.cover;

  const removeSpaces = (text: String, char: string = '') =>
    text.replaceAll(' ', char);

  const fileName = removeSpaces(
    `${companyName}-${reportRange}-${removeSpaces(reportTitle, '-')}.pdf`
  );

  return (
    <PDFDownloadLink
      document={
        <Document>
          {data.introduction && introductionSection(data.introduction)}

          {data.carbon_footprint &&
            carbonFootprintSection(data.carbon_footprint)}

          {data.employee_emissions &&
            employeeEmissions(data.employee_emissions)}

          {data.about_envirly && aboutEnvirly(data.about_envirly)}
        </Document>
      }
      fileName={fileName}
    >
      {({ url, error }) => {
        if (error) return String(error);
        if (requestError) return t('requests.cannot-get-data-from-server');
        if (!url) return t('raport.creating');
        return <Button size="large">{t('raport.download')}</Button>;
      }}
    </PDFDownloadLink>
  );
};

export default PDFReport;
