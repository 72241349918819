import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { Button } from '@mui/material';
import { StyledTitle } from '../index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import BackButton from '../../components/BackButton';
import UserForm, {
  useUserForm,
  validateForm,
} from '../components/Panels/UserForm';
import { client } from 'src/utils/api-client';
import { UserInFormSchema } from '../index.types';
import { extractSchemaErrors } from '../../utils/validation';

export default function NewUnit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const userFormState = useUserForm();

  const handleCreate = () => {
    const data: UserInFormSchema = {
      first_name: userFormState.firstName,
      last_name: userFormState.lastName,
      position: userFormState.position,
      email: userFormState.email,
      phone: userFormState.phone,
      initial_organization_id: userFormState.initialOrganizationId,
    };
    const formErrors = validateForm(data, true);
    userFormState.setErrors(formErrors);
    if (Object.keys(formErrors).length > 0) {
      return;
    }
    client
      .post(`/web/settings_v2/users`, data)
      .then((response) => {
        toast.success(<Trans i18nKey="superadmin.toast.user-created" />);
        queryClient.invalidateQueries('organization-members');
        navigate(`/superadmin/users/${response.data.id}`);
        return response.data;
      })
      .catch((err) => {
        toast.error(String(t('superadmin.toast.validation-error')));
        const errors = {
          ...extractSchemaErrors(err, data),
        };
        userFormState.setErrors(errors);
      });
  };

  return (
    <>
      <StyledFlex>
        <StyledTitle>
          <BackButton navigateTo="/superadmin#users" />
          {t('superadmin.user.newUser')}
        </StyledTitle>
      </StyledFlex>

      <UserForm {...userFormState} />

      <StyledFlex sx={{ mt: '50px' }}>
        <div />
        <Button onClick={handleCreate}>
          {t('superadmin.user.buttons.create')}
        </Button>
      </StyledFlex>
    </>
  );
}
