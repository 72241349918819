import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CloudText,
  CloudTitle,
  EmployeeWrapper,
  PointsWrapper,
} from './index.styles';

export default function EmployeeInfoContent() {
  const { t } = useTranslation();
  return (
    <EmployeeWrapper>
      <div>
        <CloudTitle>{t('employeeInfoCloud.employee.title')}</CloudTitle>
        <div style={{ height: '112px', marginBottom: '34px' }}>
          <CloudText
            dangerouslySetInnerHTML={{
              __html: t('employeeInfoCloud.employee.desc'),
            }}
          />
        </div>
        <PointsWrapper>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="10" r="10" fill="#165D5C" />
              <rect
                x="5.69824"
                y="9.10938"
                width="6.31579"
                height="2.10526"
                rx="1.05263"
                transform="rotate(45 5.69824 9.10938)"
                fill="white"
              />
              <rect
                x="14.6289"
                y="6.13281"
                width="2.10526"
                height="10.5263"
                rx="1.05263"
                transform="rotate(45 14.6289 6.13281)"
                fill="white"
              />
            </svg>
            <p>{t('employeeInfoCloud.employee.point1')}</p>
          </div>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="10" r="10" fill="#165D5C" />
              <rect
                x="5.69824"
                y="9.10938"
                width="6.31579"
                height="2.10526"
                rx="1.05263"
                transform="rotate(45 5.69824 9.10938)"
                fill="white"
              />
              <rect
                x="14.6289"
                y="6.13281"
                width="2.10526"
                height="10.5263"
                rx="1.05263"
                transform="rotate(45 14.6289 6.13281)"
                fill="white"
              />
            </svg>
            <p>{t('employeeInfoCloud.employee.point2')}</p>
          </div>
        </PointsWrapper>
      </div>
      <div>
        <CloudTitle>{t('employeeInfoCloud.income.title')}</CloudTitle>
        <div style={{ height: '112px', marginBottom: '34px' }}>
          <CloudText
            dangerouslySetInnerHTML={{
              __html: t('employeeInfoCloud.income.desc'),
            }}
          />
        </div>
        <PointsWrapper>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="10" r="10" fill="#165D5C" />
              <rect
                x="5.69824"
                y="9.10938"
                width="6.31579"
                height="2.10526"
                rx="1.05263"
                transform="rotate(45 5.69824 9.10938)"
                fill="white"
              />
              <rect
                x="14.6289"
                y="6.13281"
                width="2.10526"
                height="10.5263"
                rx="1.05263"
                transform="rotate(45 14.6289 6.13281)"
                fill="white"
              />
            </svg>
            <p>{t('employeeInfoCloud.income.point1')}</p>
          </div>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="10" r="10" fill="#165D5C" />
              <rect
                x="5.69824"
                y="9.10938"
                width="6.31579"
                height="2.10526"
                rx="1.05263"
                transform="rotate(45 5.69824 9.10938)"
                fill="white"
              />
              <rect
                x="14.6289"
                y="6.13281"
                width="2.10526"
                height="10.5263"
                rx="1.05263"
                transform="rotate(45 14.6289 6.13281)"
                fill="white"
              />
            </svg>
            <p>{t('employeeInfoCloud.income.point2')}</p>
          </div>
        </PointsWrapper>
      </div>
    </EmployeeWrapper>
  );
}
