import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { companiesColors } from '../SuperDashboard/data';
import { styled as styledMUI } from '@mui/material/styles';
import { Alert } from '@mui/material';
import themeColors from '../../../theme/colors';
import { Link } from 'react-router-dom';

/* eslint-disable no-unused-vars */

export const Container = styled.div`
  padding-bottom: 40px;
  text-align: left;
  color: #2f2f2f;
  width: calc(100vw - 23.375rem);
`;

export const Breadcrumbs = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 36px;

  a {
    text-decoration: none;
    color: ${themeColors.dark};
  }

  span {
    padding: 0 3px;
  }

  & > div {
    color: ${themeColors.grayIcons};
  }
`;

export const ChartContainer = styled.div`
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;
`;

export const Cell = styled.div`
  display: contents;
  flex-direction: column;
`;

export const StyledAlert = styledMUI(Alert)`
  margin-bottom: 2.75rem;
`;

export const StyledHeader = styled.div`
  display: flex;
`;

export const PreviewBox = styled.div`
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #dddddd;
  padding: 24px 45px;
  margin: 50px 0;
`;

export const StyledTd = styled(
  ({
    width,
    colorIdx,
    ...props
  }: { width: number; colorIdx?: number } & HTMLAttributes<HTMLDivElement>) => (
    <div {...props} />
  )
)`
  border-left: 6px solid
    ${({ colorIdx }) =>
      companiesColors[(colorIdx ?? 1) % companiesColors.length]};
  width: ${({ width }) => width}%;
  background-color: #f2f2f2;
  height: 57px;
  font-size: 15px;
  padding-left: 11px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  color: #2f2f2f;
  font-weight: 500;
`;

export const StyledTdInvisible = styled(
  ({ width, ...props }: { width: number } & HTMLAttributes<HTMLDivElement>) => (
    <div {...props} />
  )
)`
  font-size: 16px;
  font-weight: 500;
  height: 57px;
  padding-left: 11px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  width: ${({ width }) => width}%;
  flex: 1;

  div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50%;
    width: 100%;
  }

  button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 50%;

    min-height: 24px;
    min-width: 24px;
    max-height: 48px;
    max-width: 48px;
  }

  button:hover {
    background-color: #f2f2f2;
  }

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

export const StyledTh = styled(
  ({ width, ...props }: { width: number } & HTMLAttributes<HTMLDivElement>) => (
    <div {...props} />
  )
)`
  background-color: #606462;
  border-left: 6px solid #ffffff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  height: 75px;
  padding-left: 11px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  width: ${({ width }) => width}%;

  &:first-child {
    border-left: 6px solid #606462;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ButtonFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const Title = styled.h1`
  color: #2f2f2f;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = css`
  display: flex;

  cursor: pointer;
  padding: 0 12px 0 18px;
  text-decoration: none;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
  background-color: #fff;
  height: 48px;
  color: ${themeColors.greenBrand};
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  border: 1px solid #dddddd;
  border-radius: 24px;

  svg {
    margin-left: 6px;
  }

  &:hover {
    background-color: ${themeColors.hoverButton};
  }
`;

export const EditButton = styled(Link)`
  ${Button};
`;

export const RemoveButton = styled.button`
  margin-left: 8px;
  ${Button};
`;

export const BackButton = styled(Link)`
  ${Button};
  padding: 0 24px 0 18px;

  svg {
    margin-left: 0;
    margin-right: 6px;
  }
`;

export const BoxContainer = styled.div`
  padding-left: 0;
`;
