import React from 'react';

// Parses the value of the input field to ensure it has the correct format
// checks maxDigits and decimalPlaces
// changes ',' to '.'
// eslint-disable-next-line import/prefer-default-export
export const parseValue = (
  event: React.ChangeEvent<HTMLInputElement>,
  maxDigits: number,
  decimalPlaces: number
): [React.ChangeEvent<HTMLInputElement>, string | number | null] => {
  const value = event.target.value.replace(',', '.');
  const [digits, decimals, ..._] = value.split('.');
  const _digits = digits.slice(0, maxDigits);
  const _decimals = decimals?.slice(0, decimalPlaces);
  const _decimalsString = value.includes('.') ? `.${_decimals}` : '';
  const _value = `${_digits}${_decimalsString}`;
  event.target.value = _value;
  if (_value === '') return [event, null];
  return [event, _value];
};
