import styled from 'styled-components';
import themeColors from '../../theme/colors';
import { colors } from '../../views/Pages/SuperDashboard/data';

export const TableButtons = styled.div`
  display: flex;
  margin-left: -11px;
  button {
    background-color: transparent;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  button:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  button:active {
    background: rgba(0, 0, 0, 0.08);
  }

  svg {
    display: block;
    width: 48px;
    height: 48px;
  }
`;

const rowStyle = (n: number, i: number, c: string) => `
& tr:nth-child(${n}n+${i + 2}) {
  --row-color: ${c};
}`;

const allRowsStyle = (colors_: string[]) =>
  colors_.map((color, idx) => rowStyle(colors_.length, idx, color)).join('\n');

const defaultColors = colors;

export const Table = styled.table<{
  colors?: string[];
}>`
  width: 100%;
  border-collapse: collapse;
  ${(props) => allRowsStyle(props.colors || defaultColors)}
`;

export const StyledTh = styled.th<{
  width?: string;
  color?: string;
}>`
  background-color: ${({ color }) => color || '#606462'};
  border-left: 6px solid #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  min-height: 57px;
  padding: 5px 10px 5px 11px;
  text-align: left;
  height: 57px;
  width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  &:first-child {
    border-left: 6px solid ${({ color }) => color || '#606462'};
  }
`;

export const Row = styled.tr<{
  active?: boolean;
  visited?: boolean;
  hide?: boolean;
}>`
  border-bottom: 8px solid #fff;
  cursor: ${({ active }) => (active ? 'pointer' : 'unset')};

  &:hover {
    td {
      background-color: ${({ active }) => (active ? '#f2f2f2' : '#f8f8f8')};
    }
  }
  &:active {
    td {
      background-color: ${({ active }) => (active ? '#fff' : '#f8f8f8')};
    }
  }

  td {
    border-left: 6px solid var(--row-color);

    background-color: var(--Table-background, #f8f8f8);
    min-height: 57px;
    font-size: 15px;
    padding: 12px;
    word-break: break-all;
    color: ${({ hide }) => (hide ? 'transparent' : themeColors.dark)};
    text-shadow: ${({ hide }) => (hide ? '0 0 5px rgba(0,0,0,0.5)' : 'none')};
    font-weight: ${({ visited }) => (visited ? '400' : '700')};

    p {
      flex-grow: 1;
    }

    & > .comment {
      position: relative;
      top: -5px;
      display: block;
      margin-left: auto;
      width: 32px;
      height: 32px;
    }
  }

  &:last-child {
    border-bottom: 0;
  }
`;
