import { styled } from '@mui/material/styles';
import { Box, Menu, Typography } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  ...theme.mixins.toolbar,
  width: '100%',
  backgroundColor: '#fff',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 9px 6px -5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing('8px', 0),
  paddingRight: theme.spacing(2),
}));

export const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 24px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px 0 rgba(47, 47, 47, 0.09);
    margin-top: 10px;
  }
`;
