import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Logo } from './icons';
import ChangeLanguage from '../TopBar/ChangeLanguage';

const ContentBox = styled.div`
  border-radius: 16px;
  border: 1px solid var(--Light-grey, #ccc);
  background: var(--White, #fff);
`;

export type NavButtonProps = {
  label: string;
  onClick: () => void;
};

export type SurveyContainerProps = {
  header?: React.ReactNode;
  logo: React.ReactNode;
  children: React.ReactNode;
  prevLink?: NavButtonProps;
  nextLink?: NavButtonProps;
};

export default function SurveyContainer({
  header,
  children,
  nextLink,
  prevLink,
  logo,
}: SurveyContainerProps) {
  const { t } = useTranslation();

  return (
    <Box width="800px" marginX="auto">
      <div
        style={{
          margin: '45px 0',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ position: 'relative' }}>{logo}</div>
        <ChangeLanguage localOnly />
      </div>
      <ContentBox>
        {header}
        <div style={{ padding: 45 }}>{children}</div>
      </ContentBox>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '40px 0 80px 0',
          paddingBottom: '40px',
        }}
      >
        <div>
          {!!prevLink && (
            <Button
              variant="secondary"
              onClick={prevLink.onClick}
              style={{ width: 'auto', padding: '0 24px' }}
            >
              {'<'} {t(prevLink.label)}
            </Button>
          )}
        </div>
        <div>
          {!!nextLink && (
            <Button onClick={nextLink.onClick}>{t(nextLink.label)}</Button>
          )}
        </div>
      </div>
    </Box>
  );
}
