import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import {
  useDirectCombustionEmissions,
  useDirectEmissions,
  useDirectProcessEmissions,
} from 'src/Cbam/hooks';
import { CbamDirectEmissionsSchema } from 'src/Cbam/types';
import { StyledPanel } from 'src/components/StyledPanel';
import { StyledTextField } from '../../../styles';
import DirectCombustionEmission from './DirectCombustionEmission';
import DirectProcessEmission from './DirectProcessEmission';
import { useChangeTracker } from '../components/CounterProvider';
import InfoPanel from 'src/Cbam/components/InfoPanel';

interface Props {
  id: number;
}

export default function DirectEmissions(props: Props) {
  const { id } = props;
  const { isModified, setIsModified } = useChangeTracker();
  const directEmissions = useDirectEmissions(Number(id));
  const combustionEmissions = useDirectCombustionEmissions(Number(id));
  const processEmissions = useDirectProcessEmissions(Number(id));

  const initialForm = {
    production_process_id: id,
    monitoring_methodology_documentation: null,
  };

  const [isNewProcessVisible, setIsNewProcessVisible] = React.useState(false);
  const [isNewCombustionVisible, setIsNewCombustionVisible] =
    React.useState(false);

  const [form, setForm] =
    React.useState<CbamDirectEmissionsSchema>(initialForm);

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  React.useEffect(() => {
    if (directEmissions.data) setForm(directEmissions.data);
  }, [directEmissions.data]);

  const handleSaveClick = () =>
    directEmissions.update.mutateAsync(form).then(() => {
      setIsModified(false);
    });

  return (
    <>
      <Typography variant="h3">Standard Method (B.3.1)</Typography>
      <br />
      <InfoPanel>
        <Typography>
          In Standard method, emissions are calculated by adding combustion and
          process emissions, counted separately for each source stream.
        </Typography>
      </InfoPanel>
      <br />
      {/* <StyledPanel>
        <Typography variant="h5">Methodology</Typography>
        <StyledTextField
          multiline
          minRows={3}
          label="Monitoring methodology documentation"
          value={form.monitoring_methodology_documentation || ''}
          onChange={(e) =>
            handleFormChange(
              'monitoring_methodology_documentation',
              e.target.value
            )
          }
        />
        {isModified && (
          <Box sx={{ width: '100%', textAlign: 'right', mt: '12px' }}>
            <Button
              color="primary"
              onClick={handleSaveClick}
              sx={{ minWidth: '200px' }}
            >
              Save
            </Button>
          </Box>
        )}
      </StyledPanel>
      <br />
      <br /> */}
      <Typography variant="h4">Combustion Emissions (B.3.1.1)</Typography>
      <br />
      {!combustionEmissions.data.length && !isNewCombustionVisible && (
        <Typography>No combustion emissions</Typography>
      )}
      {combustionEmissions.data.map((combustionEmission) => (
        <DirectCombustionEmission
          key={combustionEmission.id}
          productionProcessId={id}
          id={combustionEmission.id}
        />
      ))}
      {!isNewCombustionVisible ? (
        <Button
          sx={{ mt: '20px' }}
          onClick={() => setIsNewCombustionVisible(true)}
        >
          Add combustion emissions data
        </Button>
      ) : (
        <DirectCombustionEmission
          productionProcessId={id}
          onSaved={() => setIsNewCombustionVisible(false)}
        />
      )}
      <br />
      <br />
      <Typography variant="h4">Process Emissions (B.3.1.2)</Typography>
      <br />
      {!processEmissions.data.length && !isNewProcessVisible && (
        <Typography>No process emissions</Typography>
      )}
      {processEmissions.data.map((processEmission) => (
        <DirectProcessEmission
          key={processEmission.id}
          productionProcessId={id}
          id={processEmission.id}
        />
      ))}
      <br />
      {!isNewProcessVisible ? (
        <Button
          sx={{ mt: '20px' }}
          onClick={() => setIsNewProcessVisible(true)}
        >
          Add process emissions data
        </Button>
      ) : (
        <DirectProcessEmission
          productionProcessId={id}
          onSaved={() => setIsNewProcessVisible(false)}
        />
      )}
    </>
  );
}
