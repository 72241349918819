import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';

export const StyledDialogContentText = styledMUI(DialogContentText)`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #2F2F2F;
`;

export const StyledDialog = styledMUI(Dialog)`  
  & .MuiPaper-root {
    width: 536px;
    background: #FFFFFF;
    border: 1px solid #D9DFDD;
    border-radius: 16px;
    padding: 50px;
  }
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d9dfdd;
  border-radius: 28px;
  padding: 3px 30px 3px 30px;
  margin-left: 0;
  margin-right: auto;
  cursor: pointer;
  > * {
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #165d5c;
    text-decoration: none;
  }
  &:hover {
    transition: background-color 0.3s ease-in-out;
    background-color: #f9f9f9;
  }
`;

export const NextButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #165d5c;
  border: 2px solid #165d5c;
  border-radius: 28px;
  padding: 3px 30px 3px 30px;
  margin-right: 0;
  margin-left: auto;
  > span,
  > a {
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #ffffff;
    text-decoration: none;
  }
  &:hover {
    transition:
      background-color,
      border 0.3s ease-in-out;
    background-color: #276e6d;
    border: 2px solid #276e6d;
  }
`;
