import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  GeneratingText,
  LoaderSpin,
  SaveIcon,
  StyledDialogContentText,
  TakeTime,
} from '../../../components/RaportPopup/index.styles';

import spin1 from '../../../../images/0-33.png';
import spin2 from '../../../../images/34-65.png';
import spin3 from '../../../../images/66-100.png';

export const Ready = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>{t('raport.report-ghg')}</p>

      <div style={{ position: 'relative', marginLeft: -20 }}>
        <SaveIcon
          width="144"
          height="144"
          viewBox="0 0 144 144"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.623 0H100.123L143.998 43.2V129.6C143.998 137.52 137.417 144 129.373 144H102V129.6H129.373V43.2H98.2949V14.4H41.623V129.6H66V144H41.5499C33.5062 144 26.998 137.52 26.998 129.6L27.0712 14.4C27.0712 6.48 33.5793 0 41.623 0Z"
            fill="#276E6D"
          />
          <rect y="21" width="71" height="39" rx="8" fill="#165D5C" />
          <path
            d="M27.3235 37.9704C27.3235 38.865 27.1182 39.6864 26.7075 40.4344C26.2968 41.1677 25.6662 41.7617 24.8155 42.2164C23.9648 42.671 22.9088 42.8984 21.6475 42.8984H19.3155V48.4424H15.5535V32.9984H21.6475C22.8795 32.9984 23.9208 33.211 24.7715 33.6364C25.6222 34.0617 26.2602 34.6484 26.6855 35.3964C27.1108 36.1444 27.3235 37.0024 27.3235 37.9704ZM21.3615 39.9064C22.0802 39.9064 22.6155 39.7377 22.9675 39.4004C23.3195 39.063 23.4955 38.5864 23.4955 37.9704C23.4955 37.3544 23.3195 36.8777 22.9675 36.5404C22.6155 36.203 22.0802 36.0344 21.3615 36.0344H19.3155V39.9064H21.3615ZM35.728 32.9984C37.356 32.9984 38.7787 33.321 39.996 33.9664C41.2133 34.6117 42.152 35.521 42.812 36.6944C43.4867 37.853 43.824 39.195 43.824 40.7204C43.824 42.231 43.4867 43.573 42.812 44.7464C42.152 45.9197 41.206 46.829 39.974 47.4744C38.7567 48.1197 37.3413 48.4424 35.728 48.4424H29.942V32.9984H35.728ZM35.486 45.1864C36.9087 45.1864 38.016 44.7977 38.808 44.0204C39.6 43.243 39.996 42.143 39.996 40.7204C39.996 39.2977 39.6 38.1904 38.808 37.3984C38.016 36.6064 36.9087 36.2104 35.486 36.2104H33.704V45.1864H35.486ZM56.6404 32.9984V36.0124H50.3484V39.2684H55.0564V42.1944H50.3484V48.4424H46.5864V32.9984H56.6404Z"
            fill="white"
          />
          <path
            className="arrow"
            d="M94.5 61L72.5 61L72.5 87.7692L56 87.7692L83.5 119L111 87.7692L94.5 87.7692L94.5 61Z"
            fill="#165D5C"
          />
        </SaveIcon>
      </div>
    </div>
  );
};
const getImage = (seconds: number) => {
  if (seconds < 33) return spin1;
  else if (seconds > 33 && seconds < 66) return spin2;
  else return spin3;
};

type DownloadCountDownProps = {
  startCount?: number;
};

const DownloadCountDown = ({ startCount = 0 }: DownloadCountDownProps) => {
  const { t } = useTranslation();
  const [count, setCount] = React.useState(startCount);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const steps = [3, 5, 7, 9];
      const newStepIndex = Math.floor(Math.random() * steps.length);
      const newStep = steps[newStepIndex];
      const newCount = count + newStep > 100 ? 100 : count + newStep;
      setCount(newCount);
    }, 300);
    return () => clearTimeout(timeout);
  }, [count]);

  const image = getImage(count);

  return (
    <StyledDialogContentText>
      {count >= 100 ? (
        <div>
          <Ready />
        </div>
      ) : (
        <div>
          <p>{t('raport.progress')}</p>
          <TakeTime>{t('raport.take-time')}</TakeTime>
          <div style={{ position: 'relative' }}>
            <Box>
              {count} <span>%</span>
            </Box>
            <LoaderSpin src={image} alt="" />
          </div>
          <GeneratingText>{t('raport.progress')}</GeneratingText>
        </div>
      )}
    </StyledDialogContentText>
  );
};

export default DownloadCountDown;
