import { Box, Typography } from '@mui/material';
import React from 'react';

import { SPECIFIC_ATTRIBUTED_EMISSIONS_ERROR_THRESHOLD } from 'src/Cbam/utils';
import InfoPanel from 'src/Cbam/components/InfoPanel';

interface EmissionProps {
  type: 'Direct' | 'Indirect';
  emissions: number;
  unit: string;
}

export default function EmissionSummary(props: EmissionProps) {
  const { type, emissions, unit } = props;

  const emissionsString = emissions.toFixed(5);

  const tooHigh = emissions > SPECIFIC_ATTRIBUTED_EMISSIONS_ERROR_THRESHOLD;
  const invalid = emissions < 0;

  return (
    <>
      <Typography variant="h5">
        Specific {type} Emissions: {emissionsString} tCO2/{unit}
      </Typography>
      {tooHigh && (
        <Box sx={{ mt: '12px', mb: '12px' }}>
          <InfoPanel>
            <Typography color="error">
              The Specific {type} Emissions are higher than{' '}
              {SPECIFIC_ATTRIBUTED_EMISSIONS_ERROR_THRESHOLD} tCO2/
              {unit}. Please verify the data, as this value is unusually high.
            </Typography>
          </InfoPanel>
        </Box>
      )}
      {invalid && (
        <Box sx={{ mt: '12px', mb: '12px' }}>
          <InfoPanel>
            <Typography color="error">
              The specific indirect emissions are negative. Please verify the
              data, as this value is invalid.
            </Typography>
          </InfoPanel>
        </Box>
      )}
    </>
  );
}
