import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledFlex } from 'src/components/StyledFlex';
import { StyledPanel } from 'src/components/StyledPanel';
import { useProductionProcesses } from '../hooks';
import ProductionProcessInfoPanel from '../ProductionProcess/Form/components/ProductionProcessInfoPanel';
import Row from './Row';

export default function ProductionProcesses() {
  const navigate = useNavigate();
  const productionProcesses = useProductionProcesses();

  return (
    <Box>
      <StyledFlex>
        <Typography variant="h1">Production Processes</Typography>
        <Button onClick={() => navigate('create')}>Add process</Button>
      </StyledFlex>
      <br />
      <br />
      <ProductionProcessInfoPanel />
      <br />
      <br />
      <StyledPanel>
        <Box
          sx={{
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Production process</TableCell>
                <TableCell>Good type</TableCell>
                <TableCell>Indirect emissions [tCO2/unit]</TableCell>
                <TableCell>Direct emissions [tCO2/unit]</TableCell>
                <TableCell>Survey active</TableCell>
                <TableCell>Survey link</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productionProcesses.data.map((process) => (
                <Row id={process.id} key={process.id} />
              ))}
            </TableBody>
          </Table>
        </Box>
      </StyledPanel>
    </Box>
  );
}
