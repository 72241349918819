import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';
import { StyledFlex } from 'src/components/StyledFlex';
import { StyledPanel, StyledTitle } from 'src/Superadmin/index.styles';
import UsersTable from './UsersTable';
import { ContentProps } from '../index.types';
import { useNavigate } from 'react-router-dom';

export default function Users(props: ContentProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleNewUserClick = () => navigate('/superadmin/users/create-user');

  return (
    <Box sx={{ minWidth: '1000px' }}>
      <StyledFlex>
        <StyledTitle sx={{ fontSize: '28px' }}>
          {t('superadmin.settings.tabs.users.title')}
        </StyledTitle>
        <Button onClick={handleNewUserClick}>
          {t('superadmin.settings.tabs.users.buttons.newUser')}
        </Button>
      </StyledFlex>
      <br />
      <Typography variant="body1">
        <Trans i18nKey="superadmin.settings.tabs.users.subtitle" />
      </Typography>

      <StyledPanel sx={{ mt: '37px', minWidth: '1150px' }}>
        <UsersTable />
      </StyledPanel>
    </Box>
  );
}
