import React from 'react';
import { ProcessModal as Props } from './index.types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'src/Lca/components/Button';

import {
  StyledTitle,
  StyledSectionName,
  StyledTextField,
  StyledSelect,
  StyledRow,
  StyledLine,
} from './index.styles';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from 'src/utils/api-client';
import { LCAProcess } from '../index.types';
import { AxiosError } from 'axios';
import { parseRequestErrors } from 'src/utils/helpers';
import { useLcaDiagram } from 'src/Lca/hooks';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { toast } from 'react-toastify';
import { useLcaProcesses } from '../hooks';

export default function ProcessModal({
  productId,
  open,
  onClose: _onClose,
  process: processInstance,
}: Props) {
  const url = `/web/lca/processes/${productId}`;
  const queryKey = `lca-product-${productId}-processes`;
  const isNew = !processInstance?.id;

  const getProcesses = useQuery(queryKey, () =>
    client.get(url).then((response) => response.data)
  );

  type InputType = string | undefined;
  type InputEvent = React.ChangeEvent<HTMLInputElement>;

  const [name, setName] = React.useState<InputType>('');
  const [process, setProcess] = React.useState<InputType>('');
  const [stage, setStage] = React.useState<InputType>('');
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  const { stagesAsOptions } = useLcaDiagram(productId);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState<boolean>(false);

  const processOptions = getProcesses.data?.filter((process: LCAProcess) => {
    const isTheSameProcess = processInstance?.id === process.id;
    if (!stage) return !isTheSameProcess;
    const isStageSafe = !!stage ? process.stage === stage : false;
    const isSameIdSafe = !isNew ? !isTheSameProcess : true;

    return isStageSafe && isSameIdSafe;
  });

  const handleNameChange = (e: InputEvent) => setName(e.target.value);

  const handleProcessChange = (e: InputEvent) => {
    const { value: newProcess } = e.target;
    setProcess(newProcess);
    const process = processOptions?.find(
      (process: LCAProcess) => String(process.id) === newProcess
    );
    if (process) setStage(process.stage);
  };

  const handleStageChange = (e: InputEvent) => {
    const { value: newStage } = e.target;
    const isProcessInStage = !!processOptions.filter(
      (process: LCAProcess) => process.id === process
    ).length;
    if (!isProcessInStage) setProcess('');
    setStage(newStage);
  };

  const onClose = (e?: any) => {
    e?.preventDefault();
    setName('');
    setProcess('');
    setStage('');
    setErrors({});
    _onClose();
  };

  React.useEffect(() => {
    if (!isNew) {
      setName(String(processInstance?.name));
      setStage(String(processInstance?.stage));
      setProcess(String(processInstance?.parent_process || ''));
    }
  }, []);

  const queryClient = useQueryClient();

  const requestActionsHandlers = {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
      queryClient.invalidateQueries({
        queryKey: ['lca-diagram'],
      });
      setErrors({});
      onClose();
    },
    onError: (error: AxiosError) =>
      setErrors(parseRequestErrors(error?.response?.data)),
  };

  const postProcess = useMutation(
    queryKey,
    (data: { [key: string]: any }) => client.post(url, data),
    requestActionsHandlers
  );

  const putProcess = useMutation(
    queryKey,
    (data: { [key: string]: any }) => {
      return client
        .put(`${url}/${processInstance?.id}`, data)
        .then((res) => res.data);
    },
    requestActionsHandlers
  );

  const deleteProcess = useMutation(
    () => client.delete(`${url}/${processInstance?.id}`),
    requestActionsHandlers
  );

  const handleSubmit = () => {
    const data = {
      name,
      parent_process_id: process || undefined,
      stage,
      product_id: productId,
    };
    if (isNew) {
      postProcess.mutate(data);
    } else {
      putProcess.mutate(data);
    }
  };

  const handleDeleteProcess = () => {
    setDeleteConfirmationOpen(false);
    deleteProcess.mutateAsync().then(() => {
      toast.success('Object removed');
    });
  };

  const { requestCopy } = useLcaProcesses();

  const handleCopyClick = () =>
    !!processInstance?.id && requestCopy.mutate(processInstance.id);

  const isLoading = getProcesses.isLoading || postProcess.isLoading;

  const saveButtonText = isNew ? 'Create' : 'Save changes';

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg">
      <IconButton
        sx={{ position: 'absolute', top: 12, right: 10 }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Typography variant="h3">Add Process</Typography>
      </DialogTitle>

      <DialogContent sx={{ width: 900 }}>
        <StyledSectionName>Process details</StyledSectionName>

        <StyledRow>
          <StyledTextField
            fullWidth
            name="name"
            value={name}
            onChange={handleNameChange}
            disabled={isLoading}
            error={!!errors?.name}
            helperText={errors?.name}
            label="Name"
          />
        </StyledRow>

        <StyledLine />

        <StyledSectionName>Life cycle phases</StyledSectionName>

        <StyledRow>
          <StyledSelect
            fullWidth
            name="stage"
            value={stage}
            onChange={handleStageChange}
            disabled={isLoading}
            error={!!errors.stage}
            label="Life cycle phase"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {stagesAsOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </StyledSelect>

          <div style={{ width: 20 }} />

          <StyledSelect
            fullWidth
            name="process"
            value={process}
            onChange={handleProcessChange}
            disabled={isLoading}
            error={errors.parent_process_id}
            label="Add to the process (optional)"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {processOptions?.map((process: LCAProcess) => (
              <MenuItem key={process.id} value={process.id}>
                {process.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledRow>
        <br />

        {/* ACTION BUTTONS */}

        <StyledRow>
          {!!isNew && (
            <Button color="default" onClick={onClose} isSecondary>
              cancel
            </Button>
          )}

          {!isNew && (
            <StyledRow style={{ padding: 0, justifyContent: 'flex-start' }}>
              <Button
                color="default"
                onClick={() => setDeleteConfirmationOpen(true)}
                isSecondary
              >
                Remove
              </Button>
              <div style={{ width: 10 }} />
              <Button color="default" onClick={handleCopyClick} isSecondary>
                Copy
              </Button>
              <div style={{ width: 10 }} />
              <Button color="default" onClick={onClose} isSecondary>
                Hide
              </Button>
            </StyledRow>
          )}

          <Button color="process" onClick={handleSubmit}>
            {saveButtonText}
          </Button>
        </StyledRow>
      </DialogContent>
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onFalse={() => setDeleteConfirmationOpen(false)}
        onTrue={handleDeleteProcess}
        textKey='If you click "Remove", the selected process and all objects in it will be removed from the flowchart.'
        titleKey="Are you sure you want to remove this process?"
      />
    </Dialog>
  );
}
