import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from './closeIcon';
import { useTranslation } from 'react-i18next';
import {
  ButtonsWrapper,
  Close,
  ModalSubTitle,
  ModalTitle,
} from './index.styles';
import { useMutation, useQueryClient } from 'react-query';
import { client } from '../../../../utils/api-client';
import { Button } from '@mui/material';
import ConfirmationModal from 'src/components/ConfirmationModal';

interface Props {
  report: {
    period: string;
    name: string;
    last_update: string;
    id: string;
  };
  setClose: Function;
  orgId: number | null;
  newName: string;
}

export default function CreateReportModal({
  report,
  setClose,
  orgId,
  newName,
}: Props) {
  const { period, name } = report;
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const nav = useNavigate();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleClose = () => {
    setClose(false);
    setPage(1);
  };

  const report_post = useMutation({
    mutationFn: () => {
      return client.post(
        `web/esg_v2/${orgId}`,
        JSON.stringify({ name: newName, period })
      );
    },
    onSuccess: () => {
      nav(`/esg/reports/${report.id}/indicators`);
      queryClient.invalidateQueries({ queryKey: ['esg-reports'] });
      setClose(false);
      setPage(1);
    },
  });

  const report_delete = useMutation({
    mutationFn: () => {
      return client.delete(`web/esg_v2/${orgId}/${report.id}`);
    },
    onSuccess: () => report_post.mutate(),
  });

  return (
    <div>
      <Close type="button" onClick={handleClose}>
        <CloseIcon />
      </Close>
      {page === 1 ? (
        <>
          <ModalTitle>{t('esg.modal.1.title')}</ModalTitle>
          <ModalSubTitle>{t('esg.modal.1.subtitle')}</ModalSubTitle>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonsWrapper>
              <Button
                size="large"
                onClick={() => navigate(`/esg/reports/${report.id}/indicators`)}
              >
                {t('esg.modal.1.buttons.open')}
              </Button>

              <Button
                size="large"
                variant="secondary"
                onClick={() => setPage(2)}
              >
                {t('esg.modal.1.buttons.create')}
              </Button>
            </ButtonsWrapper>
          </div>
        </>
      ) : (
        <ConfirmationModal
          open
          onClose={handleClose}
          onFalse={handleClose}
          textKey="esg.modal.2.subtitle"
          titleKey="esg.modal.2.title"
          onTrue={() => report_delete.mutate()}
        />
      )}
    </div>
  );
}
