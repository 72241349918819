import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';

import { useProductionProcesses, useSectorSpecific } from 'src/Cbam/hooks';

import {
  AggregatedGoodsCategory,
  PigIronReducingAgents,
  SectorSpecificSchema,
  SteelReducingAgents,
} from 'src/Cbam/types';
import RadioField from '../components/RadioField';
import {
  StyledFormControl,
  StyledNumberField,
  StyledTextField,
} from '../../../styles';
import { useChangeTracker } from '../components/CounterProvider';
import InfoPanel from 'src/Cbam/components/InfoPanel';
import {
  ProductionMethodNamesMap,
  ProductionMethodsForAggregatedGoodsCategory,
} from 'src/Cbam/productionMethods';
import {
  QualifyingParameterFormFieldsMap,
  QualifyingParameterLabelsMap,
  QualifyingParameterNamesMap,
  QualifyingParameters,
  QualifyingParametersForAggregatedGoodsCategory,
} from 'src/Cbam/qualifyingParameters';

interface Props {
  id: number;
  aggregatedGoodsCategory: AggregatedGoodsCategory | null;
}

const initialForm = {
  production_process_id: 0,
  production_method: null,
  calcinated_clay: null,
  cement_mass_ratio: null,
  urea_mass_contained: null,
  nitric_acid_concentration: null,
  ammonia_concentration: null,

  mixed_fertilisers_content_of_n_as_ammonium: null,
  mixed_fertilisers_content_of_n_as_nitrate: null,
  mixed_fertilisers_content_of_n_as_urea: null,
  mixed_fertilisers_content_of_n_in_other_forms: null,

  pig_iron_main_reducing_agent: null,
  crude_steel_main_reducing_agent: null,

  carbon_content: null,
  manganese_content: null,
  chromium_content: null,
  nickel_content: null,
  content_of_other_alloy_elements: null,

  tonnes_of_scrap: null,
  pct_of_pre_consumer_scrap: null,
  total_pct_of_other_elements: null,

  electricity_fulfillment_of_conditionality: null,
};

export default function SectorSpecific(props: Props) {
  const { id, aggregatedGoodsCategory } = props;
  const { isModified, setIsModified } = useChangeTracker();
  const productionProcesses = useProductionProcesses();
  const productionProcess = productionProcesses.data.find(
    (p) => p.id === Number(id)
  );
  const sectorSpecific = useSectorSpecific(Number(id));
  const [form, setForm] = React.useState<SectorSpecificSchema>(initialForm);

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  React.useEffect(() => {
    if (sectorSpecific.data) setForm(sectorSpecific.data);
  }, [sectorSpecific.data]);

  const handleSaveClick = () =>
    sectorSpecific.update.mutateAsync(form).then(() => setIsModified(false));

  if (!productionProcess) return null;

  const nonEmpty = [
    AggregatedGoodsCategory.Electricity,
    AggregatedGoodsCategory.CalcinedClay,
    AggregatedGoodsCategory.Cement,
    AggregatedGoodsCategory.Urea,
    AggregatedGoodsCategory.NitricAcid,
    AggregatedGoodsCategory.Ammonia,
    AggregatedGoodsCategory.MixedFertilisers,
    AggregatedGoodsCategory.PigIron,
    AggregatedGoodsCategory.FeMn,
    AggregatedGoodsCategory.FeCr,
    AggregatedGoodsCategory.FeNi,
    AggregatedGoodsCategory.DRI,
    AggregatedGoodsCategory.CrudeSteel,
    AggregatedGoodsCategory.IronOrSteelProducts,
    AggregatedGoodsCategory.UnwroughtAluminium,
    AggregatedGoodsCategory.AluminiumProducts,
    AggregatedGoodsCategory.Hydrogen,
  ];

  const productionMethods = aggregatedGoodsCategory
    ? ProductionMethodsForAggregatedGoodsCategory[aggregatedGoodsCategory]
    : [];

  const qualifyingParameters = aggregatedGoodsCategory
    ? QualifyingParametersForAggregatedGoodsCategory[aggregatedGoodsCategory]
    : [];

  if (!aggregatedGoodsCategory || !nonEmpty.includes(aggregatedGoodsCategory))
    return null;

  return (
    <>
      <br />
      <Typography variant="h3">
        Sector Specific ({aggregatedGoodsCategory})
      </Typography>
      <br />

      {aggregatedGoodsCategory === AggregatedGoodsCategory.Electricity && (
        <>
          <InfoPanel>
            <Typography variant="h4">
              ANNEX IV TO REGULATION (EU) 2023/956
            </Typography>
            <Typography variant="h5">…</Typography>
            <Typography variant="h5">
              5. CONDITIONS FOR APPLYING ACTUAL EMBEDDED EMISSIONS IN IMPORTED
              ELECTRICITY
            </Typography>
            <Typography>
              An authorised CBAM declarant may apply actual embedded emissions
              instead of default values for the calculation referred to in
              Article 7(3) if the following cumulative criteria are met:
            </Typography>
            <ul>
              <li>
                <Typography>
                  (a) the amount of electricity for which the use of actual
                  embedded emissions is claimed is covered by a power purchase
                  agreement between the authorised CBAM declarant and a producer
                  of electricity located in a third country;
                </Typography>
              </li>
              <li>
                <Typography>
                  (b) the installation producing electricity is either directly
                  connected to the Union transmission system or it can be
                  demonstrated that at the time of export there was no physical
                  network congestion at any point in the network between the
                  installation and the Union transmission system;
                </Typography>
              </li>
              <li>
                <Typography>
                  (c) the installation producing electricity does not emit more
                  than 550 grammes of CO2 of fossil fuel origin per
                  kilowatt-hour of electricity;
                </Typography>
              </li>
              <li>
                <Typography>
                  (d) the amount of electricity for which the use of actual
                  embedded emissions is claimed has been firmly nominated to the
                  allocated interconnection capacity by all responsible
                  transmission system operators in the country of origin, the
                  country of destination and, if relevant, each country of
                  transit, and the nominated capacity and the production of
                  electricity by the installation refer to the same period of
                  time, which shall not be longer than one hour;
                </Typography>
              </li>
            </ul>
          </InfoPanel>
          <br />
        </>
      )}

      <StyledPanel>
        {aggregatedGoodsCategory === AggregatedGoodsCategory.CalcinedClay && (
          <>
            <Typography variant="h5">
              Whether or not the clay is calcined.
            </Typography>
            <RadioField
              label="The clay is calcinated"
              checked={form.calcinated_clay === true}
              onClick={() => handleFormChange('calcinated_clay', true)}
            />
            <br />
            <RadioField
              label="The clay is not calcinated"
              checked={form.calcinated_clay === false}
              onClick={() => handleFormChange('calcinated_clay', false)}
            />
          </>
        )}
        {(aggregatedGoodsCategory === AggregatedGoodsCategory.PigIron ||
          aggregatedGoodsCategory === AggregatedGoodsCategory.DRI) && (
          <>
            <Typography variant="h5">The main reducing agent used:</Typography>
            <StyledFormControl>
              <InputLabel id="pig-iron-main-reducing-agent">
                Main reducing agent
              </InputLabel>
              <Select
                key={form.pig_iron_main_reducing_agent}
                labelId="pig-iron-main-reducing-agent"
                label="Main reducing agent"
                value={form.pig_iron_main_reducing_agent}
                onChange={(e) =>
                  handleFormChange(
                    'pig_iron_main_reducing_agent',
                    e.target.value
                  )
                }
              >
                {Object.values(PigIronReducingAgents).map((agent) => (
                  <MenuItem key={agent} value={agent}>
                    {agent}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </>
        )}
        {(aggregatedGoodsCategory === AggregatedGoodsCategory.CrudeSteel ||
          aggregatedGoodsCategory ===
            AggregatedGoodsCategory.IronOrSteelProducts) && (
          <>
            <Typography variant="h5">The main reducing agent used:</Typography>
            <StyledFormControl>
              <InputLabel id="crude-steel-main-reducing-agent">
                Main reducing agent
              </InputLabel>
              <Select
                key={form.crude_steel_main_reducing_agent}
                labelId="crude-steel-main-reducing-agent"
                label="Main reducing agent"
                value={form.crude_steel_main_reducing_agent}
                onChange={(e) =>
                  handleFormChange(
                    'crude_steel_main_reducing_agent',
                    e.target.value
                  )
                }
              >
                {Object.values(SteelReducingAgents).map((agent) => (
                  <MenuItem key={agent} value={agent}>
                    {agent}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </>
        )}
        {qualifyingParameters.map((param: QualifyingParameters) => (
          <Box key={param}>
            <Typography variant="h5">
              {QualifyingParameterNamesMap?.[param]}
            </Typography>
            <StyledNumberField
              label={QualifyingParameterLabelsMap?.[param]}
              value={form[QualifyingParameterFormFieldsMap?.[param]]}
              onChange={(value) =>
                handleFormChange(
                  QualifyingParameterFormFieldsMap?.[param],
                  value
                )
              }
            />
            <br />
          </Box>
        ))}

        {aggregatedGoodsCategory === AggregatedGoodsCategory.Electricity && (
          <>
            <Typography>
              Please provide information confirming that the criteria (a) to (d)
              from Section 5 of Annex IV to Regulation (EU) 2023/956 are
              fulfilled.
            </Typography>
            <StyledTextField
              label="Fullfilment of conditionality"
              value={form.electricity_fulfillment_of_conditionality || ''}
              onChange={(e) =>
                handleFormChange(
                  'electricity_fulfillment_of_conditionality',
                  e.target.value
                )
              }
              multiline
              rows={3}
            />
          </>
        )}

        {productionMethods.length > 0 && (
          <>
            <Typography variant="h5">Production method:</Typography>
            {productionMethods.map((method) => (
              <>
                <RadioField
                  key={method}
                  label={ProductionMethodNamesMap[method]}
                  checked={form.production_method === method}
                  onClick={() => handleFormChange('production_method', method)}
                />
                <br />
              </>
            ))}
          </>
        )}

        {isModified && (
          <Box sx={{ width: '100%', textAlign: 'right', mt: '12px' }}>
            <Button
              color="primary"
              onClick={handleSaveClick}
              sx={{ minWidth: '200px' }}
            >
              Save
            </Button>
          </Box>
        )}
      </StyledPanel>
    </>
  );
}
