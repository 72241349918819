/* eslint-disable no-nested-ternary */

import Button from '@mui/material/Button';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import { styled as styledMUI } from '@mui/material/styles';
import { StyledButtonProps } from './index.interfaces';

export const StyledButton = styledMUI(Button)<StyledButtonProps>(
  ({ selected, disabled }) => `
    border: 1px solid ${selected ? '#165D5C' : '#B6BBB9'};
    border-radius: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: ${selected ? 'white' : '#132C1D'};
    background-color: ${
      disabled
        ? selected
          ? '#aaaaaa80'
          : '#ffffff'
        : selected
          ? '#165D5C'
          : '#ffffff'
    };
    text-transform: none;
    padding: 18px 0;
    `
);

export const StyledButtonGroup = styledMUI(ButtonGroup)<ButtonGroupProps>`
    width: 100%;
    & .MuiButtonGroup-grouped:hover {
      color: #132C1D;
      border: 1px solid #165D5C;
      background-color: #165D5C20;
    }
`;
