import styled from 'styled-components';

export const Box = styled.div`
  border-radius: 16px;
  border: 1px solid var(--stroke-pressed-button, #ddd);
  background: var(--pure-white, #fff);
  padding: 24px;
  margin-bottom: 24px;
`;

export const Close = styled.button`
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
`;

export const ModalTitle = styled.p`
  color: #38414f;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.48px;
  margin-bottom: 30px;
`;

export const ModalSubTitle = styled.p`
  color: #38414f;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.36px;
  margin-bottom: 35px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
