import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export
export const ProductItem = styled(Link)`
  height: 57px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--icon-button-hover-dark, rgba(0, 0, 0, 0.04));
  color: var(--dark, #5c5c5c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.28px;
  text-decoration: none;
  margin: 0px -24px;
  padding: 0px 35px 0px 25px;
  svg {
    margin-right: 12px;
  }
  &:hover {
    background-color: #f5f5f5;
  }
`;
