import themeColors from './colors';

const dialogTitleTypography = {
  // Figma's <h3> as of 2024-06-06
  fontSize: '24px',
  fontWeight: 600, // semibold
  lineHeight: '34px',
  letterSpacing: '0.48px',
};

const dialogContentTypography = {
  // Figma's <p> as of 2024-06-06
  fontSize: '16px',
  fontWeight: 400, // normal
  lineHeight: '26px',
  letterSpacing: '0.48px',
};

const muiDialogPadding = '24px';

const dialogTheme = {
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: `1px solid ${themeColors.strokePressed}`,
          background: themeColors.pureWhite,
          padding: muiDialogPadding,
          zIndex: 1,
          minWidth: '600px',
          borderRadius: '16px',
        },
        root: {
          backdropFilter: 'blur(2px) sepia(5%)',
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        // work only with defaultProps
        align: 'left',
        p: {
          '& .MuiTypography-root': {
            // use overrided Typography directly?
            ...dialogTitleTypography,
          },
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        align: 'left',

        // apply dialogContent typography for default variant of Typography when it is used inside DialogContent
        '& .MuiTypography-body1': dialogContentTypography,
      },
      styleOverrides: {
        root: {
          paddingLeft: muiDialogPadding,
          paddingRight: muiDialogPadding,
          paddingTop: '24px !important', // text field getting padding-top: 0; this cuts off the label
          paddingBottom: muiDialogPadding,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: muiDialogPadding,
        },
      },
    },
  },
};

export default dialogTheme;
