const themeColors = {
  grayIcons: '#606462',
  greenLogotype: '#165D5C',
  greenBrand: '#165D5C',
  greenHover: '#276E6D',
  greenPressed: '#58A497',
  redParagraphs: '#AD5356',
  redGraphics: '#CF6367',
  grayInactive: '#B6BBB9',
  strokePressed: '#ddd',
  hoverButton: '#F2F2F2',
  background: '#F9F9F9',
  pureWhite: '#fff',
  greenCalendarLight: '#B2E5DD',
  greenCalendar: '#8AC8BE',

  // Global
  error: '#A03137',
  hover: 'rgba(0, 0, 0, 0.04)',
  pressed: 'rgba(0, 0, 0, 0.08)',
  disabledLabel: '#BBBBBB',
  disabledGraphics: '#CCCCCC',
  placeholder: '#6E6E6E',
  strokeInputs: '#9E9E9E',
  backgroundInput: '#F4F4F4',
  footer: '#EAEAEA',

  dark: '#2F2F2F',
  darkGrey: '#6E6E6E',

  // ESG  Colors
  esgMain: '#38414F',
  esgMainDark: '#27303E',
  esgMainLight: '#49525F',
  esgMainLighter: '#5A636F',

  // ESG text colors
  esgDark: '#38414F',
  esgDarkGrey: '#6E6E6E',

  // LCA Colors
  lcaMain: '#7E4308',
  lcaMainDark: '#6D3207',
  lcaMainLight: '#8F5409',
  lcaMainLighter: '#9F652A',

  lcaVeryDark: '#4B1004',

  // LCA text colors
  lcaDark: '#281302',
  lcaDarkGrey: '#6E6E6E',

  // GHG Colors
  ghgMain: '#165d5c',
  ghgMainDark: '#166054',
  ghgMainLight: '#276e6d',
  ghgMainLighter: '#387f7e',

  // GHG new text colors
  ghgDark: '#2f2f2f',
  ghgDarkGrey: '#606462',

  // CBAM Colors
  cbamMain: '#D3B9E9',
  cbamMainDark: '#C2A8D8',
  cbamMainLight: '#E4CAFA',
  cbamMainLighter: '#F5DBFB',

  // CBAM text colors
  cbamDark: '#2E3342',
  cbamDarkGrey: '#6E6E6E',
};

export default themeColors;
