import React from 'react';

import {
  Box,
  Button,
  SelectChangeEvent,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import {
  Member,
  MemberRole,
  OrganizationMember,
  UpdateOrganizationMemberSchema,
} from 'src/Superadmin/index.types';
import PermissionSelect from '../components/PermissionSelect';
import OrganizationSelect from '../components/OrganizationSelect';
import { useOrganizationMember } from './hooks';
import { StyledFlex } from 'src/components/StyledFlex';
import { ProductPlatform } from 'src/utils/api.interfaces';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledActionCell, StyledSelectCell } from './styles';
import ActionMenu from './ActionMenu';

interface Props {
  renderUserColumn: boolean;
  member: Member;
  organizationMember: OrganizationMember;
}
export default function Row(props: Props) {
  const { t } = useTranslation();
  const { member, organizationMember, renderUserColumn } = props;
  const navigate = useNavigate();

  const [role, setRole] = React.useState<MemberRole>(organizationMember.role);
  const [organization, setOrganization] = React.useState<number>(
    organizationMember.organization_id
  );
  const { organizations, updateOrganizationMember } = useOrganizationMember(
    member.id
  );

  const updateMember = (extras: Partial<UpdateOrganizationMemberSchema>) =>
    updateOrganizationMember.mutate({
      ...organizationMember,
      organizationId: organizationMember.organization_id,
      ...extras,
    });

  const organizationMembers = member.organizations || [];
  const _existingOrgIds = organizationMembers.map((om) => om.organization_id);
  const organizationOptions = organizations.filter(
    (org) =>
      !_existingOrgIds.includes(org.id) ||
      org.id === organizationMember.organization_id
  );

  const fullName =
    member.first_name || member.last_name
      ? `${member.first_name} ${member.last_name}`
      : member.email;

  const handleRoleChange = (e: SelectChangeEvent<any>) => {
    const newRole = e.target.value as MemberRole;
    setRole(newRole);
    updateMember({ role: newRole });
  };

  const handleOrganizationChange = (e: SelectChangeEvent<any>) => {
    const newOrganization = parseInt(e.target.value, 10);
    setOrganization(newOrganization);
    updateMember({ organizationId: newOrganization });
  };

  const handleModuleChange = (product: ProductPlatform) => {
    const exists = organizationMember.products.includes(product);

    const newModules = exists
      ? organizationMember.products.filter((p) => p !== product)
      : [...organizationMember.products, product];

    updateMember({ products: newModules });
  };

  return (
    <TableRow sx={{ p: 0 }}>
      {renderUserColumn && (
        <TableCell
          sx={{ verticalAlign: 'top', minWidth: '400px', p: '8px' }}
          rowSpan={member.organizations.length + 1}
        >
          <StyledFlex sx={{ alignItems: 'flex-start' }}>
            <Box>
              <Typography variant="h6">{fullName}</Typography>
              <Typography variant="captionText" component="div">
                {member.email}
              </Typography>
            </Box>
            <Button
              size="small"
              variant="primary"
              onClick={() => navigate(`/superadmin/users/${member.id}`)}
            >
              {t(
                'superadmin.settings.tabs.users.usersTable.permissions.detailedPermissions'
              )}
            </Button>
          </StyledFlex>
        </TableCell>
      )}

      <StyledSelectCell sx={{ borderBottom: 'none' }}>
        <PermissionSelect
          key={organizationMember.id}
          value={role}
          onChange={handleRoleChange}
        />
      </StyledSelectCell>
      <StyledSelectCell sx={{ borderBottom: 'none' }}>
        <OrganizationSelect
          organizations={organizationOptions}
          value={organization}
          onChange={handleOrganizationChange}
        />
      </StyledSelectCell>
      <StyledActionCell sx={{ borderBottom: 'none' }}>
        <Switch
          checked={organizationMember.products.includes(ProductPlatform.Co2)}
          onClick={() => handleModuleChange(ProductPlatform.Co2)}
        />
      </StyledActionCell>
      <StyledActionCell sx={{ borderBottom: 'none' }}>
        <Switch
          checked={organizationMember.products.includes(ProductPlatform.Esg)}
          onClick={() => handleModuleChange(ProductPlatform.Esg)}
        />
      </StyledActionCell>
      <StyledActionCell sx={{ borderBottom: 'none' }}>
        <Switch
          checked={organizationMember.products.includes(ProductPlatform.Lca)}
          onClick={() => handleModuleChange(ProductPlatform.Lca)}
        />
      </StyledActionCell>
      <StyledActionCell sx={{ borderBottom: 'none' }}>
        <ActionMenu member={member} organizationMember={organizationMember} />
      </StyledActionCell>
    </TableRow>
  );
}
