/* eslint-disable no-unused-vars */
import { AggregatedGoodsCategory, SectorSpecificSchema } from './types';

export enum QualifyingParameters {
  QPD01 = 'QPD01',
  QPD02 = 'QPD02',
  QPD03 = 'QPD03',
  QPD04 = 'QPD04',
  QPD05 = 'QPD05',
  QPD06 = 'QPD06',
  QPD07 = 'QPD07',
  QPD08 = 'QPD08',
  QPD09 = 'QPD09',
  QPD10 = 'QPD10',
  QPD11 = 'QPD11',
  QPD12 = 'QPD12',
  QPD13 = 'QPD13',
  QPD14 = 'QPD14',
  QPD15 = 'QPD15',
  QPD16 = 'QPD16',
  QPD17 = 'QPD17',
  QPD18 = 'QPD18',
  QPD19 = 'QPD19',
  QPD20 = 'QPD20',
  QPD21 = 'QPD21',
  QPD22 = 'QPD22',
  QPD23 = 'QPD23',
}

export const QualifyingParameterNamesMap: {
  [_key in QualifyingParameters]: string;
} = {
  [QualifyingParameters.QPD01]: '',
  [QualifyingParameters.QPD02]:
    'Mass ratio of tonnes cement clinker consumed per produced tonne of cement (clinker to cement ratio expressed in per cent).',
  [QualifyingParameters.QPD03]:
    'Purity (mass % urea contained, % N contained).',
  [QualifyingParameters.QPD04]: 'Concentration (mass %).',
  [QualifyingParameters.QPD05]: 'Concentration, if hydrous solution.',
  [QualifyingParameters.QPD06]:
    'Mass % nitrogen bound in urea (related to fertiliser or fertiliser solution as is, NOT on dry basis).',
  [QualifyingParameters.QPD07]:
    'Mass % nitrogen bound in ammonium (NH4+), related to fertiliser or fertiliser solution as is, NOT on dry basis).',
  [QualifyingParameters.QPD08]:
    'Mass % nitrogen bound in nitrate (NO3–), related to fertiliser or fertiliser solution as is, NOT on dry basis).',
  [QualifyingParameters.QPD09]:
    'Mass % nitrogen bound in other (organic) forms, related to fertiliser or fertiliser solution as is, NOT on dry basis).',
  [QualifyingParameters.QPD10]: '',
  [QualifyingParameters.QPD11]: '',
  [QualifyingParameters.QPD12]:
    'Mass % carbon contained in the iron, alloy or steel under consideration.',
  [QualifyingParameters.QPD13]:
    'Mass % manganese contained in the iron, alloy or steel under consideration.',
  [QualifyingParameters.QPD14]:
    'Mass % chromium contained in the iron, alloy or steel under consideration.',
  [QualifyingParameters.QPD15]:
    'Mass % nickel contained in the iron, alloy or steel under consideration.',
  [QualifyingParameters.QPD16]:
    'Total mass % of all alloy elements other than C, Mn, Cr and Ni.',
  [QualifyingParameters.QPD17]:
    'Tonnes scrap used for producing 1 t crude steel (total scrap including post-consumer scrap).',
  [QualifyingParameters.QPD18]:
    'Percentage of scrap used that is pre-consumer scrap.',
  [QualifyingParameters.QPD19]:
    'Tonnes scrap used for producing 1 t of the product (total scrap including post-consumer scrap).',
  [QualifyingParameters.QPD20]:
    'Mass % of materials contained which are not iron or steel if their mass is more than 1% but less than 5% of the total goods’ mass.',
  [QualifyingParameters.QPD21]:
    'Tonnes scrap used for producing 1 t of the product (total scrap including post-consumer scrap).',
  [QualifyingParameters.QPD22]:
    'Percentage of scrap used that is pre-consumer scrap.',
  [QualifyingParameters.QPD23]:
    'Mass % of materials contained which are not aluminium if their mass is more than 1% but less than 5% of the total goods’ mass.',
};

export const QualifyingParameterLabelsMap: {
  [_key in QualifyingParameters]: string;
} = {
  [QualifyingParameters.QPD01]: '',
  [QualifyingParameters.QPD02]: 'Mass ratio [%]',
  [QualifyingParameters.QPD03]: 'Mass contained [%]',
  [QualifyingParameters.QPD04]: 'Concentration [%]',
  [QualifyingParameters.QPD05]: 'Concentration [%]',
  [QualifyingParameters.QPD06]: 'Content of N as urea [%]',
  [QualifyingParameters.QPD07]: 'Content of N as ammonium [%]',
  [QualifyingParameters.QPD08]: 'Content of N as nitrate [%]',
  [QualifyingParameters.QPD09]: 'Content of N in other forms [%]',
  [QualifyingParameters.QPD10]: '',
  [QualifyingParameters.QPD11]: '',
  [QualifyingParameters.QPD12]: 'Content of carbon [%]',
  [QualifyingParameters.QPD13]: 'Content of Mn [%]',
  [QualifyingParameters.QPD14]: 'Content of Cr [%]',
  [QualifyingParameters.QPD15]: 'Content of Ni [%]',
  [QualifyingParameters.QPD16]: 'Content if other alloy elements [%]',
  [QualifyingParameters.QPD17]: 'Tonnes of scrap [tonne]',
  [QualifyingParameters.QPD18]: 'Percentage of pre-consumer scrap [%]',
  [QualifyingParameters.QPD19]: 'Tonnes of scrap [tonne]',
  [QualifyingParameters.QPD20]: 'Mass % of non-iron/steel materials [%]',
  [QualifyingParameters.QPD21]: 'Tonnes of scrap [tonne]',
  [QualifyingParameters.QPD22]: 'Percentage of pre-consumer scrap [%]',
  [QualifyingParameters.QPD23]: 'Total percentage of other elements [%]',
};

export const QualifyingParameterFormFieldsMap: {
  [_key in QualifyingParameters]: keyof SectorSpecificSchema;
} = {
  [QualifyingParameters.QPD01]: 'calcinated_clay',
  [QualifyingParameters.QPD02]: 'cement_mass_ratio',
  [QualifyingParameters.QPD03]: 'urea_mass_contained',
  [QualifyingParameters.QPD04]: 'nitric_acid_concentration',
  [QualifyingParameters.QPD05]: 'ammonia_concentration',
  [QualifyingParameters.QPD06]: 'mixed_fertilisers_content_of_n_as_urea',
  [QualifyingParameters.QPD07]: 'mixed_fertilisers_content_of_n_as_ammonium',
  [QualifyingParameters.QPD08]: 'mixed_fertilisers_content_of_n_as_nitrate',
  [QualifyingParameters.QPD09]: 'mixed_fertilisers_content_of_n_in_other_forms',
  [QualifyingParameters.QPD10]: 'pig_iron_main_reducing_agent',
  [QualifyingParameters.QPD11]: 'crude_steel_main_reducing_agent',
  [QualifyingParameters.QPD12]: 'carbon_content',
  [QualifyingParameters.QPD13]: 'manganese_content',
  [QualifyingParameters.QPD14]: 'chromium_content',
  [QualifyingParameters.QPD15]: 'nickel_content',
  [QualifyingParameters.QPD16]: 'content_of_other_alloy_elements',
  [QualifyingParameters.QPD17]: 'tonnes_of_scrap',
  [QualifyingParameters.QPD18]: 'pct_of_pre_consumer_scrap',
  [QualifyingParameters.QPD19]: 'tonnes_of_scrap',
  [QualifyingParameters.QPD20]: 'total_pct_of_other_elements',
  [QualifyingParameters.QPD21]: 'tonnes_of_scrap',
  [QualifyingParameters.QPD22]: 'pct_of_pre_consumer_scrap',
  [QualifyingParameters.QPD23]: 'total_pct_of_other_elements',
};

// QPD01, QPD10 and QPD11 are Selects, so we handle them differently
export const QualifyingParametersForAggregatedGoodsCategory: {
  [_key in AggregatedGoodsCategory]: QualifyingParameters[];
} = {
  [AggregatedGoodsCategory.CementClinker]: [],
  [AggregatedGoodsCategory.AluminousCement]: [],
  [AggregatedGoodsCategory.Electricity]: [],
  [AggregatedGoodsCategory.SinteredOre]: [],
  [AggregatedGoodsCategory.Hydrogen]: [],
  [AggregatedGoodsCategory.CalcinedClay]: [
    // QualifyingParameters.QPD01
  ],
  [AggregatedGoodsCategory.Cement]: [QualifyingParameters.QPD02],
  [AggregatedGoodsCategory.NitricAcid]: [QualifyingParameters.QPD04],
  [AggregatedGoodsCategory.Urea]: [QualifyingParameters.QPD03],
  [AggregatedGoodsCategory.Ammonia]: [QualifyingParameters.QPD05],
  [AggregatedGoodsCategory.MixedFertilisers]: [
    QualifyingParameters.QPD06,
    QualifyingParameters.QPD07,
    QualifyingParameters.QPD08,
    QualifyingParameters.QPD09,
  ],
  [AggregatedGoodsCategory.PigIron]: [
    // QualifyingParameters.QPD10,
    QualifyingParameters.QPD13,
    QualifyingParameters.QPD14,
    QualifyingParameters.QPD15,
    QualifyingParameters.QPD16,
  ],
  [AggregatedGoodsCategory.DRI]: [
    // QualifyingParameters.QPD10,
    QualifyingParameters.QPD13,
    QualifyingParameters.QPD14,
    QualifyingParameters.QPD15,
    QualifyingParameters.QPD16,
  ],
  [AggregatedGoodsCategory.FeMn]: [
    QualifyingParameters.QPD12,
    QualifyingParameters.QPD13,
  ],
  [AggregatedGoodsCategory.FeCr]: [
    QualifyingParameters.QPD12,
    QualifyingParameters.QPD14,
  ],
  [AggregatedGoodsCategory.FeNi]: [
    QualifyingParameters.QPD12,
    QualifyingParameters.QPD15,
  ],
  [AggregatedGoodsCategory.CrudeSteel]: [
    // QualifyingParameters.QPD11,
    QualifyingParameters.QPD13,
    QualifyingParameters.QPD14,
    QualifyingParameters.QPD15,
    QualifyingParameters.QPD16,
    QualifyingParameters.QPD17,
    QualifyingParameters.QPD18,
  ],
  [AggregatedGoodsCategory.IronOrSteelProducts]: [
    // QualifyingParameters.QPD11,
    QualifyingParameters.QPD13,
    QualifyingParameters.QPD14,
    QualifyingParameters.QPD15,
    QualifyingParameters.QPD16,
    QualifyingParameters.QPD18,
    QualifyingParameters.QPD19,
    QualifyingParameters.QPD20,
  ],
  [AggregatedGoodsCategory.UnwroughtAluminium]: [
    QualifyingParameters.QPD21,
    QualifyingParameters.QPD22,
    QualifyingParameters.QPD23,
  ],
  [AggregatedGoodsCategory.AluminiumProducts]: [
    QualifyingParameters.QPD21,
    QualifyingParameters.QPD22,
    QualifyingParameters.QPD23,
  ],
};
