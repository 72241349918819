import { styled as styledMUI } from '@mui/material/styles';
import { DialogContent } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const StyledDialogContent = styledMUI(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  height: 500,
  alignItems: 'center',
  justifyContent: 'center',
  '& >div': {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
