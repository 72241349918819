import { createTheme } from '@mui/material';
import themeColors from '../theme/colors';
import { deepmerge } from '@mui/utils';
import theme from '../theme';

const ghgTheme = createTheme(
  deepmerge(theme, {
    palette: {
      text: {
        primary: themeColors.ghgDark,
        secondary: themeColors.ghgDarkGrey,
      },
      main: themeColors.ghgMain,
      mainDark: themeColors.ghgMainDark,
      mainLight: themeColors.ghgMainLight,
      mainLighter: themeColors.ghgMainLighter,
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(2px) sepia(5%)',
            '& .MuiPaper-root': {
              borderRadius: '16px',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '0 18px',
            textTransform: 'none',
            ':hover': {
              backgroundColor: themeColors.greenHover,
            },
            img: {
              width: '20px',
              height: '20px',
            },
          },
          primary: {
            fontWeight: 700,
            backgroundColor: themeColors.ghgMain,
            color: themeColors.pureWhite,
            '&:hover': {
              backgroundColor: themeColors.ghgMainLight,
            },
            '&:pressed': {
              backgroundColor: themeColors.ghgMainLighter,
            },
          },
          secondary: {
            color: themeColors.ghgMain,
            border: `1px solid ${themeColors.disabledGraphics}`,
            backgroundColor: themeColors.pureWhite,
            '&:hover': {
              backgroundColor: themeColors.hover,
            },
            '&:pressed': {
              backgroundColor: themeColors.pressed,
            },
            '&:inactive': {
              backgroundColor: themeColors.disabledGraphics,
              color: themeColors.disabledLabel,
            },
          },
          sizeSmall: {
            height: '32px',
            fontSize: '13px',
          },
          sizeMedium: {
            height: '40px',
            fontSize: '15px',
          },
          sizeLarge: {
            height: '48px',
            fontSize: '16px',
          },
          textSecondary: {
            color: themeColors.esgDark,
            border: `1px solid ${themeColors.strokeInputs}`,
            ':hover': {
              color: themeColors.pureWhite,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              '&.Black': {
                borderColor: 'black',
              },
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '28px',
            letterSpacing: '0.54px',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: '24px',
            '&:hover': {
              backgroundColor: themeColors.ghgMainLight,
            },
            '&.active': {
              backgroundColor: themeColors.pureWhite,
              color: themeColors.ghgDark,
            },
          },
        },
      },
    },
  })
);

export default ghgTheme;
