import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import { StyledNumberField } from '../../styles';
import { useHeat } from 'src/Cbam/hooks';
import {
  CbamHeatSchema,
  HeatExportMeasurableHeat,
  HeatFactorSource,
  HeatSourceOfUtilizedHeat,
} from 'src/Cbam/types';
import RadioField from './components/RadioField';
import { useTranslation } from 'react-i18next';
import { useChangeTracker } from './components/CounterProvider';
import InfoPanel from 'src/Cbam/components/InfoPanel';

interface Props {
  id: number;
}

export default function Heat(props: Props) {
  const { id } = props;
  const { isModified, setIsModified } = useChangeTracker();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.productionProcess.heat',
  });
  const heat = useHeat(Number(id));

  const initialForm = {
    production_process_id: id,
    source_of_utilized_heat: HeatSourceOfUtilizedHeat.NO_HEAT,
    export_measurable_heat: HeatExportMeasurableHeat.NO_HEAT_IS_EXPORTED,
    imported_heat: null,
    exported_heat: null,
    heat_factor_source: HeatFactorSource.C21_C22,
    heat_emission_factor: null,
  };

  const [form, setForm] = React.useState<CbamHeatSchema>(initialForm);

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  React.useEffect(() => {
    if (heat.data) setForm(heat.data);
  }, [heat.data]);

  const handleSaveClick = () =>
    heat.update.mutateAsync(form).then(() => {
      setIsModified(false);
    });

  return (
    <>
      <Typography variant="h3">Measurable Heat</Typography>
      <br />
      <StyledPanel>
        <Typography variant="h5">Source of utilized heat:</Typography>
        {Object.values(HeatSourceOfUtilizedHeat).map((source) => (
          <Box key={source}>
            <RadioField
              label={t(`sourceOfUtilizedHeat.${source}`)}
              disabled={[
                HeatSourceOfUtilizedHeat.C21,
                HeatSourceOfUtilizedHeat.C22,
              ].includes(source)}
              checked={form.source_of_utilized_heat === source}
              onClick={() =>
                handleFormChange('source_of_utilized_heat', source)
              }
            />
          </Box>
        ))}

        {form.source_of_utilized_heat === HeatSourceOfUtilizedHeat.C23 && (
          <StyledNumberField
            label="Amount of imported heat [TJ]"
            value={form.imported_heat}
            onChange={(value) => handleFormChange('imported_heat', value)}
          />
        )}

        <br />
        <Typography variant="h5">Export of measurable heat:</Typography>

        {Object.values(HeatExportMeasurableHeat).map((option) => (
          <Box key={option}>
            <RadioField
              label={t(`exportMeasurableHeat.${option}`)}
              checked={form.export_measurable_heat === option}
              onClick={() => handleFormChange('export_measurable_heat', option)}
            />
          </Box>
        ))}

        {form.export_measurable_heat ===
          HeatExportMeasurableHeat.HEAT_IS_EXPORTED && (
          <StyledNumberField
            label="Amount of exported heat [TJ]"
            value={form.exported_heat}
            onChange={(value) => handleFormChange('exported_heat', value)}
          />
        )}
        <br />
        <Typography variant="h5">Heat factor source:</Typography>
        {Object.values(HeatFactorSource).map((option) => (
          <Box key={option}>
            <RadioField
              label={t(`factorSource.${option}`)}
              checked={form.heat_factor_source === option}
              onClick={() => handleFormChange('heat_factor_source', option)}
            />
          </Box>
        ))}

        <br />
        <Typography variant="h5">Value:</Typography>
        <StyledNumberField
          label="Heat emission factor [tCO2/TJ]"
          value={form.heat_emission_factor ?? ''}
          onChange={(value) => handleFormChange('heat_emission_factor', value)}
        />

        {form.heat_factor_source === HeatFactorSource.REGION_DEFAULT && (
          <InfoPanel>
            <Typography>
              Standard value (Region default) is based on the standard emission
              factor of the fuel most commonly used in the industrial sector of
              the country, assuming a boiler efficiency of 90%. World average is
              equal to 131.94 tCO2/TJ.
            </Typography>
          </InfoPanel>
        )}

        {isModified && (
          <Box sx={{ width: '100%', textAlign: 'right', mt: '12px' }}>
            <Button
              color="primary"
              onClick={handleSaveClick}
              sx={{ minWidth: '200px' }}
            >
              {id ? 'Save' : 'Create'}
            </Button>
          </Box>
        )}
      </StyledPanel>
    </>
  );
}
