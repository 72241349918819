import { Button, CircularProgress, Menu } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
  size: 'small' | 'medium' | 'large';
}

const ToolsButton = (props: Props) => {
  const { t } = useTranslation();
  const anchorToolsEl = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        ref={anchorToolsEl}
        id="tools-button"
        aria-controls={isOpen ? 'tools-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        variant="secondary"
        disableElevation
        onClick={() => setIsOpen((x) => !x)}
        endIcon={
          props.isLoading ? (
            <CircularProgress size="1em" color="inherit" />
          ) : (
            <KeyboardArrowDownIcon />
          )
        }
        size={props.size ? props.size : 'medium'}
        disabled={props.isLoading}
        sx={{
          zIndex: 10,
        }}
      >
        {t('esg.tools')}
      </Button>
      <Menu
        id="tools-menu"
        anchorEl={anchorToolsEl.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onClick={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          mt: 0.5,
        }}
      >
        {props.children}
      </Menu>
    </>
  );
};

export default ToolsButton;
