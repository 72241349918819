import { styled as styledMUI } from '@mui/material/styles';
import { Checkbox, CheckboxProps, FormGroup } from '@mui/material';
import styled from 'styled-components';

export const StyledCheckbox = styledMUI(Checkbox)<
  CheckboxProps & { error?: boolean }
>`
  color: ${({ error }) => (error ? '#CF6367' : '#165D5C')} ;
  
  &.Mui-checked {
    color: #165D5C;
  }
`;

export const StyledFormGroup = styledMUI(FormGroup)`
  flex-direction: row;
  align-items: center;

  & .MuiTypography-root.MuiFormControlLabel-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #2F2F2F;
    font-family: 'Poppins', sans-serif;
  }
`;

export const InfoBox = styled.div`
  position: relative;
  cursor: pointer;
`;

export const ContentBox = styled.div`
  position: absolute;
  width: 370px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px 0px 16px 16px;
  padding: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #606462;
  right: 30px;
  top: 30px;
  z-index: 110;
`;
