import { Button, FormControl } from '@mui/material';
import React from 'react';
import { StyledTextField } from '../productInformation';

interface Material {
  material_name: string;
  percentage: number;
  is_basic: boolean;
  id?: number;
  tempId?: number;
}

interface Props {
  values: {
    functional_unit: string;
    data_collection_period: string;
    weight: number | null;
    weight_unit: string;
    dimensions: string;
    dimensions_unit: string;
    volume: number | null;
    volume_unit: string;
    production_process_description: string;
    assumptions_description: string;
    reference_service_life: string;
    cutoff_rules: string;
    additional_information: string;
    materials: Material[];
  };
  setValues: Function;
}

export default function Materials({ setValues, values }: Props) {
  const handleMaterialChange = (
    materialId: number,
    type: 'material_name' | 'percentage',
    value: string | number,
    isBasic: boolean
  ) => {
    const updatedMaterials = values.materials.map((material) => {
      const _id = material.id || material.tempId;
      return _id === materialId && material.is_basic === isBasic
        ? { ...material, [type]: value }
        : material;
    });
    setValues({ ...values, materials: updatedMaterials });
  };

  const handleRemoveMaterial = (materialId: number, isBasic: boolean) => {
    const updatedMaterials = values.materials.filter((material) => {
      const _id = material.id || material.tempId;
      return !(_id === materialId && material.is_basic === isBasic);
    });
    setValues({ ...values, materials: updatedMaterials });
  };

  const addMaterial = (isBasic: boolean) => {
    const newMaterial: Material = {
      tempId: new Date().getTime(),
      material_name: '',
      percentage: 0,
      is_basic: isBasic,
    };
    const updatedMaterials = [...values.materials, newMaterial];
    setValues({ ...values, materials: updatedMaterials });
  };

  const renderMaterialFields = (isBasic: boolean) =>
    values.materials
      .filter((material) => material.is_basic === isBasic)
      .map((material) => (
        <div
          style={{ display: 'flex', gap: '24px', alignItems: 'center' }}
          key={material.id || material.tempId}
        >
          <FormControl fullWidth>
            <StyledTextField
              value={material.material_name}
              label={
                isBasic ? 'Basic material' : 'Additional material (optional)'
              }
              onChange={(e) => {
                const { value } = e.target;
                handleMaterialChange(
                  material.id || material.tempId || new Date().getTime(),
                  'material_name',
                  value,
                  isBasic
                );
              }}
            />
          </FormControl>
          <StyledTextField
            value={material.percentage}
            label="Percentage of material in the product [%]"
            onChange={(e) => {
              handleMaterialChange(
                material.id || material.tempId || new Date().getTime(),
                'percentage',
                e.target.value === '' ? 0 : parseFloat(e.target.value),
                isBasic
              );
            }}
          />
          <Button
            variant="secondary"
            size="small"
            onClick={() =>
              handleRemoveMaterial(
                material.id || material.tempId || new Date().getTime(),
                isBasic
              )
            }
          >
            remove
          </Button>
        </div>
      ));

  return (
    <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
        {renderMaterialFields(true)}
        <div>
          <Button
            variant="secondary"
            size="small"
            onClick={() => addMaterial(true)}
          >
            Add another basic material
          </Button>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
        {renderMaterialFields(false)}
        <div>
          <Button
            variant="secondary"
            size="small"
            onClick={() => addMaterial(false)}
          >
            Add another additional material
          </Button>
        </div>
      </div>
    </div>
  );
}
