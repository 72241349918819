import React from 'react';
import Step from './Step';
import LightTooltip from '../LightTooltip';
import { StyledProgressBar, StyledProgressBarContainer } from './styles';
import { Box } from '@mui/material';
import colors from 'src/theme/colors';

interface Props {
  pct?: number;
  height?: number;
  width?: string | number;
  steps?: number[]; // pct, creates circle label on the progress bar
  color?: string;
}

const DEFAULT_INDICATOR_CATEGORY_COLOR = colors.pureWhite; // from backend
const DEFAULT_PROGRESS_COLOR = colors.esgMain; // to apply

export default function ProgressBar(props: Props) {
  const { pct: _pct = 0, height = 10, width = '100%', steps = [] } = props;
  const pct = Math.min(100, Math.max(0, _pct));
  const _color = props.color?.toLocaleLowerCase() || DEFAULT_PROGRESS_COLOR;
  const isEmptyColor = _color === DEFAULT_INDICATOR_CATEGORY_COLOR;
  const color = isEmptyColor ? DEFAULT_PROGRESS_COLOR : _color;

  return (
    <LightTooltip title={`${pct}%`}>
      <Box sx={{ width }}>
        <StyledProgressBarContainer height={height} pct={pct}>
          <StyledProgressBar pct={pct} height={height} color={color} />
          {steps.map((step) => (
            <Step
              key={step}
              pctLabel={step}
              pct={pct}
              height={height}
              color={color}
            />
          ))}
        </StyledProgressBarContainer>
      </Box>
    </LightTooltip>
  );
}
