import { Button, Typography } from '@mui/material';
import React from 'react';
import { useDirectCombustionEmissions, useFuelTypes } from 'src/Cbam/hooks';
import { StyledPanel } from 'src/components/StyledPanel';
import { StyledNumberField, StyledTextField } from '../../../styles';
import {
  CbamDirectCombustionEmissionInSchema,
  CbamDirectCombustionEmissionOutSchema,
  DirectCombustionActivityUnit,
  EmissionFactorSource,
} from 'src/Cbam/types';
import RadioField from '../components/RadioField';
import { StyledFlex } from 'src/components/StyledFlex';
import EmissionFactorSourceField from '../components/EmissionFactorSourceField';
import FuelTypeField from '../components/FuelTypeField';
import { useChangeTracker } from '../components/CounterProvider';

interface Props {
  productionProcessId: number;
  id?: number;
  onSaved?: () => void;
}
export default function DirectCombustionEmission(props: Props) {
  const { productionProcessId, id, onSaved } = props;
  const { isModified, setIsModified } = useChangeTracker();
  const fuelTypes = useFuelTypes();
  const combustionEmissions = useDirectCombustionEmissions(productionProcessId);
  const combustionEmission = combustionEmissions.data.find(
    (c) => c.id === Number(id)
  );

  const initialForm = {
    production_process_id: productionProcessId,
    name: '',
    activity_data_unit: DirectCombustionActivityUnit.TJ,
    activity_data: null,
    emission_factor_source: EmissionFactorSource.EVIDENCE_BASED,
    fuel_type: null,
    emission_factor_tco2_tonne: null,
    oxidation_factor: 1,
  };

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  const [form, setForm] = React.useState<
    CbamDirectCombustionEmissionInSchema | CbamDirectCombustionEmissionOutSchema
  >(initialForm);

  React.useEffect(() => {
    if (combustionEmission) setForm(combustionEmission);
  }, [combustionEmission]);

  const handleSaveClick = () =>
    id
      ? combustionEmissions.update.mutateAsync({ ...form, id }).then(() => {
          setIsModified(false);
          onSaved?.();
        })
      : combustionEmissions.create.mutateAsync(form).then(() => {
          setIsModified(false);
          onSaved?.();
        });

  const fuelDataFactor = (value: string) => {
    if (!value) return 0;
    const fuelType = fuelTypes.data[value];
    if (!fuelType) return 0;
    // factor is [tCo2/TJ]
    // ncv is [TJ/Gg] = [TJ/kt]
    if (form.activity_data_unit === DirectCombustionActivityUnit.TJ)
      return fuelType.factor;
    if (form.activity_data_unit === DirectCombustionActivityUnit.TONNE)
      return (fuelType.factor * fuelType.ncv) / 1000;
    return 0; // TODO m3
  };

  React.useEffect(() => {
    if (form.fuel_type) {
      const factor = fuelDataFactor(form.fuel_type);
      setForm((prevState) => ({
        ...prevState,
        emission_factor_tco2_tonne: factor,
      }));
    }
  }, [fuelTypes.data, form.fuel_type, form.activity_data_unit]);

  return (
    <StyledPanel sx={{ m: '20px 0px' }}>
      <StyledTextField
        label="Name"
        value={form.name}
        onChange={(e) => handleFormChange('name', e.target.value)}
      />
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <Typography variant="h5">Activity data unit:</Typography>
        {[
          DirectCombustionActivityUnit.TJ,
          DirectCombustionActivityUnit.TONNE,
        ].map((option) => (
          <RadioField
            key={option}
            label={option}
            checked={form.activity_data_unit === option}
            onClick={() => handleFormChange('activity_data_unit', option)}
          />
        ))}
      </StyledFlex>

      <StyledNumberField
        label={`Activity data [${form.activity_data_unit}]`}
        value={form.activity_data ?? ''}
        onChange={(value) => handleFormChange('activity_data', value)}
      />

      <br />
      <EmissionFactorSourceField
        value={form.emission_factor_source}
        onChange={(value) => handleFormChange('emission_factor_source', value)}
      />

      {form.emission_factor_source === EmissionFactorSource.CBAM && (
        <FuelTypeField
          value={form.fuel_type}
          onChange={(value) => handleFormChange('fuel_type', value)}
        />
      )}
      <StyledNumberField
        disabled={form.emission_factor_source === EmissionFactorSource.CBAM}
        label={`Emission factor [tCO2/${form.activity_data_unit}]`}
        value={form.emission_factor_tco2_tonne}
        onChange={(value) =>
          handleFormChange('emission_factor_tco2_tonne', value)
        }
      />

      <StyledNumberField
        label="Oxidation factor"
        value={form.oxidation_factor ?? ''}
        onChange={(value) => handleFormChange('oxidation_factor', value)}
      />

      <StyledFlex>
        <Button
          onClick={() =>
            id ? combustionEmissions.delete.mutate(id) : onSaved?.()
          }
          color="primary"
        >
          Remove Answer
        </Button>
        {isModified && (
          <Button
            color="primary"
            onClick={handleSaveClick}
            sx={{ minWidth: '200px' }}
          >
            {id ? 'Save' : 'Create'}
          </Button>
        )}
      </StyledFlex>
    </StyledPanel>
  );
}
