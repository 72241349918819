import { client } from '../../../utils/api-client';
import { Field } from './Field/index.types';
import { Question } from './Question/index.types';
import { QuestionAnswer } from './types';
import { paths, preparefieldsValues } from './helpers';

export const updateQuestionAnswer = (
  tokenAuth: () => Promise<any>,
  questionAnswer: QuestionAnswer,
  fields: Field[]
) =>
  tokenAuth().then((token) =>
    client.put(
      `${paths.questionnaireAnswerRecords}/${questionAnswer.id}`,
      preparefieldsValues(questionAnswer, fields),
      {
        headers: {
          'X-Questionnaires-Token': token,
        },
      }
    )
  );

export const createQuestionAnswer = (
  tokenAuth: () => Promise<any>,
  questionnaireAnswerId: string | undefined,
  questionAnswer: QuestionAnswer,
  question: Question,
  fields: Field[]
) =>
  tokenAuth().then((token) =>
    client.post(
      `${paths.questionnaireAnswerRecords}`,
      preparefieldsValues(questionAnswer, fields),
      {
        headers: { 'X-Questionnaires-Token': token },
        params: {
          questionnaire_answer_id: questionnaireAnswerId,
          question_key: question.key,
        },
      }
    )
  );
