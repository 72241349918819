import React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { client } from 'src/utils/api-client';

import { Button } from '@mui/material';
import { StyledTitle } from '../index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import UserForm, {
  useUserForm,
  validateForm,
} from '../components/Panels/UserForm';
import { UserOutFormSchema } from '../index.types';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import { toast } from 'react-toastify';
import { withEmptyStringsAsNull } from 'src/utils/helpers';
import { useAppSelector } from 'src/redux-file/hooks';

export default function MyAccount() {
  const { t } = useTranslation();

  const { id: userId } = useAppSelector(
    (state) => state.platform.user || { id: null }
  );

  const formState = useUserForm();

  const {
    data: user,
    isLoading,
    isError,
  } = useQuery(['user', userId], () =>
    client
      .get<UserOutFormSchema>(`/web/settings_v2/users/${userId}`)
      .then((response) => {
        const { data } = response;
        if (data) {
          formState.setFirstName(data?.first_name || '');
          formState.setLastName(data?.last_name || '');
          formState.setPosition(data?.position || '');
          formState.setEmail(data?.email || '');
          formState.setPhone(data?.phone || '');
        }
        return data;
      })
  );

  if (isError) return <SomethingWentWrong />;
  if (isLoading) return <PageLoading />;

  const handleSave = () => {
    const data = {
      first_name: formState.firstName,
      last_name: formState.lastName,
      position: formState.position,
      email: formState.email,
      phone: formState.phone,
    };
    const formErrors = validateForm(data, false);
    formState.setErrors(formErrors);
    if (Object.keys(formErrors).length > 0) {
      return;
    }
    client
      .put(`/web/settings_v2/users/${userId}`, withEmptyStringsAsNull(data))
      .then((response) => {
        toast.success(String(t('superadmin.toast.user-saved')));
        return response.data;
      });
  };

  return (
    <>
      <StyledFlex>
        <StyledTitle>{t('superadmin.myAccount.title')}</StyledTitle>
      </StyledFlex>

      <UserForm {...formState} id={userId} myAccount onSubmit={handleSave} />

      <StyledFlex sx={{ mt: '50px' }}>
        <div />
        <Button onClick={handleSave}>
          {t('superadmin.myAccount.buttons.save')}
        </Button>
      </StyledFlex>
    </>
  );
}
