import styled from 'styled-components';

export const Icon = styled.img`
  height: 20px;
  margin-right: 11px;
`;

export const Text = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #165d5c;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const Button = styled.div`
  margin-top: 8px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
