import React from 'react';
import LightTooltip from '../LightTooltip';

interface Props {
  minLength: number;
  text: string;
}

export default function TextWithTooltip(props: Props) {
  // injects tooltip if text is longer than length and renders Typography noWrap
  // else renders Typography
  const { minLength, text } = props;
  const isTooltipNeeded = text.length > minLength;
  return !isTooltipNeeded ? (
    <span>{text}</span>
  ) : (
    <LightTooltip title={text}>
      <span>{text.slice(0, minLength)}...</span>
    </LightTooltip>
  );
}
