import QuizIcon from '@mui/icons-material/Quiz';
import React from 'react';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { useModal } from '../../Management/Modals/Provider';
import { Category } from '../../Management/ProcessObjectPanel/index.types';
import { categorySettings } from '../../helpers';
import { Subcategory } from '../index.types';
import {
  StyledNode,
  StyledObjectFooter,
  StyledObjectNode,
  StyledObjectText,
  StyledProcessContent,
  StyledProcessHeader,
} from './index.styles';
import { Node as NodeType } from './index.types';
import { useProductImpact } from 'src/Lca/hooks';
import { useAppSelector } from 'src/redux-file/hooks';
import EcoinventEmission from 'src/Lca/components/EcoinventEmission';

type Props = {
  node: NodeType;
};

export const ProcessNode = ({ node }: Props) => {
  const { name, children } = node;

  const { openModal } = useModal();

  const openProcess = () =>
    openModal({
      __name__: 'process',
      productId: node?.product,
      process: { ...node },
    });

  return (
    <StyledNode id={node.id}>
      <StyledProcessHeader onClick={openProcess}>{name}</StyledProcessHeader>
      <StyledProcessContent>
        {!children?.length && <div>&nbsp;</div>}
        {(children || []).map((childNode) => (
          <Node key={childNode.id} node={childNode} />
        ))}
      </StyledProcessContent>
    </StyledNode>
  );
};

export const ObjectNode = ({ node }: Props) => {
  const {
    name,
    product: productId,
    amount,
    category_key: categoryKey,
    ecoinvent_activity: ecoinventActivity,
  } = node;
  const category = categorySettings[categoryKey || ''];
  const CategoryIcon = category?.icon || QuizIcon;

  const { data: categories } = useQuery(`lca-categories`, () =>
    client.get('/web/lca/categories').then((response) => response.data)
  );

  const { selectedAreaOfImpact } = useAppSelector((state) => state.lcaDiagram);
  const { data: impactData } = useProductImpact(
    productId as number,
    selectedAreaOfImpact?.id
  );

  const impact = impactData?.emission_per_node?.[node.id];

  const allSubcategories = categories?.flatMap(
    (category: Category) => category.subcategories
  );

  const subcategory = allSubcategories?.find(
    (subcategory: Subcategory) => subcategory.id === node.subcategory
  );

  const { openModal } = useModal();

  const handleObjectModalOpen = () => {
    openModal({
      __name__: 'object',
      subcategory,
      productId,
      object: node,
    });
  };

  return (
    <StyledObjectNode
      color={categoryKey || 'uncategorized'}
      onClick={handleObjectModalOpen}
      id={node.id}
    >
      <StyledObjectText>
        {amount} {ecoinventActivity?.reference_product_unit ?? ''} of {name}
      </StyledObjectText>
      <StyledObjectFooter>
        <CategoryIcon />
        <StyledObjectText>
          <EcoinventEmission value={impact} />
        </StyledObjectText>
      </StyledObjectFooter>
    </StyledObjectNode>
  );
};

export default function Node({ node }: Props) {
  const { type } = node;
  switch (type) {
    case 'process':
      return <ProcessNode node={node} />;
    case 'object':
      return <ObjectNode node={node} />;

    default:
      return <div>undefined node type: {type}</div>;
  }
}
