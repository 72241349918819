import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import { StyledNumberField, StyledTextField } from '../../../styles';
import { useCo2Transfer } from 'src/Cbam/hooks';
import { CbamCo2TransferSchema } from 'src/Cbam/types';
import { useChangeTracker } from '../components/CounterProvider';
import InfoPanel from 'src/Cbam/components/InfoPanel';
import { useTranslation } from 'react-i18next';
import {
  FormErrors,
  isMonitoringMethodologyDocumentationRequired,
  validateForm,
} from './FormValidation';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import { toast } from 'react-toastify';

interface Props {
  id: number;
}

export default function Co2Transfer(props: Props) {
  const { id } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.productionProcess.co2Transfer',
  });
  const { isModified, setIsModified } = useChangeTracker();
  const co2Transfer = useCo2Transfer(id);

  const initialForm = {
    production_process_id: id,
    amount_of_stored_inherent_co2_tonne: null,
    amount_of_used_inherent_co2_tonne: null,
    amount_of_emitted_inherent_co2_tonne: null,
    monitoring_methodology_documentation_for_co2_transfer: null,
  };

  const [form, setForm] = React.useState<CbamCo2TransferSchema>(initialForm);

  const [errors, setErrors] = React.useState<FormErrors>({});

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  const isError = (fieldName: keyof CbamCo2TransferSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof CbamCo2TransferSchema) =>
    isError(fieldName) && mapError(errors, fieldName, form[fieldName] || '');

  React.useEffect(() => {
    if (co2Transfer.data) setForm(co2Transfer.data);
  }, [co2Transfer.data]);

  const handleSaveClick = () =>
    co2Transfer.update
      .mutateAsync(form)
      .then(() => {
        setIsModified(false);
      })
      .catch((err: any) => {
        const extractedErrors = extractSchemaErrors(err, form);
        setErrors(extractedErrors);
      });

  return (
    <>
      <Typography variant="h3">CO2 Transfer (B.8)</Typography>
      <br />
      <InfoPanel>
        <Typography>
          Inherent CO2 that is transferred into an installation, including that
          contained in natural gas, a waste gas (including blast furnace or coke
          oven gas) or in process inputs (including synthesis gas), shall be
          included in the emission factor for that source stream.
          <br />
          CO2 originating from fossil carbon and originating from combustion or
          processes leading to process emissions, or which is imported from
          other installations, including in the form of inherent CO2, may be
          accounted for as not emitted if:
          <ul>
            <li>
              It is used to make products in which the carbon stemming from CO2
              is permanently chemically bound so that it does not enter the
              atmosphere under normal use, including any normal activity taking
              place after the end of the life of the product. This includes both
              usage in this Installation and transfers to other entity, which
              monitors emissions and makes such products.
            </li>
            <li>
              It is stored in long-term geological storage, which monitors
              emissions.
            </li>
          </ul>
        </Typography>
      </InfoPanel>
      <br />
      <StyledPanel>
        {[
          'amount_of_emitted_inherent_co2_tonne',
          'amount_of_used_inherent_co2_tonne',
          'amount_of_stored_inherent_co2_tonne',
        ].map((key) => (
          <StyledNumberField
            key={key}
            label={t(`form.${key}`)}
            value={form[key as keyof CbamCo2TransferSchema] || ''}
            onChange={(value) => handleFormChange(key, value)}
            error={isError(key as keyof CbamCo2TransferSchema)}
            helperText={errorText(key as keyof CbamCo2TransferSchema)}
          />
        ))}
        <StyledTextField
          label={t(
            'form.monitoring_methodology_documentation_for_co2_transfer'
          )}
          value={
            form.monitoring_methodology_documentation_for_co2_transfer || ''
          }
          onChange={(e) =>
            handleFormChange(
              'monitoring_methodology_documentation_for_co2_transfer',
              e.target.value
            )
          }
          multiline
          minRows={3}
          required={isMonitoringMethodologyDocumentationRequired(form)}
          error={isError(
            'monitoring_methodology_documentation_for_co2_transfer'
          )}
          helperText={errorText(
            'monitoring_methodology_documentation_for_co2_transfer'
          )}
        />
        {isModified && (
          <>
            <br />
            <br />
            <Box sx={{ width: '100%', textAlign: 'right' }}>
              <Button
                color="primary"
                onClick={handleSaveClick}
                sx={{ minWidth: '200px' }}
                disabled={Object.keys(validateForm(form, t)).length > 0}
              >
                {id ? 'Save' : 'Create'}
              </Button>
            </Box>
          </>
        )}
      </StyledPanel>
    </>
  );
}
