import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { parseValue } from './utils';

interface Props {
  maxDigits?: number;
  decimalPlaces?: number;
  onChange: (
    // eslint-disable-next-line no-unused-vars
    value: null | string | number,
    // eslint-disable-next-line no-unused-vars
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export default function NumberField(
  props: Omit<TextFieldProps, 'onChange'> & Props
) {
  const { onChange, maxDigits = 10, decimalPlaces = 5 } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [_event, value] = parseValue(event, maxDigits, decimalPlaces);
    onChange?.(value, _event);
  };

  // Enforces the value to be a number when the input field loses focus
  // removes leading and trailing zeros
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const _number = Number(event.target.value);
    if (!!_number) {
      const value = String(_number);
      event.target.value = value;
      onChange?.(value, event);
    }
  };

  const isInvalidNumber = Number.isNaN(Number(props.value));
  const invalidNumberMessage = isInvalidNumber ? 'Invalid number' : null;

  return (
    <TextField
      {...props}
      helperText={props.helperText || invalidNumberMessage}
      value={props.value ?? ''}
      onChange={handleChange}
      error={props.error || isInvalidNumber}
      onBlur={handleOnBlur}
    />
  );
}
