import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledFlex } from 'src/components/StyledFlex';

export const StyledCell = styled(Typography)`
  color: #38414f;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-family: Poppins;
  padding: 17px;
  width: 10%;
  min-width: 90px;
  height: 70px;
  display: flex;
  flex-wrap: nowrap;
`;

export const StyledHeader = styled(StyledCell)`
  font-weight: 700;
`;

export const StyledRow = styled(StyledFlex)`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  border-bottom: 1px solid #b6bbb9;
  height: 74px;
  overflow: auto;

  & > :nth-of-type(1) {
    width: 15%; /* Targeting the second child specifically */
  }

  & > :nth-of-type(2) {
    flex: 2;
  }

  & > :nth-of-type(3),
  & > :nth-of-type(4),
  & > :nth-of-type(5) {
    justify-content: center;
  }

  ,
  & > * {
    flex: 1; /* Applies to all children, but the second child's width takes precedence */
    min-width: 0;
  }
`;

export const StyledDataCell = styled(StyledCell)`
  padding-top: 12px;
  display: flex;
  justify-content: center;
`;

export const StyledDialogTitle = styled(Typography)`
  color: #38414f;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.48px;
  padding: 24px;
  padding-bottom: 12px;
`;

export const StyledDialogSubtitle = styled(Typography)`
  color: #38414f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.48px;
  padding: 24px;
  padding-top: 12px;
`;

export const stakeholderTypesCellStyle = {
  width: '20%',
  minWidth: '200px',
  backgroundColor: '#F6F6F6',
};
