import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import themeColors from '../../../theme/colors';

export const StyledDialogContentText = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: ${themeColors.dark};
  display: flex;
  justify-content: center;
`;

export const StyledDialog = styledMUI(Dialog)`  
  & .MuiPaper-root {
    background: #FFFFFF;
    border: 1px solid #D9DFDD;
    border-radius: 26px;
    padding: 10px 10px 20px 10px;
  }
  
  & .MuiDialogContent-root {
    margin-top: -25px;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 0;
  position: relative;
  z-index: 2;

  &:hover {
    transition:
      background-color,
      border 0.3s ease-in-out;
    background-color: #00000010;
  }
`;

export const Error = styled.div`
  color: ${themeColors.redParagraphs};
`;

export const Link = styled.a`
  background: ${themeColors.greenBrand};
  text-decoration: none;
  color: ${themeColors.pureWhite};
  border-radius: 28px;
  padding: 10px 50px;
`;

export const SelectDateText = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${themeColors.dark};
  margin-bottom: 24px;
`;

export const LoaderSpin = styled.img`
  animation-duration: 3s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 218px;
  height: 218px;

  @keyframes slidein {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

export const GenerateReportButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 36px;
  justify-content: flex-end;
`;

export const SaveIcon = styled.svg`
  margin: 90px 0;
  .arrow {
    animation-duration: 3s;
    animation-name: arrowMove;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes arrowMove {
    from {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(20px);
    }

    to {
      transform: translateY(0px);
    }
  }
`;

export const Box = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 64px;
  line-height: 38px;
  color: #165d5c;

  span {
    font-size: 40px;
  }
`;

export const Abort = styled.div`
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-top: 30px;
  letter-spacing: 0.02em;

  color: #606462;
`;

export const TakeTime = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  margin: 20px 0;
  color: #2f2f2f;
`;

export const GeneratingText = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #165d5c;
`;
