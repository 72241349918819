import { Box, Button, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import { CbamInstallationInSchema, CbamInstallationOutSchema } from '../types';
import { toast } from 'react-toastify';
import NavigationBlockerModal from 'src/components/NavigationBlockerModal';
import { useNonEUCountries } from 'src/common/hooks';
import { useTranslation } from 'react-i18next';
import { FormErrors, requiredFields, validateForm } from './FormValidation';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import { useInstallations, useOperators } from '../hooks';
import {
  StyledFormControl,
  StyledNumberField,
  StyledSectionDivider,
  StyledSectionHeader,
  StyledTextField,
} from '../styles';
import InstallationInfoPanel from '../Installations/InstallationInfoPanel';

const initialForm = {
  identifier: '',
  name: '',
  name_en: '',
  economic_activity: '',

  country: '',
  sub_division: '',
  city: '',
  street_name: '',
  street_name_additional_line: '',
  street_number: '',
  post_code: '',
  po_box: '',
  plot_parcel_number: '',

  UN_LOCODE: '',
  emission_source_latitude: null,
  emission_source_longitude: null,

  operator_id: null,
};

interface Props {
  id?: Number;
  survey?: boolean;
  onSaved?: () => void;
}

export default function InstallationForm(props: Props) {
  const { id, survey, onSaved } = props;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.installations',
  });

  const { data, create, update } = useInstallations();
  const { data: countries } = useNonEUCountries();
  const { data: operators } = survey ? { data: [] } : useOperators();

  const installation = data?.find((i) => i.id === id);

  const [form, setForm] = React.useState<
    CbamInstallationOutSchema | CbamInstallationInSchema
  >(initialForm);

  const [errors, setErrors] = React.useState<FormErrors>({});

  const [isModified, setIsModified] = React.useState(false);

  const handleFormChange = (key: string, value: string | number | null) => {
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
    setIsModified(true);
  };

  React.useEffect(() => {
    if (installation) setForm(installation);
  }, [installation]);

  const save = !installation ? create.mutateAsync : update.mutateAsync;

  const isError = (fieldName: keyof CbamInstallationInSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof CbamInstallationInSchema) =>
    isError(fieldName) && mapError(errors, fieldName, form[fieldName] || '');

  const handleSaveClick = () => {
    setIsModified(false);
    save(form)
      .then(() => onSaved?.())
      .catch((err: any) => {
        setIsModified(true);
        toast.error('Failed to save');
        const extractedErrors = extractSchemaErrors(err, form);
        setErrors(extractedErrors);
      });
  };

  return (
    <Box>
      <Box sx={{ mb: '12px' }}>
        <InstallationInfoPanel />
      </Box>
      <NavigationBlockerModal shouldBlock={isModified} />
      <StyledPanel>
        <StyledSectionHeader>{t('general_information')}</StyledSectionHeader>
        {['identifier', 'name', 'name_en', 'economic_activity'].map((field) => (
          <StyledTextField
            key={field}
            label={t(`form.${field}`)}
            value={form[field as keyof CbamInstallationInSchema]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleFormChange(field, e.currentTarget.value)
            }
            required={requiredFields.includes(
              field as keyof CbamInstallationInSchema
            )}
            error={isError(field as keyof CbamInstallationInSchema)}
            helperText={errorText(field as keyof CbamInstallationInSchema)}
            multiline={field === 'economic_activity'}
            rows={field === 'economic_activity' ? 3 : 1}
          />
        ))}

        {!survey && (
          <StyledFormControl required>
            <InputLabel id="operator">{t('form.operator')}</InputLabel>
            <Select
              key={form.operator_id}
              labelId="operator"
              label={t('form.operator')}
              value={form.operator_id || ''}
              onChange={(e) => handleFormChange('operator_id', e.target.value)}
            >
              {operators.map((operator) => (
                <MenuItem key={operator.id} value={operator.id}>
                  {operator.identifier} ({operator.name})
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        )}
        <StyledSectionDivider />
        <StyledSectionHeader>{t('address')}</StyledSectionHeader>
        <StyledFormControl required>
          <InputLabel id="country">{t('form.country')}</InputLabel>
          <Select
            key={form.country}
            labelId="country"
            label={t('form.country')}
            value={form.country}
            onChange={(e) => handleFormChange('country', e.target.value)}
          >
            {countries.map((country) => (
              <MenuItem key={country.iso_code} value={country.iso_code}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        {[
          'sub_division',
          'city',
          'street_name',
          'street_name_additional_line',
          'street_number',
          'post_code',
          'po_box',
          'plot_parcel_number',
          'UN_LOCODE',
        ].map((field) => (
          <StyledTextField
            key={field}
            label={t(`form.${field}`)}
            value={form[field as keyof CbamInstallationInSchema]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleFormChange(field, e.currentTarget.value)
            }
            required={requiredFields.includes(
              field as keyof CbamInstallationInSchema
            )}
            error={isError(field as keyof CbamInstallationInSchema)}
            helperText={errorText(field as keyof CbamInstallationInSchema)}
          />
        ))}
        {['emission_source_latitude', 'emission_source_longitude'].map(
          (field) => (
            <StyledNumberField
              key={field}
              label={t(`form.${field}`)}
              value={form[field as keyof CbamInstallationInSchema]}
              onChange={(value) => handleFormChange(field, value)}
              error={isError(field as keyof CbamInstallationInSchema)}
              helperText={errorText(field as keyof CbamInstallationInSchema)}
            />
          )
        )}
      </StyledPanel>
      <br />
      <br />
      <Button
        sx={{ float: 'right' }}
        onClick={handleSaveClick}
        disabled={!isModified || Object.keys(validateForm(form, t)).length > 0}
      >
        {t('save')}
      </Button>
    </Box>
  );
}
