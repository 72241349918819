import React from 'react';
import { useQuery } from 'react-query';
import { InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { StyledButton, StyledButtonBox } from '../index.styles';
import { mapError } from 'src/utils/validation';
import {
  FormErrors,
  RegistrationUBNPSchema,
  validateForm,
  validateFormFirstPage,
} from '../form';
import Autocomplete from 'src/components/Autocomplete';
import { Country, PkdCode } from '../types';
import { client } from 'src/utils/api-client';
import Consents from '../components/Consents';
import ScheduleMeetingDialog from '../components/ScheduleMeetingDialog';
import { useLanguage, useSupportedCountries } from 'src/common/hooks';

export default function RegistrationFirstPage(props: {
  form: RegistrationUBNPSchema;
  setForm: React.Dispatch<React.SetStateAction<RegistrationUBNPSchema>>;
  errors: FormErrors;
  setErrors: React.Dispatch<React.SetStateAction<FormErrors>>;
  onNext: () => void;
}) {
  const { form, setForm, errors, setErrors, onNext } = props;
  const { t, language } = useLanguage();

  const [scheduleMeetingDialogOpen, setScheduleMeetingDialogOpen] =
    React.useState<boolean>(false);

  const { data: countries } = useSupportedCountries();

  const { data: pkdCodes } = useQuery<PkdCode[]>(
    ['pkdCodes', { language }],
    () => client.get('web/industries/pkd').then((res) => res?.data || [])
  );

  const isError = (fieldName: keyof RegistrationUBNPSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof RegistrationUBNPSchema) =>
    !!form[fieldName] &&
    String(t(mapError(errors, fieldName, form[fieldName]) || ''));

  const handleNextPageButtonClick = () => {
    if (form.bnp_analytics) {
      onNext();
    } else {
      setScheduleMeetingDialogOpen(true);
    }
  };

  return (
    <>
      {[
        'first_name',
        'last_name',
        'email',
        'phone_number',
        'organization_name',
        'vat_identifier',
        'regon',
      ].map((field) => (
        <TextField
          key={field}
          sx={{ mt: '24px' }}
          label={t(`registration_bnp.form.${field}`)}
          type="text"
          value={form[field as keyof RegistrationUBNPSchema]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newForm = {
              ...form,
              [field]: e.currentTarget.value,
            };
            setForm(newForm);
            setErrors(validateForm(newForm));
          }}
          variant="outlined"
          fullWidth
          required
          error={isError(field as keyof RegistrationUBNPSchema)}
          helperText={errorText(field as keyof RegistrationUBNPSchema)}
        />
      ))}
      <Autocomplete
        label={t('registration_bnp.form.pkd_code')}
        placeholder={t('registration_bnp.form.pkd_code')}
        options={pkdCodes?.map((option: PkdCode) => ({
          key: option.code,
          value: option.code,
          label: `${option.code} ${option.description}`,
        }))}
        value={form.pkd_code}
        onChange={(pkd: any) => setForm({ ...form, pkd_code: pkd })}
        handleClearClick={() => setForm({ ...form, pkd_code: null })}
        sx={{ mt: '24px' }}
        errorText={errorText('pkd_code') || undefined}
      />
      <FormControl required fullWidth sx={{ mt: '24px' }}>
        <InputLabel id="countryInputLabel">
          {t('registration_bnp.form.country')}
        </InputLabel>
        <Select
          labelId="countryInputLabel"
          label={t('registration_bnp.form.country')}
          id="countrySelect"
          value={form.country}
          defaultValue={form.country}
          onChange={(e: SelectChangeEvent<string | null>) =>
            setForm({ ...form, country: e.target.value })
          }
          error={isError('country')}
        >
          {countries?.map((country: Country) => (
            <MenuItem key={country.alpha3} value={country.alpha3}>
              {country.name} ({country.alpha3})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Consents form={form} setForm={setForm} />

      <StyledButtonBox sx={{ justifyContent: 'right' }}>
        <StyledButton
          variant="contained"
          size="large"
          onClick={handleNextPageButtonClick}
          disabled={Object.keys(validateFormFirstPage(form)).length > 0}
          endIcon={<NavigateNextIcon fontSize="large" />}
        >
          {t('registration_bnp.nextPage')}
        </StyledButton>

        <ScheduleMeetingDialog
          open={scheduleMeetingDialogOpen}
          onClose={() => setScheduleMeetingDialogOpen(false)}
        />
      </StyledButtonBox>
    </>
  );
}
