import { Edit } from '@mui/icons-material';
import { Button, IconButton, Switch, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useProductionProcessEmissions,
  useProductionProcesses,
} from '../hooks';
import { toast } from 'react-toastify';

interface Props {
  id: number;
}

export default function Row(props: Props) {
  const { id } = props;
  const navigate = useNavigate();
  const productionProcesses = useProductionProcesses();
  const process = productionProcesses.data.find((p) => p.id === id);
  const { data: emissions } = useProductionProcessEmissions(id);

  const toggleSurvey = (enabled: boolean) => {
    productionProcesses.updateSurvey.mutateAsync({ id, is_active: enabled });
  };

  const getSurveyUrl = (token: string | null) => {
    if (!token) return '';
    return `${window.location.origin}/production-process-survey/${token}`;
  };

  const copySurveyUrl = (token: string | null) => {
    if (!token) return;
    navigator.clipboard.writeText(getSurveyUrl(token));
    toast.success('Survey link copied to clipboard');
  };

  if (!process) return null;

  return (
    <TableRow key={process.id}>
      <TableCell>{process.name}</TableCell>
      <TableCell>{process?.aggregated_goods_category}</TableCell>
      <TableCell>
        {emissions.specific_attributed_indirect_emissions.toFixed(2)}
      </TableCell>
      <TableCell>
        {emissions.specific_attributed_direct_emissions.toFixed(2)}
      </TableCell>
      <TableCell>
        <Switch
          color="secondary"
          onChange={(_, checked) => toggleSurvey(checked)}
          checked={process.survey.is_active}
        />
      </TableCell>
      <TableCell>
        <Button
          disabled={!process.survey.is_active}
          onClick={() => copySurveyUrl(process.survey.token)}
        >
          Copy link
        </Button>
      </TableCell>
      <TableCell>
        <IconButton onClick={() => navigate(String(process.id))}>
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
