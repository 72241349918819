/* eslint-disable import/prefer-default-export */
import {
  AggregatedGoodsCategory,
  ProductionProcessGeneralInformation,
} from './types';

export const quantityUnit = (process?: ProductionProcessGeneralInformation) => {
  if (
    process?.aggregated_goods_category === AggregatedGoodsCategory.Electricity
  ) {
    return 'MWh';
  }
  return 'tonne';
};

export const SPECIFIC_ATTRIBUTED_EMISSIONS_ERROR_THRESHOLD = 1000;
