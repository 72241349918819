import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { client } from 'src/utils/api-client';
import { useMutation } from 'react-query';
import { getFilenameDateSuffix } from 'src/utils/timeConverter';
import { useParams } from 'react-router-dom';
import InfoPanel from '../components/InfoPanel';
import { StyledFlex } from 'src/components/StyledFlex';

export default function Summary() {
  const { reportId } = useParams();
  const [error, setError] = React.useState<string | null>(null);

  const mutationRequest = (url: string) => {
    return client.get(url, { responseType: 'blob' });
  };

  const downloadReport = (response: any, fileName: string) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response.data);
    link.download = fileName;
    link.click();
  };

  const exportXmlMutation = useMutation({
    mutationFn: () =>
      mutationRequest(`/web/cbam/reports/${reportId}/export-xml`),
    onSuccess: (response) => {
      setError(null);
      const currentDate = getFilenameDateSuffix();
      downloadReport(response, `cbam-report-${currentDate}.zip`);
    },
    onError: async (error: any) => {
      console.log(error.response);
      const { detail } = JSON.parse(await error.response.data.text());
      console.log(detail);
      setError(detail);
    },
  });

  return (
    <>
      <StyledFlex>
        <Button
          onClick={() => exportXmlMutation.mutate()}
          endIcon={<DownloadIcon />}
          sx={{ mb: '16px' }}
        >
          Export to XML
        </Button>
      </StyledFlex>
      <InfoPanel>
        <Typography>
          Exported report can be uploaded and submitted to CBAM Registry. Before
          submitting the report, please make sure that all data is correct and
          complete.
        </Typography>
      </InfoPanel>
      {error && (
        <Box sx={{ mt: '16px' }}>
          <InfoPanel>
            <Typography color="error">{error}</Typography>
          </InfoPanel>
        </Box>
      )}
    </>
  );
}
