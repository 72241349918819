import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import themeColors from '../../../../theme/colors';

export const FormType = styled.div<{ bgc?: string }>`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: ${({ bgc }) => bgc || '#e9bc46'};
  color: #38414f;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  white-space: normal;
  max-width: 178px;
  word-break: keep-all;
  line-height: 16px;
  height: auto;
  width: fit-content;
`;

export const Title = styled.h1`
  color: #38414f;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.64px;
`;

export const LastEdit = styled.span`
  color: var(--grey, #888);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.12px;
  display: block;
  position: absolute;
  top: 0;
`;

export const GenDate = styled.span<{ hide?: string }>`
  color: var(--grey, #888);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.12px;
  display: block;
  position: absolute;
  top: 5px;
  right: 0;
`;

export const EditButton = styled.button`
  border-radius: 50%;
  height: 48px;
  width: 48px;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  display: flex;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const StyledDialog = styledMUI(Dialog)`
  & .MuiPaper-root {
    border: 1px solid ${themeColors.strokePressed};
    background: ${themeColors.pureWhite};
    padding: 24px;
    z-index: 1;
    width: 984px;
    border-radius: 16px;
    max-width: none;
  }
`;

export const ManageBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
  min-height: 48px;
  cursor: pointer;
`;

export const InfoBox = styled.p`
  color: var(--main-dark-main, #38414f);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.36px;
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
  background: var(--ESRS-2, #e9bc46);
`;

export const ContentType = styled.span<{ color?: string }>`
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  word-break: break-word;
  padding: 6px 8px;
  background-color: ${({ color }) => color || '#38414f'};
  border-radius: 6px;
  white-space: break-spaces;
  //max-width: 150px;
  width: fit-content;
`;

export const TruncatedTextRows = styled.span<{ lines?: number }>`
  word-break: keep-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ lines }) => lines || 10};
  text-overflow: ellipsis;
`;
